/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface RoomStudentAttrs {
  product_id: number
  room_student_status_id: number
  room_id: number
  ktwelve_curriculum_registration_id: number
  registration_id: number
  starts_at: string
  ends_at: string
  active: boolean
  product_name: string
  status_name: string
  student_name: string
  room_name: string
  year: number
  room_student_status_name: string
}
export type RoomStudentJson = JsonFormat<'room_students', RoomStudentAttrs>

export type FetchRoomStudentsResponse = JsonResponseFormat<RoomStudentJson[]>
export type FetchRoomStudentResponse = JsonResponseFormat<RoomStudentJson>

export type FetchRoomStudentsPayload = WithRequestParams
export interface FetchRoomStudentPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateRoomStudentPayload {
  data: Partial<RoomStudentAttrs>
}

export interface UpdateRoomStudentPayload extends CreateRoomStudentPayload {
  id: string
}

export interface DeleteRoomStudentPayload {
  id: string
}

export const FETCH_ROOM_STUDENTS = createPromiseAction('room_students: FETCH_ROOM_STUDENTS')<FetchRoomStudentsPayload, FetchRoomStudentsResponse, any>()
export const FETCH_ROOM_STUDENT = createPromiseAction('room_students: FETCH_ROOM_STUDENT')<FetchRoomStudentPayload, FetchRoomStudentResponse, any>()
export const CREATE_ROOM_STUDENT = createPromiseAction('room_students: CREATE_ROOM_STUDENT')<CreateRoomStudentPayload, FetchRoomStudentResponse, any>()
export const UPDATE_ROOM_STUDENT = createPromiseAction('room_students: UPDATE_ROOM_STUDENT')<UpdateRoomStudentPayload, FetchRoomStudentResponse, any>()
export const DELETE_ROOM_STUDENT = createPromiseAction('room_students: DELETE_ROOM_STUDENT')<DeleteRoomStudentPayload, AxiosResponse, any>()
