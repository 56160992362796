/**
 * TODO: Decouple this component:
 * This file is a React Router 4 wrapper. It has a impure component
 * because it's  highly coupled to route configs and Login and NotFoundPage Components.
 * Can it will be an library in a not far distant future?
 *
 * TODO: Write some case tests for logged and not logged case
 */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route, Redirect, withRouter, useHistory, useLocation } from 'react-router-dom'
import reverse from 'lodash/reverse'

import { RootState } from '../store/configureStore'
import { privateRoutes, publicRoutes, notLoggedRoutes, Route as RouteType } from './pathUrls'
import RouteWithTemplate from './RouteWithTemplate'
import NotFoundPage from '../pages/NotFoundPage'
import { SIGN_OUT } from '../store/auth'
import { Paths, profiles } from '../utils/constants'



const Routes = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    auth: { profile, isLogged, company },
  } = useSelector((state: RootState) => state)
  const filteredPrivateRoutes = React.useCallback(() : RouteType[] => {
    const profileInfo = profile?.role
    const filtered = privateRoutes.filter((route) => {
      return route?.permission?.includes(profileInfo)
    })
    if(isLogged && (!filtered || filtered.length === 0)){
      dispatch(SIGN_OUT.request())
    }
    // if(isLogged && !find(filtered, item => item.path === location.pathname && !isEmpty(filtered))){
    //   const defaultPath = filtered.find(item => item.default)
    //   defaultPath ? history.push(defaultPath.path) : history.push(head(filtered)?.path as string)
    // }
    return filtered
  },
    [profile]
  )()
  React.useEffect(() => {
    !isLogged && location.pathname !== Paths.LOGIN && history.push(Paths.HOME)
  }, [isLogged])

  const defaultPrivateRoute = isLogged && profile?.role ? profiles.find(item => item.name === profile.role)?.default_route : filteredPrivateRoutes.find((route) => route.default)?.path
  const setRoute = (route: any) => {
    const props = {
      ...route,
      key: route.path,
      exact: true
    }
    if(route.template){
      return <RouteWithTemplate {...props} />
    } else {
      return <Route {...props} />
    }

  }
    

  /**
     * This preserves the path for redirect to wished page after login
     * @param {*} route
     */
  const setPrivateRoute = (route: RouteType) => {
    return setRoute({
      ...route,
      template: route.template,
      component: route.component
    })
  }

  const setRedirect = (route: RouteType) => <Redirect key={route.path} from={route.path} to={defaultPrivateRoute || Paths.HOME} exact />

  const routesPrecedence = [
    filteredPrivateRoutes.map(setPrivateRoute),
    notLoggedRoutes.map(isLogged ? setRedirect : setRoute),
    publicRoutes.map(setRoute)
  ]

  /**
     * Two precedence rules:
     * 1 - User is Logged: Private Routes > Public Routes
     * 2 - User is Not Logged: Public Routes > Not Logged Routes > Private Routes
     *
     * These two rules allow overwrites on direction of user status, easing the route configuration.
     */
  const routes = isLogged ? routesPrecedence : reverse(routesPrecedence)
  React.useEffect(() => {
    if(isLogged && !filteredPrivateRoutes.map(route => route.path).includes(location.pathname)){
      history.push(defaultPrivateRoute as string)
    }

  },[profile, company])
  return (
    <Switch>
      {routes}
      <Route component={NotFoundPage} />
    </Switch>
  )
}


export default withRouter(Routes)
