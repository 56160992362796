/* eslint-disable camelcase */
import { head, isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import RoomStudentsForm from '../components/form/RoomStudentForm';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { RoomStudentAttributes, RoomAttributes, KtwelveCurriculumRegistrationAttributes } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { FETCH_ROOM_STUDENT, UPDATE_ROOM_STUDENT } from '../store/room_students';
import { CREATE_KTWELVE_CURRICULUM_REGISTRATION } from '../store/ktwelve_curriculum_registrations';

interface KtwelveCurriculumRegistrationFormttributes extends KtwelveCurriculumRegistrationAttributes {
  room_students_attributes: Partial<RoomStudentAttributes>[]
}

const RoomStudentsFormContainer = (props: {
  onSave: () => void
  room_student_id?: number
  room: RoomAttributes,
  close_form: () => void
}) => {
  const { onSave, room_student_id, close_form, room } = props
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = React.useState<Partial<KtwelveCurriculumRegistrationFormttributes> | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const isUpdating = !isNil(initialValues?.id);

 
  const loadRoomStudents = React.useCallback(async () => {
    if (room_student_id) {
      const room_student = await dispatch(
        FETCH_ROOM_STUDENT.request({
          id: room_student_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = room_student;
      const formatted_room_student = {
        id,
        ...attributes,
      };
      setInitialValues(formatted_room_student);
    } else {
      setInitialValues({ ktwelve_curriculum_id: room.ktwelve_curriculum_id, room_students_attributes: [{ room_id: ~~room.id, product_id: room.product_id }] })
    }
  }, [room_student_id, room]);

  const initRoomStudentsForm = React.useCallback(async () => {
    setLoading(true);
    await loadRoomStudents();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: KtwelveCurriculumRegistrationFormttributes) => {
      try {
        if (isUpdating) {
          const room_student_data = head(data.room_students_attributes)
          const initial_room_student_data = head(initialValues?.room_students_attributes)
          const { id, ...rest } = verifyDifferentValues(room_student_data, initial_room_student_data, [
            'id',
            'company_id',
          ]) as RoomStudentAttributes;
          await dispatch(
            UPDATE_ROOM_STUDENT.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_KTWELVE_CURRICULUM_REGISTRATION.request({
              data: {
                ...data,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Dados do aluno na sala salvo com sucesso.',
          }),
        );
        close_form()
        onSave()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar dados do aluno na sala.',
          }),
        );
      }
    },
    [initialValues, room, isUpdating],
  );

  React.useEffect(() => {
    initRoomStudentsForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <RoomStudentsForm room={room} close_form={close_form} initialValues={initialValues} onSubmit={onSubmit} />;
};

export default RoomStudentsFormContainer;
