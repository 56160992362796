/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PiStudent, PiExam } from 'react-icons/pi';
import { CiViewTable } from 'react-icons/ci';
import { RiCalendarScheduleLine } from 'react-icons/ri';
import { MdErrorOutline } from "react-icons/md";
import {
  colors,
  RoomAttributes,
  Role,
  PaginationType,
  defaultPagination,
  KtwelveSubjectAttributes,
  CompositionAttributes,
  CompositionPeriodAttributes,
  FormulableType,
  ExamPlaceableType,
  ExamPlacementAttributes,
  FormulaAttributes,
  SubjectPeriodRegistrationAttributes,
  ExamAttributes,
  RegistrationResultAttributes,
  SubjectPeriodAttributes,
  ExamKindEnum,
  KtwelveSubjectEvaluationKindEnum,
  onConfirm,
  examIrregularityOptions,
  ExamIrregularityEnum,
  CompanyAttributes,
  TeacherAttributes,
} from '../../utils/constants';
import TooltipButton from '../shared/TooltipButton';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Tab,
  TableFooter,
  TablePagination,
  Tabs,
  Tooltip,
} from '@mui/material';
import RoomsFormContainer from '../../containers/RoomsFormContainer';
import PenIcon from '../icon/PenIcon';
import { compactSum, evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import TablePaginationActions from '../shared/TablePaginationActions';
import SelectComponent from '../input/form/select';
import InputComponent from '../input/form/input';
import RoomStudentsTable from './RoomStudentsTable';
import ClassTimeTable from './ClassTimeDisplayTable';
import RoomSchedulesTable from './RoomSchedulesTable';
import { FETCH_KTWELVE_CURRICULUM } from '../../store/ktwelve_curriculums';
import { filter, find, head, isEmpty, isNumber, map, orderBy, size, sortBy, toNumber, union } from 'lodash';
import { KtwelveSubjectJson } from '../../store/ktwelve_subjects';
import { CompositionJson } from '../../store/compositions';
import { CompositionPeriodJson } from '../../store/composition_periods';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import TabPanel from '../shared/TabPanel';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import {
  FETCH_SUBJECT_PERIOD_REGISTRATION,
  FETCH_SUBJECT_PERIOD_REGISTRATIONS,
  UPDATE_SUBJECT_PERIOD_REGISTRATION,
} from '../../store/subject_period_registrations';
import { FormulaJson } from '../../store/formulas';
import { ExamPlacementJson } from '../../store/exam_placements';
import { SubjectPeriodJson } from '../../store/subject_periods';
import { RegistrationResultJson } from '../../store/registration_results';
import { CREATE_EXAM, ExamJson, UPDATE_EXAM } from '../../store/exams';
import { ExpandMore } from '@mui/icons-material';
import { IconModal } from '../modal/Modal';
import TextAreaComponent from '../input/form/textarea';
import { FETCH_TEACHER } from '../../store/teachers';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const FilterCss = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  & > span {
    margin-bottom: 14px;
  }
  & .inputArea {
    width: 10rem !important;
    padding-right: 3rem;
  }
`;

const ViewCss = css`
  width: inherit;
  min-height: 100%;
  height: fit-content;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  & .MuiBackdrop-root {
    position: inherit;
  };
  & .MuiPaper-root {
    background: none;
    box-shadow: none;
    overflow: hidden;
  },
`;
const TabCss = css`
  & .Mui-selected {
    background: #fdfeff;
    border-radius: 1rem 1rem 0 0;
  }
`;

const SliderWrapperCss = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SliderCss = css`
  & .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  & .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  & input:checked + .slider {
    background-color: #2196f3;
  }

  & input:focus-within + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  & input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  & .slider.round {
    border-radius: 34px;
  }

  & .slider.round:before {
    border-radius: 50%;
  }
`;

const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue};
`;
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`;
const InputCss = css`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const enforceMaxMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  if(e.target.value && isNumber(~~e.target.value)){
    const value = e.target.value
    if(toNumber(value) < 0) {
      e.target.value = 0+''
    } else if (toNumber(value) > 10) {
      e.target.value = 10+''
    }
  }
}

export interface SubjectRegistrationPeriodData extends SubjectPeriodRegistrationAttributes {
  exams: ExamAttributes[];
  registration_results: RegistrationResultAttributes[];
}

export interface SubjectPeriodData extends SubjectPeriodAttributes {
  subject_period_registrations: SubjectRegistrationPeriodData[];
  formulas: FormulaAttributes[];
  exam_placements: ExamPlacementAttributes[];
}

const SubjectPeriodRegistrationList = (props: {
  ktwelve_subject: KtwelveSubjectAttributes;
  room: RoomAttributes;
  initial_current_composition_period: CompositionPeriodData;
}) => {
  const { ktwelve_subject, room, initial_current_composition_period } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [is_checked, setCheckbox] = React.useState(
    ktwelve_subject.evaluation_kind === KtwelveSubjectEvaluationKindEnum.CONCEPT,
  );
  const checkboxHandler = (value: boolean) => {
    setCheckbox(value);
  };
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile },
  } = state;
  const [subjectPeriodData, setSubjectPeriodData] = React.useState<SubjectPeriodData | null>(null);
  const fetchSubjectPeriodRegistrations = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        FETCH_SUBJECT_PERIOD_REGISTRATIONS.request({
          params: {
            filters: {
              'q[rooms_id_eq]': room.id,
              'page[size]': '100',
              'q[ktwelve_subject_id_eq]': ktwelve_subject.id,
              'q[composition_period_id_eq]': initial_current_composition_period.id,
              include: [
                'registration_results',
                'subject_period.formulas',
                'subject_period.exam_placements',
                'exams',
              ].join(','),
            },
          },
        }),
      );
      const {
        data: { data, included },
      } = response;
      const subject_period = find(
        included,
        (incl) =>
          incl.type === 'subject_periods' &&
          incl.attributes.composition_period_id === ~~initial_current_composition_period.id,
      ) as SubjectPeriodJson;
      const subject_period_formulas = map(
        filter(
          included,
          (incl) =>
            incl.type === 'formulas' &&
            incl.attributes.formulable_id === ~~subject_period.id &&
            incl.attributes.formulable_type === FormulableType.SUBJECT_PERIOD,
        ) as FormulaJson[],
        (formula) => ({
          id: formula.id,
          ...formula.attributes,
        }),
      );
      const subject_period_exam_placements = map(
        filter(
          included,
          (incl) =>
            incl.type === 'exam_placements' &&
            incl.attributes.exam_placeable_id === ~~subject_period.id &&
            incl.attributes.exam_placeable_type === ExamPlaceableType.SUBJECT_PERIOD,
        ) as ExamPlacementJson[],
        (exam_placement) => ({
          id: exam_placement.id,
          ...exam_placement.attributes,
        }),
      );
      const subject_period_formulas_steps = sortBy(map(subject_period_formulas, (formula) => formula.step));
      const composition_period_formulas_steps = sortBy(
        initial_current_composition_period.formulas.map((formula) => formula.step),
      );
      const formulas = map(union(subject_period_formulas_steps, composition_period_formulas_steps), (step) => {
        return (
          find(subject_period_formulas, (spf) => spf.step === step) ||
          find(initial_current_composition_period.formulas, (cpf) => cpf.step === step)
        );
      }) as FormulaAttributes[];

      const subject_period_exam_placement_order = sortBy(
        map(subject_period_exam_placements, (exam_placement) => exam_placement.order),
      );
      const composition_period_exam_placement_order = sortBy(
        map(initial_current_composition_period.exam_placements, (exam_placement) => exam_placement.order),
      );
      const exam_placements = map(
        union(subject_period_exam_placement_order, composition_period_exam_placement_order),
        (order) => {
          return (
            find(subject_period_exam_placements, (spep) => spep.order === order) ||
            find(initial_current_composition_period.exam_placements, (cpep) => cpep.order === order)
          );
        },
      ) as ExamPlacementAttributes[];
      const subject_period_registrations = map(data, (spr) => {
        const registration_results = map(
          filter(
            included,
            (incl) =>
              incl.type === 'registration_results' && incl.attributes.subject_period_registration_id === ~~spr.id,
          ) as RegistrationResultJson[],
          (rs) => ({
            id: rs.id,
            ...rs.attributes,
          }),
        );
        const exams = map(
          filter(
            included,
            (incl) => incl.type === 'exams' && incl.attributes.subject_period_registration_id === ~~spr.id,
          ) as ExamJson[],
          (exam) => ({
            id: exam.id,
            ...exam.attributes,
          }),
        );
        return {
          id: spr.id,
          ...spr.attributes,
          exams,
          registration_results,
        };
      });
      const result = {
        id: subject_period?.id,
        ...subject_period?.attributes,
        subject_period_registrations,
        exam_placements,
        formulas,
      };
      setSubjectPeriodData(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        error({
          message: 'Erro ao carregar disciplinas da turma',
        }),
      );
    }
  }, [ktwelve_subject, room, initial_current_composition_period]);

  const updateSubjectPeriodRegistrationResult = React.useCallback(
    async (props: { subject_period_registration_id: string; result_input: string }) => {
      try {
        const { subject_period_registration_id, result_input } = props;
        const response = await dispatch(
          UPDATE_SUBJECT_PERIOD_REGISTRATION.request({
            id: subject_period_registration_id,
            data: {
              result_input: isEmpty(result_input) ? null : result_input,
            },
          }),
        );
        const {
          data: { data },
        } = response;
        setSubjectPeriodData((current) => {
          if (current) {
            return {
              ...current,
              subject_period_registrations: current.subject_period_registrations.map((spr) => {
                if (spr.id === subject_period_registration_id) {
                  return {
                    ...spr,
                    ...data.attributes,
                  };
                }
                return spr;
              }),
            };
          }
          return current;
        });
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao atualizar resultado do aluno',
          }),
        );
      }
    },
    [subjectPeriodData, setSubjectPeriodData],
  );

  const init = React.useCallback(async () => {
    await fetchSubjectPeriodRegistrations();
  }, [initial_current_composition_period, room, ktwelve_subject]);

  const createExam = React.useCallback(
    async (props: {
      subject_period_registration_id: number;
      exam_placement_id: number;
      subject_period_id: number;
      kind: ExamKindEnum;
      score: number;
    }) => {
      const { subject_period_registration_id, exam_placement_id, kind, score } = props;
      try {
        await dispatch(
          CREATE_EXAM.request({
            data: {
              subject_period_registration_id,
              exam_placement_id,
              kind,
              score,
            },
          }),
        );
        const response = await dispatch(
          FETCH_SUBJECT_PERIOD_REGISTRATION.request({
            id: subject_period_registration_id.toString(),
            params: {
              filters: {
                include: ['registration_results', 'exams'].join(','),
              },
            },
          }),
        );
        const {
          data: { data:responseData, included },
        } = response;
        const registration_results = map(
          filter(included, (incl) => incl.type === 'registration_results') as RegistrationResultJson[],
          (rs) => ({
            id: rs.id,
            ...rs.attributes,
          }),
        );
        const exams = map(filter(included, (incl) => incl.type === 'exams') as ExamJson[], (exam) => ({
          id: exam.id,
          ...exam.attributes,
        }));
        setSubjectPeriodData((current) => {
          if (current) {
            return {
              ...current,
              subject_period_registrations: current.subject_period_registrations.map((spr) => {
                if (~~spr.id === subject_period_registration_id) {
                  return {
                    ...spr,
                    ...responseData.attributes,
                    exams,
                    registration_results,
                  };
                }
                return spr;
              }),
            };
          }
          return current;
        });
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao criar prova',
          }),
        );
      }
    },
    [subjectPeriodData, setSubjectPeriodData],
  );

  const updateExam = React.useCallback(
    async (props: {
      subject_period_registration_id: number;
      exam_id: number;
      subject_period_id: number;
      data: Partial<ExamAttributes>;
    }) => {
      const { subject_period_registration_id, exam_id, data } = props;
      try {
        await dispatch(
          UPDATE_EXAM.request({
            id: exam_id.toString(),
            data: {
              subject_period_registration_id,
              ...data,
            },
          }),
        );
        const response = await dispatch(
          FETCH_SUBJECT_PERIOD_REGISTRATION.request({
            id: subject_period_registration_id.toString(),
            params: {
              filters: {
                include: ['registration_results', 'exams'].join(','),
              },
            },
          }),
        );
        const {
          data: { data: responseData, included },
        } = response;
        const registration_results = map(
          filter(included, (incl) => incl.type === 'registration_results') as RegistrationResultJson[],
          (rs) => ({
            id: rs.id,
            ...rs.attributes,
          }),
        );
        const exams = map(filter(included, (incl) => incl.type === 'exams') as ExamJson[], (exam) => ({
          id: exam.id,
          ...exam.attributes,
        }));
        setSubjectPeriodData((current) => {
          if (current) {
            return {
              ...current,
              subject_period_registrations: current.subject_period_registrations.map((spr) => {
                if (~~spr.id === subject_period_registration_id) {
                  return {
                    ...spr,
                    ...responseData.attributes,
                    exams,
                    registration_results,
                  };
                }
                return spr;
              }),
            };
          }
          return current;
        });
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao criar prova',
          }),
        );
      }
    },
    [setSubjectPeriodData, subjectPeriodData],
  );
  React.useEffect(() => {
    init();
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div css={SliderWrapperCss}>
        <span>Notas</span>
        <div css={SliderCss}>
          <label className='switch'>
            <input
              checked={is_checked}
              onChange={(ev) => checkboxHandler(ev.target.checked)}
              id='slide-check'
              type='checkbox'
            />
            <span className='slider round' />
          </label>
        </div>
        <span>Frequência</span>
      </div>
      <Table stickyHeader css={TableCss} size='small' aria-label='subject_period_registrations'>
        <TableHead>
          <TableRow style={{ display: is_checked ? 'none' : 'table-row' }}>
            <TableCell align='left' colSpan={5} />
            {map(orderBy(subjectPeriodData?.exam_placements, 'order'), (exam_placement) => {
              return (
                <TableCell colSpan={2} align='center' key={`${exam_placement.id}-${ktwelve_subject.id}`}>
                  <Tooltip title={exam_placement.name}>
                    <span>{`A${exam_placement.order}`}</span>
                  </Tooltip>
                </TableCell>
              );
            })}
            {!isEmpty(subjectPeriodData?.formulas) && (
              <TableCell colSpan={size(subjectPeriodData?.formulas) + 1} align='center'>
                Médias
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell align='left' colSpan={5}>
              Nome
            </TableCell>
            {!is_checked &&
              map(orderBy(subjectPeriodData?.exam_placements, 'order'), (exam_placement) => {
                return (
                  <React.Fragment key={`${exam_placement.id}-${ktwelve_subject.id}`}>
                    <TableCell align='center' colSpan={1}>
                      <Tooltip title='Avaliação'>
                        <span>AV</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align='center' colSpan={1}>
                      <Tooltip title='Reposição'>
                        <span>AVR</span>
                      </Tooltip>
                    </TableCell>
                  </React.Fragment>
                );
              })}
            {!is_checked &&
              map(orderBy(subjectPeriodData?.formulas, 'step'), (formula) => {
                return (
                  <TableCell align='center' colSpan={1} key={formula.id}>
                    <Tooltip title={formula.name}>
                      <span>{`M${formula.step}`}</span>
                    </Tooltip>
                  </TableCell>
                );
              })}
            <TableCell style={{ display: is_checked ? 'none' : 'table-cell' }} align='center' colSpan={1}>
              <Tooltip title={'Média Final'}>
                <span>{`MF`}</span>
              </Tooltip>
            </TableCell>
            {is_checked && (
              <>
                <TableCell align='center'>Total de Faltas</TableCell>
                <TableCell align='center'>Faltas sem justificativa</TableCell>
                <TableCell align='center'>Total de Aulas</TableCell>
                <TableCell align='center'>Frequência geral(%)</TableCell>
                <TableCell align='center'>Frequência c/ justificativa(%)</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(
            orderBy(subjectPeriodData?.subject_period_registrations, 'student_name'),
            (subject_period_registration) => {
              const onConfirmResultInput: onConfirm = async (props) => {
                const { setLoading, handleClose, setLoadingMessage } = props;
                try {
                  setLoading(true);
                  setLoadingMessage('Salvando resultado');
                  const input_element = document.querySelector(
                    `#result-input-${subject_period_registration.id}`,
                  ) as HTMLInputElement;
                  await updateSubjectPeriodRegistrationResult({
                    subject_period_registration_id: subject_period_registration.id,
                    result_input: input_element?.value || '',
                  });
                  setLoading(false);
                  handleClose();
                } catch (err) {
                  dispatch(
                    error({
                      message: 'Erro ao salvar resultado de aluno',
                    }),
                  );
                  setLoading(false);
                  handleClose();
                }
              };
              const frequency_rate = subject_period_registration.frequency_rate || []
              const disabled_final_score_button = ![Role.SUPERUSER, Role.COORDINATOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN].includes(profile.role)

              const final_result = isEmpty(subject_period_registration.result_input)
                ? subject_period_registration.score
                : subject_period_registration.result_input;
              return (
                <React.Fragment key={subject_period_registration.id}>
                  <TableRow css={InputCss}>
                    <TableCell align='left' colSpan={5}>
                      {subject_period_registration.student_name}
                    </TableCell>
                    {!is_checked &&
                      map(orderBy(subjectPeriodData?.exam_placements, 'order'), (exam_placement) => {
                        const exams_for_this_placement = filter(
                          subject_period_registration.exams,
                          (exam) => exam.exam_placement_id === ~~exam_placement.id,
                        );
                        const default_exam = find(
                          exams_for_this_placement,
                          (exam) => exam.kind === ExamKindEnum.DEFAULT,
                        );
                        const replacement_exam = find(
                          exams_for_this_placement,
                          (exam) => exam.kind === ExamKindEnum.REPLACEMENT,
                        );
                        const exams = {
                          [ExamKindEnum.DEFAULT]: default_exam,
                          [ExamKindEnum.REPLACEMENT]: replacement_exam,
                        };
                        const changeExam = async (event: React.FocusEvent<HTMLInputElement, Element>) => {
                          const data_kind = event.target.getAttribute('data-kind') as ExamKindEnum;
                          const score = event.target.value;
                          if (isEmpty(exams[data_kind]) && score) {
                            await createExam({
                              subject_period_id: subject_period_registration.subject_period_id,
                              subject_period_registration_id: ~~subject_period_registration.id,
                              kind: data_kind,
                              exam_placement_id: ~~exam_placement.id,
                              score: toNumber(score),
                            });
                          } else if (exams[data_kind]) {
                            const exam_data = exams[data_kind] as ExamAttributes;
                            await updateExam({
                              subject_period_id: subject_period_registration.subject_period_id,
                              subject_period_registration_id: ~~subject_period_registration.id,
                              data: {
                                score: !isEmpty(score) ? ~~score : null,
                              },
                              exam_id: toNumber(exam_data.id),
                            });
                          }
                        };
                        const onConfirmSaveIrregulairy = async (props: {
                          setLoading: React.Dispatch<React.SetStateAction<boolean>>;
                          handleClose: () => void;
                          setLoadingMessage: React.Dispatch<React.SetStateAction<string>>;
                          exam_id: string
                      }) => {
                          const { setLoading, handleClose, setLoadingMessage, exam_id } = props;
                          try {
                            setLoading(true);
                            setLoadingMessage('Salvando irregularidade');
                            const irregularity_input_element = document.querySelector(
                              `#input-irregularity-${exam_id} input`,
                            ) as HTMLInputElement;
                            const comments_input_element = document.querySelector(
                              `#input-comments-${exam_id} textarea`,
                            ) as React.HTMLProps<HTMLTextAreaElement>;
                            await updateExam({
                              subject_period_registration_id: ~~subject_period_registration.id,
                              exam_id: ~~exam_id,
                              data: {irregularity: (irregularity_input_element?.value || '') as ExamIrregularityEnum, comments: comments_input_element.value as string || ''},
                              subject_period_id: subject_period_registration.subject_period_id
                            });
                            setLoading(false);
                            handleClose();
                          } catch (err) {
                            dispatch(
                              error({
                                message: 'Erro ao salvar resultado de aluno',
                              }),
                            );
                            setLoading(false);
                            handleClose();
                          }
                        };
                        const disabled_fields =
                          isBefore(new Date(), new Date(subjectPeriodData?.insert_starts_at as string)) ||
                          isAfter(new Date(), new Date(subjectPeriodData?.insert_ends_at as string));
                          const examButtonCss = css`
                           ${default_exam && `#input-irregularity-${default_exam?.id} { min-width: 50% }`}
                          ${replacement_exam && `#input-irregularity-${replacement_exam?.id} { min-width: 50% }`}
                          `
                          const disabled_comment_button = !default_exam || !isNumber(default_exam?.score) || default_exam.score > 0
                        return (
                          <React.Fragment key={exam_placement.id}>
                            <TableCell align='center' colSpan={1}>
                              <div>
                                <input
                                  min={0}
                                  max={10}
                                  onChange={enforceMaxMinChange}  
                                  type='number'
                                  disabled={disabled_fields}
                                  onBlur={changeExam}
                                  data-kind={ExamKindEnum.DEFAULT}
                                  style={{ width: '50px' }}
                                  defaultValue={!isNumber(default_exam?.score) ? '' : default_exam?.score as number}
                                />
                                <IconModal
                                  icon={MdErrorOutline}
                                  disabled={disabled_comment_button}
                                  iconProps={{
                                    style: {
                                      display: disabled_comment_button ? 'none' : 'block',
                                      color: colors.blue,
                                    },
                                  }}
                                  onConfirm={(props) => onConfirmSaveIrregulairy({...props, exam_id: default_exam?.id as string})}
                                  title={'Adicionar comentário e/ou irregularidade'}
                                  tooltipText={'Adicionar comentário e/ou irregularidade'}
                                  confirmButtonText='Salvar'
                                >
                                  <div  css={examButtonCss}>
                                  <SelectComponent
                                    placeholder='Selecionar Irregularidade'
                                    small
                                    input={{
                                      label: 'Irregularidade',
                                      placeholder: 'Selecionar opção de irregularidade',
                                      name: `irregularity-${default_exam?.id}`,
                                      defaultValue: default_exam?.irregularity,
                                    }}
                                    options={examIrregularityOptions}
                                  />
                                  <TextAreaComponent
                                    input={{
                                      placeholder: 'Comentários',
                                      defaultValue: default_exam?.comments,
                                      name: `comments-${default_exam?.id}`
                                    }}
                                  />
                                  </div>
                                </IconModal>
                              </div>
                            </TableCell>
                            <TableCell align='center' colSpan={1}>
                              <div css={InputCss}>
                                <input
                                  onChange={enforceMaxMinChange}  
                                  min={0}
                                  max={10}
                                  type='number'
                                  disabled={disabled_fields}
                                  onBlur={changeExam}
                                  data-kind={ExamKindEnum.REPLACEMENT}
                                  style={{ width: '50px' }}
                                  defaultValue={replacement_exam?.score || ''}
                                />
                              </div>
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                    {!is_checked &&
                      map(orderBy(subjectPeriodData?.formulas, 'step'), (formula) => {
                        const result_for_this_formula = find(
                          subject_period_registration.registration_results,
                          (rr) => rr.formula_id === ~~formula.id,
                        );
                        return (
                          <TableCell align='center' colSpan={1} key={formula.id}>
                            <Tooltip title={formula.name}>
                              <span>{result_for_this_formula?.score}</span>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    <TableCell style={{ display: is_checked ? 'none' : 'table-cell' }} align='center' colSpan={1}>
                      <span>{final_result}</span>
                      <IconModal
                        icon={PenIcon}
                        disabled={disabled_final_score_button}
                        iconProps={{
                          style: {
                            color: colors.blue,
                            display: disabled_final_score_button ? 'none' : 'block',
                          },
                        }}
                        onConfirm={onConfirmResultInput}
                        title={'Ajustar média final'}
                        tooltipText={'Ajustar média final'}
                        confirmButtonText='Salvar'
                      >
                        <input
                          min={0}
                          max={10}
                          onChange={enforceMaxMinChange}  
                          type='number'
                          id={`result-input-${subject_period_registration.id}`}
                          defaultValue={subject_period_registration.result_input}
                        />
                      </IconModal>
                    </TableCell>
                    {is_checked && (
                      <>
                        <TableCell align='center'>{subject_period_registration.frequency_data[1]}</TableCell>
                        <TableCell align='center'>{subject_period_registration.frequency_data[0]}</TableCell>
                        <TableCell align='center'>{subject_period_registration.frequency_data[2]}</TableCell>
                        <TableCell align='center'>
                          {compactSum([100, (-frequency_rate[0]||0) *100])}
                        </TableCell>
                        <TableCell align='center'>
                        {compactSum([100, (-frequency_rate[1]||0) *100])}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </React.Fragment>
              );
            },
          )}
        </TableBody>
      </Table>
    </>
  );
};

const KtwelveSubjectResults = (props: {
  ktwelve_subject: KtwelveSubjectAttributes;
  composition: CompositionData;
  room: RoomAttributes;
}) => {
  const { composition, ktwelve_subject, room } = props;
  let initial_current_composition_period = find(composition.composition_periods, (cp) => {
    const is_same_day_as_start_day = isSameDay(new Date(), new Date(cp.starts_at));
    const is_after_starts_at_day = isAfter(new Date(), new Date(cp.starts_at));
    const is_same_day_as_end_day = isSameDay(new Date(), new Date(cp.ends_at));
    const is_before_end_day = isBefore(new Date(), new Date(cp.ends_at));
    return is_same_day_as_start_day || (is_after_starts_at_day && is_same_day_as_end_day) || is_before_end_day;
  });
  if (!initial_current_composition_period) {
    initial_current_composition_period = head(composition.composition_periods) as CompositionPeriodData;
  }

  return (
    <>
      {map(composition.composition_periods, (cp) => {
        return (
          <Accordion
            expanded={initial_current_composition_period?.id === cp?.id}
            TransitionProps={{ unmountOnExit: true }}
            key={cp.id}
            css={AccordionCss}
          >
            <AccordionSummary css={AccordionSummaryCss}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ExpandMore />
                <span>{cp.name}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <SubjectPeriodRegistrationList
                ktwelve_subject={ktwelve_subject}
                room={room}
                initial_current_composition_period={cp}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export interface CompositionPeriodData extends CompositionPeriodAttributes {
  formulas: FormulaAttributes[];
  exam_placements: ExamPlacementAttributes[];
}

export interface CompositionData extends CompositionAttributes {
  composition_periods: CompositionPeriodData[];
}

interface StateCompanies extends CompanyAttributes {
  teachers: TeacherAttributes[]
}

const StudentResultsTable = (props: { room: RoomAttributes }) => {
  const { room } = props;
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
    account: { companies },
  } = state;

  const [composition, setComposition] = React.useState<CompositionData | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [ktwelveSubjects, setKtwelveSubjects] = React.useState<KtwelveSubjectAttributes[]>([]);
  const [tab, setTab] = React.useState('');
  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  const fetchKtwelveCurriculumData = React.useCallback(async () => {
    try {
      setLoading(true);
      const current_teacher = head(find(companies as StateCompanies[], (co: StateCompanies) => co.id === company)?.teachers)
      const evaluate_teacher = profile.role === Role.TEACHER && current_teacher
      let teacher_ktwelve_subject_ids = [] as string[]
      if(evaluate_teacher) {
        const teacher_ktwelve_subjects_response = await dispatch(
          FETCH_TEACHER.request({
            id: current_teacher.id,
            params: {
              filters: {
                'include': 'ktwelve_subjects'
              }
            }
          })
        )
        const { data: { included } } = teacher_ktwelve_subjects_response
        teacher_ktwelve_subject_ids = map(filter(included, incl => incl.type === 'ktwelve_subjects'), incl => incl.id)
      }

      const ktwelve_curriculum = await dispatch(
        FETCH_KTWELVE_CURRICULUM.request({
          id: room.ktwelve_curriculum_id,
          params: {
            filters: {
              include: [
                'composition',
                'composition_periods.exam_placements',
                'composition_periods.formulas',
                'ktwelve_subjects',
              ].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = ktwelve_curriculum;
      let ktwelve_subjects = map(
        filter(included, (incl) => incl.type === 'ktwelve_subjects') as KtwelveSubjectJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      if(evaluate_teacher) {
        ktwelve_subjects = filter(ktwelve_subjects, ks => teacher_ktwelve_subject_ids.includes(ks.id))
      }
      const included_composition = find(included, (incl) => incl.type === 'compositions') as CompositionJson;
      const composition = {
        id: included_composition.id,
        ...included_composition.attributes,
        composition_periods: map(
          filter(included, (incl) => incl.type === 'composition_periods') as CompositionPeriodJson[],
          (cp) => {
            const formulas = map(
              filter(
                included,
                (incl) =>
                  incl.type === 'formulas' &&
                  incl.attributes.formulable_type === FormulableType.COMPOSITION_PERIOD &&
                  incl.attributes.formulable_id === ~~cp.id,
              ) as FormulaJson[],
              (formula) => ({
                id: formula.id,
                ...formula.attributes,
              }),
            ) as FormulaAttributes[];

            const exam_placements = map(
              filter(
                included,
                (incl) =>
                  incl.type === 'exam_placements' &&
                  incl.attributes.exam_placeable_type === ExamPlaceableType.COMPOSITION_PERIOD &&
                  incl.attributes.exam_placeable_id === ~~cp.id,
              ) as ExamPlacementJson[],
              (formula) => ({
                id: formula.id,
                ...formula.attributes,
              }),
            ) as ExamPlacementAttributes[];
            return {
              id: cp.id,
              ...cp.attributes,
              formulas,
              exam_placements,
            };
          },
        ),
      };
      setComposition(composition);
      setKtwelveSubjects(ktwelve_subjects);
      setTab(head(ktwelve_subjects)?.id as string);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        error({
          message: 'Erro ao carregar dados da matriz curricular',
        }),
      );
    }
  }, []);

  const init = React.useCallback(async () => {
    await fetchKtwelveCurriculumData();
  }, []);

  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Tabs css={TabCss} value={tab} onChange={handleTabChange}>
        {ktwelveSubjects.map((item) => {
          return <Tab key={item.subject_name} label={item.subject_name} value={item.id} />;
        })}
      </Tabs>
      {ktwelveSubjects.map((item) => {
        return (
          <TabPanel key={item.subject_name} index={item.id} value={tab}>
            <KtwelveSubjectResults ktwelve_subject={item} room={room} composition={composition as CompositionData} />
          </TabPanel>
        );
      })}
    </div>
  );
};

const RoomsTable = ({
  rooms,
  pagination,
  handleChangePageSize,
  handlePageChange,
  fetchRoomsMethod,
  companyFilterValue,
  setCompanyFilterValue,
  nameFilterValue,
  setNameFilterValue,
  setProductFilterValue,
  productFilterValue,
}: {
  rooms: RoomAttributes[];
  pagination: PaginationType;
  handlePageChange?: (_: any, newPage: number) => void;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setProductFilterValue: React.Dispatch<React.SetStateAction<string>>;
  handleChangePageSize?: (e: any) => void;
  fetchRoomsMethod: ({
    newPagination,
    company_filter,
    product_filter,
  }: {
    newPagination?: PaginationType;
    company_filter?: string;
    product_filter?: string;
  }) => Promise<void>;
  companyFilterValue: string;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  productFilterValue: string;
}) => {
  const [expandedRoomAssets, setExpandedRoomStudents] = React.useState<number[]>([]);
  const [expandedClassTimes, setExpandedClassTimes] = React.useState<number[]>([]);
  const [expandedRoomSchedules, setExpandedRoomSchedules] = React.useState<number[]>([]);
  const [expandedStudentResults, setExpandedStudentResults] = React.useState<number[]>([]);

  const [editRoom, setEditingRoom] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  return (
    <div css={ViewCss}>
      <span className='title'>Salas</span>
      <div css={FilterCss}>
        {is_above_school_director && (
          <SelectComponent
            placeholder='Selecionar Unidade'
            small
            options={companies_options}
            input={{
              value: companyFilterValue,
              onChange: (e: any) => {
                setCompanyFilterValue(e.target.value);
                fetchRoomsMethod({
                  newPagination: { ...defaultPagination },
                  company_filter: e.target.value,
                });
              },
            }}
          />
        )}
        <InputComponent
          placeholder={`Buscar por nome`}
          search
          small
          onSearch={() => {
            fetchRoomsMethod({});
          }}
          input={{
            value: nameFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setNameFilterValue(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder={`Buscar pelo produto`}
          search
          small
          onSearch={() => {
            fetchRoomsMethod({});
          }}
          input={{
            value: productFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setProductFilterValue(e.target.value);
            },
          }}
        />
      </div>
      <Table css={TableCss} size='small' aria-label='ktwelve_subjects'>
        <TableHead>
          <TableRow>
            {is_above_school_director && <TableCell align='center'>Unidade</TableCell>}
            <TableCell align='center'>Nome</TableCell>
            <TableCell align='center'>Produto</TableCell>
            <TableCell align='center'>Série</TableCell>
            <TableCell align='right'>
              {' '}
              <button disabled={editRoom.length > 0} onClick={() => setCreate(!create)} className='green small'>
                <span> Adicionar sala </span>
              </button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
              <Collapse in={create} timeout='auto' unmountOnExit>
                <RoomsFormContainer
                  onSave={async () => {
                    await fetchRoomsMethod({ newPagination: defaultPagination });
                    setCreate(false);
                  }}
                  close_form={() => setCreate(false)}
                />
              </Collapse>
            </TableCell>
          </TableRow>
          {rooms.map((room) => {
            const close_editing_form = () => {
              setEditingRoom((current) => current.filter((item) => item !== ~~room.id));
            };
            return (
              <React.Fragment key={room.id}>
                <TableRow>
                  {is_above_school_director && <TableCell align='center'>{room.company_name}</TableCell>}
                  <TableCell align='center'>{room.name}</TableCell>
                  <TableCell align='center'>{room.product_name}</TableCell>
                  <TableCell align='center'>{room.ktwelve_degree_name}</TableCell>
                  <TableCell align='right'>
                    <TooltipButton
                      tooltipProps={{
                        title: editRoom.includes(~~room.id) ? 'Cancelar edição' : 'Editar Sala',
                      }}
                      Icon={PenIcon}
                      iconButtonProps={{
                        disabled: create || (editRoom.length > 0 && !editRoom.includes(~~room.id)),
                        onClick: () =>
                          setEditingRoom((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomAssets.includes(~~room.id) ? 'Ocultar alunos' : 'Ver alunos',
                      }}
                      Icon={PiStudent}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedRoomStudents((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedClassTimes.includes(~~room.id) ? 'Ocultar horários' : 'Ver horários',
                      }}
                      Icon={CiViewTable}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedClassTimes((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />{' '}
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomSchedules.includes(~~room.id)
                          ? 'Ocultar agendamentos de horário'
                          : 'Ver agendamentos de horário',
                      }}
                      Icon={RiCalendarScheduleLine}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedRoomSchedules((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />
                    <TooltipButton
                      tooltipProps={{
                        title: expandedRoomSchedules.includes(~~room.id)
                          ? 'Ocultar notas e frequência'
                          : 'Ver notas e frequência',
                      }}
                      Icon={PiExam}
                      iconButtonProps={{
                        onClick: () =>
                          setExpandedStudentResults((current) => {
                            if (current.includes(~~room.id)) {
                              return current.filter((item) => item !== ~~room.id);
                            } else {
                              return current.concat(~~room.id);
                            }
                          }),
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={editRoom.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomsFormContainer
                        onSave={async () => {
                          close_editing_form();
                          await fetchRoomsMethod({ newPagination: defaultPagination });
                        }}
                        close_form={close_editing_form}
                        room_id={~~room.id}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedRoomAssets.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomStudentsTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedClassTimes.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <ClassTimeTable room_id={room.id} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedRoomSchedules.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <RoomSchedulesTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedStudentResults.includes(~~room.id)} timeout='auto' unmountOnExit>
                      <StudentResultsTable room={room} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
        {pagination && handlePageChange && handleChangePageSize && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                colSpan={5}
                count={pagination.totalCount}
                rowsPerPage={pagination.pageSize}
                page={pagination.pageNumber}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangePageSize}
                labelRowsPerPage='Itens Por página'
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                }
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  );
};

export default RoomsTable;
