/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { compose } from 'redux';
import { Field, FormSection, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { DefaultOptionType, RoomAttributes, RoomStudentAttributes, validation } from '../../utils/constants';
import SelectComponent from '../input/form/select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { error } from 'react-notification-system-redux';
import InsertRoomStudentTable from '../table/InsertRoomStudentTable';
import DatePickerComponent from '../input/form/datepicker';
import { FETCH_ROOM_STUDENT_STATUSES } from '../../store/room_student_statuses';
import Loading from '../loading/Loading';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      padding: '2rem 0',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const form_name = 'roomStudentForm';

const RoomStudentForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  room: RoomAttributes;
  close_form: () => void;
}) => {
  const { handleSubmit, onSubmit, close_form, room } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [roomStatusOptions, setRoomStatusOptions] = React.useState<DefaultOptionType[]>([]);
  const state = useSelector((state: RootState) => state);
  const formValues = formValueSelector(form_name);
  const room_student_form_values = getFormValues(form_name)(state) as RoomStudentAttributes;

  const registrationIdValue = formValues(state, 'registration_id') as string;

  const fetchRoomStudentStatusOptions = React.useCallback(async () => {
    try {
      setLoading(true);
      const room_student_statuses = await dispatch(
        FETCH_ROOM_STUDENT_STATUSES.request({
          params: {
            filters: {
              'q[company_id_eq]': room.company_id.toString(),
              'page[size]': '30',
            },
          },
        }),
      );
      if (!isEmpty(room_student_statuses)) {
        const {
          data: { data },
        } = room_student_statuses;
        const formattedData = data.map(({ attributes, id }) => {
          return {
            label: attributes.name,
            value: id,
          };
        });
        setRoomStatusOptions(formattedData);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(
        error({
          message: 'Erro ao carregar opções de status',
        }),
      );
    }
  }, [registrationIdValue, room]);

  const init = async () => {
    await fetchRoomStudentStatusOptions();
  };

  React.useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar Estudante</span>
      <form className={classes.form}>
        <InsertRoomStudentTable room={room} room_student={room_student_form_values} />
        {registrationIdValue && (
          <FormSection name='room_students_attributes.[0]'>
            <div style={{ display: 'grid', gridTemplateColumns: '33% 33% 33%', justifyContent: 'space-between' }}>
              <Field
                name={`starts_at`}
                label={'Início do aluno na sala:'}
                placeholder={`01/01/${new Date().getFullYear()}`}
                component={DatePickerComponent}
                datePickerProps={{
                  ...(room_student_form_values.ends_at ? { maxDate: room_student_form_values.ends_at } : {}),
                  showDropdownIcon: true,
                }}
              />
              <Field
                name={`ends_at`}
                label={'Fim do aluno na sala:'}
                placeholder={`01/01/${new Date().getFullYear()}`}
                component={DatePickerComponent}
                datePickerProps={{
                  ...(room_student_form_values.starts_at ? { minDate: room_student_form_values.starts_at } : {}),
                  showDropdownIcon: true,
                }}
              />
              <Field
                name='room_student_status_id'
                component={SelectComponent}
                options={roomStatusOptions}
                label={'Status do aluno na sala'}
                placeholder={'Selecione o status do aluno na sala'}
                validate={[validation.required]}
              />
            </div>
            <div className={classes.buttons}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  close_form();
                }}
                className='red small'
              >
                <span> Cancelar </span>
              </button>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar Estudante </span>
              </button>
            </div>
          </FormSection>
        )}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(RoomStudentForm);
