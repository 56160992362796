import React from 'react'
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { DefaultOptionType, monetaryValueMaskProps, validation } from '../../utils/constants';
import DatePickerComponent from '../input/form/datepicker';
import SelectComponent from '../input/form/select';
import TextAreaComponent from '../input/form/textarea';
import MaskedInputComponent from '../input/form/masked';
import { parseMonetaryValue } from '../../utils/functions';
import CheckboxComponent from '../input/form/checkbox';

const RegistrationClosureForm = ({
  closureReasonOptions,
  closureStatusOptions,
  disable_display_refund_registration_fee,
  closureDatePickerProps
}:{
  closureReasonOptions: DefaultOptionType[]
  closureStatusOptions: DefaultOptionType[]
  disable_display_refund_registration_fee: boolean
  closureDatePickerProps: {
    max?: Date | undefined;
}
}) => {

  return (
    <form
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column'
      }}
    >
      <span>Selecione o motivo</span>
      <Field
        name='registration_closure_status_id'
        small
        options={closureStatusOptions}
        clearable
        component={SelectComponent}
        required
        validate={[validation.required]}
      />
      <span>Selecione a razão</span>
      <Field
        name='registration_closure_reason_id'
        small
        options={closureReasonOptions}
        clearable
        component={SelectComponent}
        required
        validate={[validation.required]}
      />
      <label htmlFor='closure_date'>Data</label>
      <Field
        name='closure_date'
        id='closure_date'
        small
        component={DatePickerComponent}
        placeholder={'11/11/2021'}
        required
        validate={[validation.dateRequired]}
        datePickerProps={{...closureDatePickerProps}}
      />
      <label htmlFor='penalty'>Valor da multa</label>
      <Field
        name='penalty'
        component={MaskedInputComponent}
        label={'Valor'}
        parse={parseMonetaryValue}
        maskProps={monetaryValueMaskProps}
        placeholder={'Insira o valor da multa'}
        validate={[validation.closurePenaltyValidaiton]}

      />
      <label htmlFor='closure_description'>Detalhes</label>
      <Field
        name='details'
        component={TextAreaComponent}
        placeholder='Detalhes do cancelamento'
        id='details'
        validate={[validation.required]}
      />
      <Field
        name='refund_registration_fee'
        disabled={disable_display_refund_registration_fee}
        component={CheckboxComponent}
        placeholder={'Estornar Entrada'}
      />
    </form>
  )
}

export default compose<any>(
  reduxForm({
      form: 'registrationClosureForm',
  }),
)(RegistrationClosureForm);