import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const roomStudentsEndpoint = '/room_students'

export const fetchRoomStudents = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${roomStudentsEndpoint}?${queryParams}`)
}

export const fetchRoomStudent = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${roomStudentsEndpoint}/${id}?${queryParams}`)
}

export const createRoomStudent = (data: object) => {
  return http.post(`${roomStudentsEndpoint}`, data)
}

export const updateRoomStudent = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${roomStudentsEndpoint}/${id}`, data)
}

export const deleteRoomStudent = ({ id }: { id: string | number } ) => {
  return http.delete(`${roomStudentsEndpoint}/${id}`)
}