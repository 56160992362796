/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../store/configureStore';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { CurriculumAttributes, KtwelveCurriculumAttributes, LocationState, Role } from '../utils/constants';
import { evaluate_permissions, verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { formValueSelector } from 'redux-form';
import KtwelveCurriculumsForm from '../components/form/KtwelveCurriculumsForm';
import { CREATE_KTWELVE_CURRICULUM, FETCH_KTWELVE_CURRICULUM, UPDATE_KTWELVE_CURRICULUM } from '../store/ktwelve_curriculums';

const KtwelveCurriculumsFormContainer = (props: {
  curriculum: CurriculumAttributes;
  ktwelve_curriculum_id?: string;
  onSave: () => void;
  close_form?: () => void;
  all_ktwelve_curriculums: KtwelveCurriculumAttributes[];
}) => {
  const { curriculum, ktwelve_curriculum_id, close_form, onSave, all_ktwelve_curriculums } = props;
  const location = useLocation<LocationState>();
  const curriculum_id = curriculum.id || location.state.curriculum_id;
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
  } = state;
  const form_name = 'ktwelveCurriculumForm';
  const formValues = formValueSelector(form_name);
  const [initialValues, setInitialValues] = React.useState<KtwelveCurriculumAttributes | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const companyIdValue = formValues(state, 'company_id') as string;
  const isUpdating = !isNil(initialValues?.id);
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const company_id_to_use =
    curriculum.company_id ||
    (location?.state?.company_id ? location?.state?.company_id : is_above_school_director ? companyIdValue : company);

  const loadKtwelveCurriculums = React.useCallback(async () => {
    if (ktwelve_curriculum_id) {
      const ktwelve_curriculum = await dispatch(
        FETCH_KTWELVE_CURRICULUM.request({
          id: ktwelve_curriculum_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = ktwelve_curriculum;

      const formattedKtwelveCurriculum = {
        id,
        ...attributes,
      };
      setInitialValues(formattedKtwelveCurriculum);
    } else {
      setInitialValues({ company_id: company_id_to_use, curriculum_id } as KtwelveCurriculumAttributes);
    }
  }, [location]);

  const initKtwelveCurriculum = React.useCallback(async () => {
    setLoading(true);
    await loadKtwelveCurriculums();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: KtwelveCurriculumAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
            'curriculum_id',
          ]) as KtwelveCurriculumAttributes;
          await dispatch(
            UPDATE_KTWELVE_CURRICULUM.request({
              id: initialValues?.id as string,
              data: {
                ...rest,
              },
            }),
          );
        } else {
          await dispatch(
            CREATE_KTWELVE_CURRICULUM.request({
              data: {
                ...data,
                company_id: company_id_to_use,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Série inserida na matriz com sucesso.',
          }),
        );
        onSave();
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao inserir série na matrícula.',
          }),
        );
      }
    },
    [initialValues, company_id_to_use, isUpdating, history],
  );

  React.useEffect(() => {
    initKtwelveCurriculum();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <KtwelveCurriculumsForm
      close_form={close_form}
      initialValues={initialValues}
      onSubmit={onSubmit}
      curriculum_id={curriculum_id}
      all_ktwelve_curriculums={all_ktwelve_curriculums}
      course_id={curriculum.course_id}
    />
  );
};

export default KtwelveCurriculumsFormContainer;
