/* eslint-disable camelcase */
import React from 'react';
import { DefaultTheme, makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format, isBefore, isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { compact, every, filter, find, get, isEmpty, map, size, toNumber, uniq } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Collapse,
  IconButton,
  TableFooter,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { css } from '@emotion/react';

import {
  colors,
  InvoiceAttributes,
  InvoiceChargeStatusEnum,
  invoiceChargeStatusOptions,
  InvoiceItemAttributes,
  InvoiceRenegotiationAttributes,
  InvoiceStatusEnum,
  invoiceStatusOptions,
  OrderOptions,
  PaginationType,
  RenegotiationAttributes,
  RenegotiationStatusEnum,
  renegotiationStatusOptions,
  Role,
  ScheduleAttributes,
  TransactionAttributes,
  WalletCreditAttributes,
} from '../../utils/constants';
import InvoiceItemsTable from './InvoiceItemTable';
import {
  ArrowForwardIos,
  DoDisturbAlt,
  ExpandMore,
  FormatListBulleted,
  ForwardToInbox,
  History,
  Info,
  LinkOff,
  PendingActions,
  QrCode,
  ReceiptLong,
  RequestQuote,
  Summarize,
  Sync,
  Visibility,
} from '@mui/icons-material';
import {
  evaluate_permissions,
  formatToCurrency,
  invoiceIsExpired,
  parseText,
  toFixedNumber,
} from '../../utils/functions';
import {
  CREATE_INVOICE_CHARGE,
  DELETE_INVOICE_CHARGE,
  FETCH_INVOICE,
  FETCH_INVOICE_CHARGE_QR_CODE,
  GENERATE_INVOICE_RECEIPTS,
  InvoiceJson,
  QrCodeParams,
  REFRESH_INVOICE_CHARGE,
  SEND_STRAIGHT_NOTIFICATION,
  UNLINK_INVOICE_CHARGE,
} from '../../store/invoices';
import LoadingWithLabel from '../shared/LoadingWithLabel';
import PenIcon from '../icon/PenIcon';
import InvoiceSummary from '../shared/InvoiceSummary';
import { ButtonModal, DeleteItemModal, IconModal } from '../modal/Modal';
import SchedulesTable from './ScheduleTable';
import TransactionsTable from './TransactionsTable';
import { RootState } from '../../store/configureStore';
import TablePaginationActions from '../shared/TablePaginationActions';
import { CANCEL_RENEGOTIATION, InvoiceRenegotiationJson, RenegotiationJson } from '../../store/renegotiations';
import { WalletCreditJson } from '../../store/wallet_credits';
import { InvoiceItemJson } from '../../store/invoice_items';
import { ScheduleJson } from '../../store/schedules';
import { TransactionJson } from '../../store/transactions';
import Loading from '../loading/Loading';
type Props = {
  header_style: {
    color: string;
    fontWeight: string;
    fontSize: string;
  };
};

const RowLoading = ({ loading = false }) => {
  if (!loading) {
    return null;
  }
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          zIndex: '1',
          backgroundColor: colors.lightBlue,
        }}
      >
        <LoadingWithLabel message={'Carregando'} />
      </div>
    </div>
  );
};
const useStyles = makeStyles<DefaultTheme, Props>(() => ({
  table: {
    width: '100%',
    '& .table-body-row': {
      background: colors.lightBlue,
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`,
        padding: '0rem',
      },
      '& th': {
        color: (props) => props.header_style.color,
        fontWeight: (props) => props.header_style.fontWeight,
        fontSize: (props) => props.header_style.fontSize,
      },
      '& td': {
        color: colors.darkBlue,
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none',
      },
      '& th:last-of-type': {
        textAlign: 'center',
      },
      '& .actions': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        '& a,div': {
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          textDecoration: 'none',
          '& > span': {
            color: colors.grayBlue,
          },
          '&:hover': {
            '& >span': {
              color: colors.darkBlue,
            },
          },
        },
        '& div:first-of-type': {
          '& svg': {
            color: colors.blue,
          },
        },
        '& div:last-of-type': {
          '& svg': {
            color: colors.green,
          },
        },
      },
    },
  },
}));

const accordionCss = css`
  background: inherit;
  border-bottom: 1px solid ${colors.grayBlue};
  box-shadow: none;
`;
const accordionSummaryCss = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const RenegotiationInvoiceSummary = ({
  invoices,
  invoice_items,
  title,
  wallet_credits,
}: {
  invoices: InvoiceAttributes[];
  invoice_items: InvoiceItemAttributes[];
  title: string;
  wallet_credits: WalletCreditAttributes[];
}) => {
  return (
    <>
      <span className='title'>{title}</span>
      {invoices.map((invoice) => {
        const invoice_items_attributes = invoice_items.filter((invoice_item) => {
          return invoice_item.invoice_id === ~~invoice.id;
        });
        return (
          <Accordion key={invoice.id} css={accordionCss}>
            <AccordionSummary css={accordionSummaryCss}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ExpandMore />
                <span>{invoice.code}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <InvoiceSummary
                wallet_credits={wallet_credits}
                allow_remove_values={false}
                invoice={invoice}
                invoice_items={invoice_items_attributes}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

const RenegotiationSummary = ({
  renegotiation,
  invoice_items,
  invoice_renegotiations,
  invoices,
  wallet_credits,
  handleUpdateResources,
  renegotiations,
}: {
  renegotiation: RenegotiationAttributes;
  invoices: InvoiceAttributes[];
  invoice_renegotiations?: InvoiceRenegotiationAttributes[];
  invoice_items: InvoiceItemAttributes[];
  wallet_credits: WalletCreditAttributes[];
  handleUpdateResources?: () => Promise<void>;
  renegotiations: RenegotiationAttributes[];
}) => {
  const dispatch = useDispatch();
  const cancelRenegotiation = React.useCallback(
    async ({ setLoading, handleClose }: any) => {
      try {
        setLoading(true);
        await dispatch(
          CANCEL_RENEGOTIATION.request({
            id: renegotiation.id,
          }),
        );

        dispatch(
          success({
            title: 'Cancelamento de renegociação',
            message: 'Renegociação cancelada com sucesso!',
            autoDismiss: 3,
          }),
        );
        handleUpdateResources?.();
        setLoading(false);
        handleClose();
      } catch (err) {
        setLoading(false);
        handleClose();
        dispatch(
          error({
            message: 'Erro no cancelamento da renegociação',
          }),
        );
      }
    },
    [renegotiation],
  );
  const originInvoices = invoices.filter((invoice) => invoice.renegotiation_id === ~~renegotiation.id);
  const generatedInvoices = map(
    filter(
      invoice_renegotiations,
      (invoice_renegotiation) => invoice_renegotiation.renegotiation_id === ~~renegotiation.id,
    ),
    (invoice_renegotiation) => {
      return find(invoices, (invoice) => {
        return invoice.invoice_renegotiation_id === ~~get(invoice_renegotiation, 'id');
      }) as InvoiceAttributes;
    },
  );
  const cantCancelRenegotiation = !every(generatedInvoices, (invoice) => {
    const renegotiation = find(
      renegotiations,
      (renegotiation) => ~~renegotiation.id === get(invoice, 'renegotiation_id'),
    );
    const condition_one = isEmpty(renegotiation);
    const condition_two = renegotiation?.status === RenegotiationStatusEnum.CANCELED;
    return condition_one || condition_two;
  });

  const referenceInvoice = renegotiation.renegotiation_log.invoice_reference;
  const showCancelButton = renegotiation.status === RenegotiationStatusEnum.ACTIVE;
  const display = showCancelButton ? 'block' : 'none';
  const cancelButtonTooltipText = cantCancelRenegotiation
    ? 'Não é possível cancelar essa renegociação, pois alguma das faturas resultantes está envolvida em outra renegociação ativa. Favor cancelar tais renegociações antes de cancelar esta.'
    : 'Cancelar Renegociação';
  return (
    <div
      css={css`
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 1rem;
        & span.title {
          margin: 1rem auto;
          font-weight: bold;
          font-size: 1.25rem;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 1%;
          right: 1%;
        `}
      >
        <span>Renegociação {renegotiationStatusOptions.find((opt) => opt.value === renegotiation.status)?.label}</span>
        <IconModal
          icon={DoDisturbAlt}
          disabled={cantCancelRenegotiation}
          iconProps={{ style: { color: colors.lightRed, display: display } }}
          onConfirm={cancelRenegotiation}
          title='Cancelamento de renegociação'
          message='Deseja cancelar essa renegociação?'
          tooltipText={cancelButtonTooltipText}
          confirmButtonText='Confirma'
        />
      </div>
      <RenegotiationInvoiceSummary
        wallet_credits={wallet_credits}
        invoice_items={invoice_items}
        invoices={originInvoices}
        title='Faturas de origem'
      />
      <span className='title'>Fatura referência</span>
      <Accordion css={accordionCss}>
        <AccordionSummary css={accordionSummaryCss}>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <ExpandMore />
            <span>Resumo da fatura referência</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <InvoiceSummary
            wallet_credits={wallet_credits}
            allow_remove_values={false}
            invoice={referenceInvoice}
            invoice_items={referenceInvoice.invoice_items_attributes}
          />
        </AccordionDetails>
      </Accordion>
      <RenegotiationInvoiceSummary
        wallet_credits={wallet_credits}
        invoice_items={invoice_items}
        invoices={generatedInvoices}
        title='Faturas geradas'
      />
    </div>
  );
};

const QRCodeView = (props: {
  invoice: InvoiceAttributes;
  handleClose?: () => Promise<void>;
  handleUpdateResources?: () => Promise<void>;
  student_name: string;
  invoice_description: string[];
}) => {
  const { invoice, handleClose, handleUpdateResources, student_name, invoice_description } = props;
  const dispatch = useDispatch();
  const [qrCode, setQrCode] = React.useState<QrCodeParams | null>(null);
  const isSicoob = invoice.payment_service === 'sicoob';

  const fetchInvoiceChargeQrCode = React.useCallback(async () => {
    try {
      const result = await dispatch(
        FETCH_INVOICE_CHARGE_QR_CODE.request({
          id: invoice.id,
        }),
      );
      const {
        data: {
          data: {
            attributes: { service_params },
          },
        },
      } = result;
      if (isEmpty(service_params?.qr_code)) {
        throw new Error('Não há QR code');
      }
      dispatch(
        success({
          title: 'Obtenção de QR code',
          message: 'QR code obtido com sucesso!',
          autoDismiss: 3,
        }),
      );
      handleUpdateResources?.();
      return {
        id: result.data.data.id,
        ...result.data.data.attributes,
      };
    } catch (err) {
      handleClose && handleClose();
      dispatch(
        error({
          message: 'Erro na obtenção do QR code',
        }),
      );
    }
  }, [invoice]);
  const initQrCodeView = async () => {
    if (!isSicoob) {
      if (invoice.service_params && invoice?.service_params?.qr_code) {
        const expirationDate = utcToZonedTime(new Date(invoice.service_params.qr_code.expiration_date), 'Brasilia');
        if (new Date().getTime() > expirationDate.getTime()) {
          const new_invoice = (await fetchInvoiceChargeQrCode()) as InvoiceAttributes;
          if (new_invoice.service_params) {
            setQrCode({
              ...new_invoice?.service_params?.qr_code,
              encoded_image: 'data:image/png;base64,' + new_invoice?.service_params?.qr_code?.encoded_image,
            });
          }
        } else {
          setQrCode({
            ...invoice?.service_params?.qr_code,
            encoded_image: 'data:image/png;base64,' + invoice.service_params.qr_code.encoded_image,
          });
        }
      } else {
        fetchInvoiceChargeQrCode();
      }
    } else {
      if (invoice.service_params) {
        setQrCode({
          ...invoice?.service_params?.qr_code,
          encoded_image: invoice?.qr_code_url,
        });
      }
    }
  };

  React.useEffect(() => {
    initQrCodeView();
  }, [invoice]);
  if (!qrCode) {
    return <LoadingWithLabel message='Carregando QR code' />;
  }
  const expirationDate = qrCode.expiration_date && format(new Date(qrCode.expiration_date), 'dd/MM/yyyy HH:mm');
  const invoicePdfUrl = invoice.invoice_pdf_url
  return (
    <div
      css={css`
        align-items: center;
        display: grid;
        gap: 1rem;
        justify-items: center;
      `}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            align-items: center;
            display: grid;
            gap: 1rem;
            justify-items: start;
            width: 50%;
            & .qr-code {
              word-break: break-all;
            }
          `}
        >
          <span className='subtitle-two'>Aluno</span>
          <span>{student_name}</span>
          <span className='subtitle-two'>Descrição</span>
          {invoice_description.map((item) => (
            <span key={item}>{item}</span>
          ))}
          <span className='subtitle-two'>Código de Pagamento</span>
          <span className='qr-code'>{qrCode.payload}</span>
          <button
            onClick={() => {
              navigator.clipboard.writeText(qrCode.payload);
            }}
            className='small green'
            css={css`
              width: fit-content;
              font-weight: bold;
            `}
          >
            <span>Copiar código</span>
          </button>
        </div>
        <div
          css={css`
            align-items: center;
            display: grid;
            flex-basis: 40%;
            gap: 1rem;
            justify-items: center;
            width: 40%;
            & img {
              width: 100%;
            }
          `}
        >
          <span className='subtitle-two'>Pague com o pix</span>
          <img src={qrCode?.encoded_image} />
          {!isSicoob && <span>Este QRCode expira ás {expirationDate}</span>}
        </div>
      </div>
      <span>Importante: Após a confirmação do pagamento via pix, o prazo para a compensação é automático.</span>
      <hr />
      <a
        href={invoicePdfUrl as string}
        target='_blank'
        rel='noreferrer'
        css={css`
          justify-self: start;
          display: flex;
        `}
      >
        <span>Pagar via boleto</span>
        <ArrowForwardIos />
      </a>
    </div>
  );
};

const InvoiceActions = (props: {
  handleUpdateResources?: () => Promise<void>;
  invoice: InvoiceAttributes;
  setExpandedInvoices: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedInvoiceDescription: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedSchedules: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedTransactions: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedRenegotiations: React.Dispatch<React.SetStateAction<number[]>>;
  setExpandedInvoiceRenegotiations: React.Dispatch<React.SetStateAction<number[]>>;
  changeInvoiceStepTab?: (invoice?: InvoiceAttributes, destiny?: string) => void;
  is_representative_view?: boolean;
  student_name: string;
  invoice_description: string[];
  setLoadingInvoices: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        message: string;
      }[]
    >
  >;
  loadingInvoices: {
    id: string;
    message: string;
  }[];
  expandedInvoiceDescription: number[];
  expandedInvoices: number[];
  expandedTransactions: number[];
  expandedInvoiceRenegotiations: number[];
  expandedRenegotiations: number[];
  expandedSchedules: number[];
  resetFilters?: () => void;
  is_invoices_view: boolean;
}) => {
  const {
    changeInvoiceStepTab,
    expandedInvoiceDescription,
    expandedInvoiceRenegotiations,
    expandedInvoices,
    expandedRenegotiations,
    expandedSchedules,
    expandedTransactions,
    handleUpdateResources,
    invoice_description,
    invoice,
    is_representative_view = false,
    loadingInvoices,
    resetFilters,
    setExpandedInvoiceDescription,
    setExpandedInvoiceRenegotiations,
    setExpandedInvoices,
    setExpandedRenegotiations,
    setExpandedSchedules,
    setExpandedTransactions,
    setLoadingInvoices,
    student_name,
    is_invoices_view,
  } = props;
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile },
    ui: {
      loading: { open },
    },
  } = state;
  const disable_actions = open;
  const is_representative = is_representative_view;
  const config = invoice.company_config;
  const createInvoiceCharge = React.useCallback(
    async ({ setLoading, handleClose }: any, invoice_id: string) => {
      try {
        setLoading(true);
        setLoadingInvoices((current) => current.concat({ id: invoice_id, message: 'Criando cobrança' }));
        await dispatch(
          CREATE_INVOICE_CHARGE.request({
            id: invoice_id,
          }),
        );

        dispatch(
          success({
            title: 'Criação de cobrança',
            message: 'Cobrança criada com sucesso!',
            autoDismiss: 3,
          }),
        );
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        setLoading(false);
        resetFilters?.();
        handleUpdateResources?.();
        handleClose();
      } catch (err) {
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        setLoading(false);
        handleClose();
        dispatch(
          error({
            message: 'Erro na criação da cobrança',
          }),
        );
      }
    },
    [invoice],
  );

  const sendStraightNotification = React.useCallback(
    async ({ invoice_id }: { invoice_id: string }) => {
      try {
        setLoadingInvoices((current) => current.concat({ id: invoice_id, message: 'Enviando notificação' }));
        await dispatch(
          SEND_STRAIGHT_NOTIFICATION.request({
            id: invoice_id,
          }),
        );

        dispatch(
          success({
            title: 'Enviando notificação',
            message: 'Notificação enviada com sucesso!',
            autoDismiss: 3,
          }),
        );
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        handleUpdateResources?.();
        resetFilters?.();
      } catch (err) {
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        dispatch(
          error({
            message: 'Erro na notificação',
          }),
        );
      }
    },
    [invoice],
  );
  const deleteInvoiceCharge = React.useCallback(
    async ({ setLoading, handleClose }: any, invoice_id: string) => {
      try {
        setLoading(true);
        setLoadingInvoices((current) => current.concat({ id: invoice_id, message: 'Cancelando cobrança' }));
        await dispatch(
          DELETE_INVOICE_CHARGE.request({
            id: invoice_id,
          }),
        );

        dispatch(
          success({
            title: 'Cancelamento de cobrança',
            message: 'Cobrança cancelada com sucesso!',
            autoDismiss: 3,
          }),
        );
        setLoading(false);
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        handleUpdateResources?.();
        handleClose();
      } catch (err) {
        setLoading(false);
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        handleClose();
        dispatch(
          error({
            message: 'Erro no cancelamento da cobrança',
          }),
        );
      }
    },
    [invoice],
  );

  const unlinkInvoiceCharge = React.useCallback(
    async ({ setLoading, handleClose }: any, invoice_id: string) => {
      try {
        setLoading(true);
        setLoadingInvoices((current) => current.concat({ id: invoice_id, message: 'Desvinculando cobrança' }));
        await dispatch(
          UNLINK_INVOICE_CHARGE.request({
            id: invoice_id,
          }),
        );

        dispatch(
          success({
            title: 'Desvincular cobrança',
            message: 'Cobrança desvinculada com sucesso!',
            autoDismiss: 3,
          }),
        );
        setLoading(false);
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        handleUpdateResources?.();
        handleClose();
      } catch (err) {
        setLoading(false);
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        handleClose();
        dispatch(
          error({
            message: 'Erro ao desvincular cobrança',
          }),
        );
      }
    },
    [invoice],
  );
  const refreshInvoiceCharge = React.useCallback(
    async ({ invoice_id }: { invoice_id: string }) => {
      try {
        setLoadingInvoices((current) => current.concat({ id: invoice_id, message: 'Atualizando' }));
        await dispatch(
          REFRESH_INVOICE_CHARGE.request({
            id: invoice_id,
          }),
        );

        dispatch(
          success({
            title: 'Atualização de cobrança',
            message: 'Cobrança atualizada com sucesso!',
            autoDismiss: 3,
          }),
        );
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        handleUpdateResources?.();
      } catch (err) {
        setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
        dispatch(
          error({
            message: 'Erro na atualização da cobrança',
          }),
        );
      }
    },
    [invoice],
  );

  const generateInvoiceReceipt = React.useCallback(async () => {
    try {
      setLoadingInvoices((current) => current.concat({ id: invoice.id, message: 'Gerando recibos' }));
      const response = await dispatch(
        GENERATE_INVOICE_RECEIPTS.request({
          params: {
            filters: {
              invoice_ids: [invoice.id],
            },
          },
        }),
      );
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `recibos-${new Date().getTime()}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }
      dispatch(
        success({
          title: 'Geração de recibo',
          message: 'Recibo gerado com sucesso!',
          autoDismiss: 3,
        }),
      );
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice.id));
    } catch (err) {
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice.id));
      dispatch(
        error({
          message: 'Erro na geração do recibo',
        }),
      );
    }
  }, [invoice]);

  const canAdjust = evaluate_permissions.can_adjust_invoice({
    role: profile.role,
    invoice_status: invoice.status as InvoiceStatusEnum,
    invoice_created_at: invoice.created_at,
    invoice_expiration_date: invoice.expiration_date,
    days_config: toNumber(config?.days_to_block_invoice_adjustment_after_generation || 0),
  });
  const canAdjustInvoice = canAdjust && !is_representative;
  const can_unlink_invoice = evaluate_permissions.can_unlink_invoice(
    profile.role,
    invoice.status,
    invoice.charge_status,
  );

  const isLoading = loadingInvoices.length > 0 && loadingInvoices.some((item) => item.id === invoice.id);
  if (isLoading) {
    return <RowLoading loading={isLoading} />;
  }
  const invoiceDescriptionExpanded = expandedInvoiceDescription.includes(~~invoice.id);
  const originRenegotiationExpanded = expandedRenegotiations.includes(~~invoice.id);
  const invoiceRenegotiationExpanded = expandedInvoiceRenegotiations.includes(~~invoice.id);
  const schedulesExpanded = expandedSchedules.includes(~~invoice.id);
  const transactionsExpanded = expandedTransactions.includes(~~invoice.id);
  const expandedInvoiceItems = expandedInvoices.includes(~~invoice.id);
  return (
    <div style={{ position: 'relative' }}>
      {!is_representative && (
        <Tooltip title={`${invoiceDescriptionExpanded ? 'Ocultar' : 'Ver'} resumo da fatura`}>
          <IconButton
            onClick={() =>
              setExpandedInvoiceDescription((current) => {
                if (current.includes(~~invoice.id)) {
                  return current.filter((item) => item !== ~~invoice.id);
                } else {
                  return current.concat(~~invoice.id);
                }
              })
            }
          >
            <Visibility />
          </IconButton>
        </Tooltip>
      )}
      {invoice.renegotiation_id && !is_representative && (
        <Tooltip
          title={`${invoiceRenegotiationExpanded ? 'Ocultar' : 'Ver'} renegociação em que fatura foi renegociada`}
        >
          <IconButton
            onClick={() =>
              setExpandedRenegotiations((current) => {
                if (current.includes(~~invoice.id)) {
                  return current.filter((item) => item !== ~~invoice.id);
                } else {
                  return current.concat(~~invoice.id);
                }
              })
            }
          >
            <ReceiptLong />
          </IconButton>
        </Tooltip>
      )}
      {invoice.invoice_renegotiation_id && !is_representative && (
        <Tooltip title={`${originRenegotiationExpanded ? 'Ocultar' : 'Ver'} renegociação pela qual fatura foi gerada`}>
          <IconButton
            onClick={() =>
              setExpandedInvoiceRenegotiations((current) => {
                if (current.includes(~~invoice.id)) {
                  return current.filter((item) => item !== ~~invoice.id);
                } else {
                  return current.concat(~~invoice.id);
                }
              })
            }
          >
            <ReceiptLong style={{ color: colors.blue }} />
          </IconButton>
        </Tooltip>
      )}
      {!is_representative && (
        <Tooltip title={`${schedulesExpanded ? 'Ocultar' : 'Ver'} agendamentos da fatura`}>
          <IconButton
            onClick={() =>
              setExpandedSchedules((current) => {
                if (current.includes(~~invoice.id)) {
                  return current.filter((item) => item !== ~~invoice.id);
                } else {
                  return current.concat(~~invoice.id);
                }
              })
            }
          >
            <PendingActions />
          </IconButton>
        </Tooltip>
      )}
      {invoice.status === InvoiceStatusEnum.ACTIVE && !is_representative && (
        <Tooltip title='Enviar notificação para responsável'>
          <IconButton onClick={() => sendStraightNotification({ invoice_id: invoice.id })}>
            <ForwardToInbox style={{ color: colors.darkBlue }} />
          </IconButton>
        </Tooltip>
      )}
      {!is_representative && (
        <Tooltip title={`${transactionsExpanded ? 'Ocultar' : 'Ver'} transações da fatura`}>
          <IconButton
            onClick={() =>
              setExpandedTransactions((current) => {
                if (current.includes(~~invoice.id)) {
                  return current.filter((item) => item !== ~~invoice.id);
                } else {
                  return current.concat(~~invoice.id);
                }
              })
            }
          >
            <History />
          </IconButton>
        </Tooltip>
      )}
      {invoice.status === InvoiceStatusEnum.TO_PAYMENT_SERVICE &&
        invoice.representative_id &&
        invoice.expiration_date &&
        compact([invoice.expiration_date, invoice.punctuality_expiration_date]).every(date => isSameDay(new Date(date), new Date()) || isBefore(new Date(), new Date(date))) &&
        !is_representative && (
          <IconModal
            icon={RequestQuote}
            disabled={disable_actions}
            iconProps={{ style: { color: colors.darkBlue } }}
            title={`Gerar cobrança`}
            message={`Deseja gerar cobrança para fatura ${invoice.code}?`}
            tooltipText={'Gerar cobrança'}
            cancelButtonText='Fechar'
            confirmButtonText='Gerar cobrança'
            onConfirm={async (props) => createInvoiceCharge(props, invoice.id)}
          />
        )}
      {invoice.status === InvoiceStatusEnum.ACTIVE && !is_representative && (
        <IconModal
          icon={QrCode}
          iconProps={{ style: { color: colors.darkBlue } }}
          title='QR code para pagamento com pix'
          tooltipText={'Ver QR Code para pagamento com pix'}
          cancelButtonText='Fechar'
          singleButton
          disabled={disable_actions}
          onConfirm={() => null}
        >
          <QRCodeView
            invoice={invoice}
            handleUpdateResources={handleUpdateResources}
            student_name={student_name}
            invoice_description={invoice_description}
          />
        </IconModal>
      )}
      {is_representative && invoice.status === InvoiceStatusEnum.ACTIVE && (
        <ButtonModal
          disabled={disable_actions}
          onConfirm={() => null}
          label='Pagar'
          title='Pagamento da Fatura'
          singleButton
          labelProps={{
            css: css`
              font-weight: bold;
              cursor: pointer;
              color: ${colors.cyanBlue};
              border: none;
              &:hover {
                text-decoration: underline;
              }
              & span {
                color: ${colors.cyanBlue} !important;
              }
            `,
          }}
        >
          <QRCodeView
            invoice={invoice}
            handleUpdateResources={handleUpdateResources}
            student_name={student_name}
            invoice_description={invoice_description}
          />
        </ButtonModal>
      )}
      {!is_invoices_view && invoice.status === InvoiceStatusEnum.FINISHED && invoice.charge_status === InvoiceChargeStatusEnum.RECEIVED && (
        <Tooltip title='Gerar recibo para fatura'>
          <IconButton onClick={generateInvoiceReceipt}>
            <Summarize style={{ color: colors.darkBlue }} />
          </IconButton>
        </Tooltip>
      )}
      {!is_representative && (
        <Tooltip title={`${expandedInvoiceItems ? 'Ocultar' : 'Ver'} items da fatura`}>
          <IconButton
            onClick={() =>
              setExpandedInvoices((current) => {
                if (current.includes(~~invoice.id)) {
                  return current.filter((item) => item !== ~~invoice.id);
                } else {
                  return current.concat(~~invoice.id);
                }
              })
            }
          >
            <FormatListBulleted />
          </IconButton>
        </Tooltip>
      )}
      {canAdjustInvoice && (
        <Tooltip title='Ajustar Fatura'>
          <IconButton onClick={() => changeInvoiceStepTab && changeInvoiceStepTab(invoice)}>
            <PenIcon />
          </IconButton>
        </Tooltip>
      )}
      {!is_representative && (
        <Tooltip title='Atualizar fatura'>
          <IconButton onClick={() => refreshInvoiceCharge({ invoice_id: invoice.id })} disabled={disable_actions}>
            <Sync />
          </IconButton>
        </Tooltip>
      )}
      <div style={{ display: 'none' }}>
        <DeleteItemModal
          tooltipText='Cancelar cobrança'
          message={`Deseja cancelar essa cobrança?`}
          onConfirm={async (props) => deleteInvoiceCharge(props, invoice.id)}
        />
      </div>
      {can_unlink_invoice && (
        <IconModal
          disabled={disable_actions}
          tooltipText='Desvincular cobrança'
          icon={LinkOff}
          onConfirm={(props) => unlinkInvoiceCharge(props, invoice.id)}
          title={`Deseja desvincular a cobrança ${invoice.code}`}
        />
      )}
    </div>
  );
};

const SummaryRow = ({
  invoice,
  load_resources = false,
  loadingInvoices,
  setLoadingInvoices,
  open = false,
}: {
  invoice: InvoiceAttributes;
  loadingInvoices: {
    id: string;
    message: string;
  }[];
  setLoadingInvoices: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        message: string;
      }[]
    >
  >;
  load_resources?: boolean;
  open: boolean;
}) => {
  const initial_data = {
    wallet_credits: [] as WalletCreditAttributes[],
    invoice_items: [] as InvoiceItemAttributes[],
  };
  const dispatch = useDispatch();
  const isLoading = loadingInvoices.some((item) => item.id === invoice.id);
  const [data, setData] = React.useState(initial_data);
  const [loadingData, setLoadingData] = React.useState(load_resources);
  const fetchInvoice = React.useCallback(async () => {
    const invoice_id = invoice.id;
    try {
      setLoadingInvoices((current) => current.concat({ id: invoice_id, message: 'Carregando resumo da fatura' }));
      const response = await dispatch(
        FETCH_INVOICE.request({
          id: invoice_id,
          params: {
            filters: {
              include: ['wallet_credits', 'billings.invoice_items', 'representative.account.user'].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const wallet_credits = map(
        filter(
          included,
          (incl) => incl.type === 'wallet_credits' && incl.attributes.invoice_id === ~~invoice.id,
        ) as WalletCreditJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as WalletCreditAttributes[];
      const invoice_items = map(
        filter(
          included,
          (incl) => incl.type === 'invoice_items' && incl.attributes.invoice_id === ~~invoice.id,
        ) as InvoiceItemJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as InvoiceItemAttributes[];
      setData({
        invoice_items,
        wallet_credits,
      });
      dispatch(
        success({
          title: 'Resumo da fatura',
          message: 'Resumo da fatura carregado com sucesso!',
          autoDismiss: 3,
        }),
      );
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
    } catch (err) {
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
      dispatch(
        error({
          message: 'Erro no carregamento do resumo da fatura',
        }),
      );
    }
    setLoadingData(false);
  }, [invoice]);

  React.useEffect(() => {
    if (load_resources && open) {
      fetchInvoice();
    }
  }, []);

  if (!open) {
    return null;
  }

  if (isLoading) {
    return <RowLoading loading={isLoading} />;
  }

  if (loadingData) {
    return <Loading />;
  }

  return (
    <InvoiceSummary
      wallet_credits={data.wallet_credits}
      invoice={invoice}
      invoice_items={data.invoice_items}
      allow_remove_values={false}
    />
  );
};

const RenegotiationRow = ({
  invoice,
  load_resources = false,
  loadingInvoices,
  setLoadingInvoices,
  handleUpdateResources,
  open = false,
  is_invoice_renegotiation_row = false,
}: {
  invoice: InvoiceAttributes;
  loadingInvoices: {
    id: string;
    message: string;
  }[];
  setLoadingInvoices: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        message: string;
      }[]
    >
  >;
  load_resources?: boolean;
  handleUpdateResources?: () => Promise<void>;
  open: boolean;
  is_invoice_renegotiation_row: boolean;
}) => {
  const initial_data = {
    handleUpdateResources,
    invoice_items: [] as InvoiceItemAttributes[],
    invoice_renegotiations: [] as InvoiceRenegotiationAttributes[],
    invoices: [] as InvoiceAttributes[],
    renegotiation: null as RenegotiationAttributes | null,
    renegotiations: [] as RenegotiationAttributes[],
    wallet_credits: [] as WalletCreditAttributes[],
  };
  const dispatch = useDispatch();
  const isLoading = loadingInvoices.some((item) => item.id === invoice.id);
  const [loadingData, setLoadingData] = React.useState(load_resources);
  const [data, setData] = React.useState(initial_data);
  const fetchInvoice = React.useCallback(async () => {
    const invoice_id = invoice.id;
    try {
      setLoadingInvoices((current) => current.concat({ id: invoice_id, message: 'Carregando renegociação' }));
      const response = await dispatch(
        FETCH_INVOICE.request({
          id: invoice_id,
          params: {
            filters: {
              include: [
                'wallet_credits',
                'billings.invoice_items',
                'invoice_renegotiation.renegotiation.invoices.billings.invoice_items',
                'invoice_renegotiation.renegotiation.invoice_renegotiations.invoice.billings.invoice_items',
                'renegotiation.invoice_renegotiations.invoice.billings.invoice_items',
                'renegotiation.invoices.billings.invoice_items',
              ].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const wallet_credits = map(
        filter(
          included,
          (incl) => incl.type === 'wallet_credits' && incl.attributes.invoice_id === ~~invoice.id,
        ) as WalletCreditJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as WalletCreditAttributes[];
      const invoice_items = map(
        filter(included, (incl) => incl.type === 'invoice_items' && incl.attributes.invoice_id) as InvoiceItemJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as InvoiceItemAttributes[];
      const renegotiations = map(
        filter(included, (incl) => incl.type === 'renegotiations') as RenegotiationJson[],
        (item) => ({ id: item.id, ...item.attributes }),
      ) as RenegotiationAttributes[];
      const invoice_renegotiations = map(
        filter(included, (incl) => incl.type === 'invoice_renegotiations') as InvoiceRenegotiationJson[],
        (item) => ({ id: item.id, ...item.attributes }),
      ) as InvoiceRenegotiationAttributes[];
      const invoices = map(filter(included, (incl) => incl.type === 'invoices') as InvoiceJson[], (item) => ({
        id: item.id,
        ...item.attributes,
      })) as InvoiceAttributes[];
      const invoice_renegotiation = find(
        invoice_renegotiations,
        (ir) => ~~ir.id === invoice.invoice_renegotiation_id,
      ) as InvoiceRenegotiationAttributes;
      const invoice_renegotiation_renegotiation = find(
        renegotiations,
        (r) => ~~r.id === get(invoice_renegotiation, 'renegotiation_id'),
      ) as RenegotiationAttributes;
      const renegotiation = find(renegotiations, (r) => ~~r.id === invoice.renegotiation_id) as RenegotiationAttributes;

      setData({
        invoice_items,
        wallet_credits,
        renegotiation: is_invoice_renegotiation_row ? invoice_renegotiation_renegotiation : renegotiation,
        renegotiations,
        invoice_renegotiations,
        invoices: [...invoices, invoice],
        handleUpdateResources,
      });
      dispatch(
        success({
          title: 'Renegociação',
          message: 'Renegociação carregada com sucesso!',
          autoDismiss: 3,
        }),
      );
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
    } catch (err) {
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
      dispatch(
        error({
          message: 'Erro no carregamento da renegociação',
        }),
      );
    }
    setLoadingData(false);
  }, [invoice]);

  React.useEffect(() => {
    if (load_resources) {
      fetchInvoice();
    }
  }, []);

  if (!open) {
    return null;
  }

  if (isLoading) {
    return <RowLoading loading={isLoading} />;
  }

  if (loadingData) {
    return <Loading />;
  }

  return (
    <RenegotiationSummary
      handleUpdateResources={data.handleUpdateResources}
      invoice_items={data.invoice_items}
      invoice_renegotiations={data.invoice_renegotiations}
      invoices={data.invoices}
      renegotiation={data.renegotiation as RenegotiationAttributes}
      renegotiations={data.renegotiations}
      wallet_credits={data.wallet_credits}
    />
  );
};

const SchedulesRow = ({
  invoice,
  load_resources = false,
  loadingInvoices,
  setLoadingInvoices,
  open = false,
}: {
  invoice: InvoiceAttributes;
  loadingInvoices: {
    id: string;
    message: string;
  }[];
  setLoadingInvoices: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        message: string;
      }[]
    >
  >;
  load_resources?: boolean;
  open: boolean;
}) => {
  const initial_data = {
    schedules: [] as ScheduleAttributes[],
  };
  const dispatch = useDispatch();
  const isLoading = loadingInvoices.some((item) => item.id === invoice.id);
  const [loadingData, setLoadingData] = React.useState(load_resources);
  const [data, setData] = React.useState(initial_data);
  const fetchInvoice = React.useCallback(async () => {
    const invoice_id = invoice.id;
    try {
      setLoadingInvoices((current) => current.concat({ id: invoice_id, message: 'Carregando agendamentos' }));
      const response = await dispatch(
        FETCH_INVOICE.request({
          id: invoice_id,
          params: {
            filters: {
              include: ['schedules'].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const schedules = map(
        filter(
          included,
          (incl) => incl.type === 'schedules' && incl.attributes.invoice_id === ~~invoice.id,
        ) as ScheduleJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as ScheduleAttributes[];

      setData({
        schedules,
      });
      dispatch(
        success({
          title: 'Agendamentos',
          message: 'Agendamentos carregados com sucesso!',
          autoDismiss: 3,
        }),
      );
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
    } catch (err) {
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
      dispatch(
        error({
          message: 'Erro no carregamento dos agendamentos',
        }),
      );
    }
    setLoadingData(false);
  }, [invoice]);

  React.useEffect(() => {
    if (load_resources) {
      fetchInvoice();
    }
  }, []);

  if (!open) {
    return null;
  }

  if (isLoading) {
    return <RowLoading loading={isLoading} />;
  }

  if (loadingData) {
    return <Loading />;
  }

  return <SchedulesTable schedules={data.schedules} />;
};
const TransactionsRow = ({
  invoice,
  load_resources = false,
  loadingInvoices,
  setLoadingInvoices,
  open = false,
}: {
  invoice: InvoiceAttributes;
  loadingInvoices: {
    id: string;
    message: string;
  }[];
  setLoadingInvoices: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        message: string;
      }[]
    >
  >;
  load_resources?: boolean;
  open: boolean;
}) => {
  const initial_data = {
    transactions: [] as TransactionAttributes[],
  };
  const dispatch = useDispatch();
  const isLoading = loadingInvoices.some((item) => item.id === invoice.id);
  const [data, setData] = React.useState(initial_data);
  const [loadingData, setLoadingData] = React.useState(load_resources);
  const fetchInvoice = React.useCallback(async () => {
    const invoice_id = invoice.id;
    try {
      setLoadingInvoices((current) =>
        current.concat({ id: invoice_id, message: 'Carregando histórico de transações' }),
      );
      const response = await dispatch(
        FETCH_INVOICE.request({
          id: invoice_id,
          params: {
            filters: {
              include: ['transactions'].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const transactions = map(
        filter(
          included,
          (incl) => incl.type === 'transactions' && incl.attributes.transactable_id === ~~invoice.id,
        ) as TransactionJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as TransactionAttributes[];

      setData({
        transactions,
      });
      dispatch(
        success({
          title: 'Transações',
          message: 'Transações carregadas!',
          autoDismiss: 3,
        }),
      );
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
    } catch (err) {
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
      dispatch(
        error({
          message: 'Erro no carregamento de transações',
        }),
      );
    }
    setLoadingData(false);
  }, [invoice]);

  React.useEffect(() => {
    if (load_resources) {
      fetchInvoice();
    }
  }, []);

  if (!open) {
    return null;
  }
  if (isLoading) {
    return <RowLoading loading={isLoading} />;
  }

  if (loadingData) {
    return <Loading />;
  }

  return <TransactionsTable transactions={data.transactions} />;
};

const InvoiceItemsRow = ({
  invoice,
  load_resources = false,
  loadingInvoices,
  setLoadingInvoices,
  open = false,
}: {
  invoice: InvoiceAttributes;
  loadingInvoices: {
    id: string;
    message: string;
  }[];
  setLoadingInvoices: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        message: string;
      }[]
    >
  >;
  load_resources?: boolean;
  open: boolean;
}) => {
  const initial_data = {
    wallet_credits: [] as WalletCreditAttributes[],
    invoice_items: [] as InvoiceItemAttributes[],
  };
  const dispatch = useDispatch();
  const isLoading = loadingInvoices.some((item) => item.id === invoice.id);
  const [loadingData, setLoadingData] = React.useState(load_resources);
  const [data, setData] = React.useState(initial_data);
  const fetchInvoice = React.useCallback(async () => {
    const invoice_id = invoice.id;
    try {
      setLoadingInvoices((current) => current.concat({ id: invoice_id, message: 'Carregando itens de fatura' }));
      const response = await dispatch(
        FETCH_INVOICE.request({
          id: invoice_id,
          params: {
            filters: {
              include: ['wallet_credits', 'billings.invoice_items'].join(','),
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const wallet_credits = map(
        filter(
          included,
          (incl) => incl.type === 'wallet_credits' && incl.attributes.invoice_id === ~~invoice.id,
        ) as WalletCreditJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as WalletCreditAttributes[];
      const invoice_items = map(
        filter(
          included,
          (incl) => incl.type === 'invoice_items' && incl.attributes.invoice_id === ~~invoice.id,
        ) as InvoiceItemJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      ) as InvoiceItemAttributes[];
      setData({
        invoice_items,
        wallet_credits,
      });
      dispatch(
        success({
          title: 'Itens de fatura',
          message: 'Itens de fatura carregados com sucesso!',
          autoDismiss: 3,
        }),
      );
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
    } catch (err) {
      setLoadingInvoices((current) => current.filter((item) => item.id !== invoice_id));
      dispatch(
        error({
          message: 'Erro no carregamento dos itens de fatura',
        }),
      );
    }
    setLoadingData(false);
  }, [invoice]);

  React.useEffect(() => {
    if (load_resources) {
      fetchInvoice();
    }
  }, []);

  if (!open) {
    return null;
  }

  if (isLoading) {
    return <RowLoading loading={isLoading} />;
  }

  if (loadingData) {
    return <Loading />;
  }

  return (
    <InvoiceItemsTable invoices={[invoice]} invoice_items={data.invoice_items} wallet_credits={data.wallet_credits} />
  );
};

const InvoiceTable = (props: {
  changeInvoiceStepTab?: (invoice?: InvoiceAttributes, destiny?: string) => void;
  handleUpdateResources?: () => Promise<void>;
  handlePageChange?: (_: any, newPage: number) => void;
  handleChangePageSize?: (e: any) => void;
  handleRequestSort?: (event: React.MouseEvent<unknown>, property: keyof InvoiceAttributes) => void;
  invoice_items: InvoiceItemAttributes[];
  invoices: InvoiceAttributes[];
  invoicesDisposition: InvoiceAttributes[];
  pagination?: PaginationType;
  selectedInvoices?: number[];
  setSelectedInvoices?: React.Dispatch<React.SetStateAction<number[]>>;
  is_representative_view?: boolean;
  order?: OrderOptions;
  orderBy?: string;
  is_invoices_view?: boolean;
  resetFilters?: () => void;
}) => {
  const {
    changeInvoiceStepTab,
    handleUpdateResources,
    invoice_items,
    invoices,
    invoicesDisposition,
    handlePageChange,
    handleChangePageSize,
    handleRequestSort,
    pagination,
    order,
    is_representative_view = false,
    orderBy,
    is_invoices_view = false,
    setSelectedInvoices,
    selectedInvoices,
    resetFilters,
  } = props;

  const classes = useStyles({
    header_style: is_representative_view
      ? {
          color: colors.darkBlue,
          fontWeight: 'bold',
          fontSize: '1vmax',
        }
      : {
          color: colors.darkGrayBlue,
          fontWeight: 'regular',
          fontSize: 'inherit',
        },
  });
  const [expandedInvoices, setExpandedInvoices] = React.useState<number[]>([]);
  const [expandedInvoiceDescription, setExpandedInvoiceDescription] = React.useState<number[]>([]);
  const [expandSchedules, setExpandedSchedules] = React.useState<number[]>([]);
  const [expandedTransactions, setExpandedTransactions] = React.useState<number[]>([]);
  const [expandedRenegotiations, setExpandedRenegotiations] = React.useState<number[]>([]);
  const [expandedInvoiceRenegotiations, setExpandedInvoiceRenegotiations] = React.useState<number[]>([]);
  const [loadingInvoices, setLoadingInvoices] = React.useState<{ id: string; message: string }[]>([]);
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile },
  } = state;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);

  const columns = [
    ...(is_representative_view
      ? ['Aluno']
      : is_invoices_view && is_above_school_director
      ? ['', 'Unidade', 'Código Fatura', 'Responsável', 'Aluno']
      : is_invoices_view
      ? ['', 'Código Fatura', 'Responsável', 'Aluno']
      : ['', 'Código Fatura', 'Responsável']),
    'Status Fatura',
    ...(!is_representative_view ? ['Status da Cobrança'] : []),
    'Data Vencimento',
    'Data Desconto',
    'Valor',
    'Desconto',
    ...(is_representative_view ? ['Detalhes'] : []),
    'Ações',
  ];

  const selectable_invoices = invoicesDisposition.filter((invoice) =>
    [InvoiceStatusEnum.ACTIVE, InvoiceStatusEnum.TO_PAYMENT_SERVICE, InvoiceStatusEnum.FINISHED].includes(
      invoice.status as InvoiceStatusEnum,
    ),
  );
  const displayed_invoices_ids = selectable_invoices.map((item) => item.id);
  const indeterminate_check =
    displayed_invoices_ids.length > 0 && selectedInvoices && displayed_invoices_ids.length < selectedInvoices.length;
  const all_checked =
    size(selectedInvoices) > 0 &&
    size(displayed_invoices_ids) > 0 &&
    size(selectedInvoices) === size(displayed_invoices_ids);
  const cant_select_all = size(displayed_invoices_ids) === 0;
  const onCheckAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSelectedInvoices &&
      setSelectedInvoices(() => {
        if (checked) {
          return selectable_invoices.map((item) => ~~item.id);
        } else {
          return [];
        }
      });
  };
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => {
                  if (item === 'Código Fatura') {
                    return (
                      <TableCell align='center' sortDirection={orderBy === 'code' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'code'}
                          direction={orderBy === 'code' ? order : 'asc'}
                          onClick={(e) => handleRequestSort?.(e, 'code')}
                        >
                          Código Fatura
                        </TableSortLabel>
                      </TableCell>
                    );
                  } else if (item === 'Data Vencimento') {
                    return (
                      <TableCell align='center' sortDirection={orderBy === 'expiration_date' ? order : undefined}>
                        <TableSortLabel
                          active={orderBy === 'expiration_date'}
                          direction={orderBy === 'expiration_date' ? order : 'asc'}
                          onClick={(e) => handleRequestSort?.(e, 'expiration_date')}
                        >
                          Data Vencimento
                        </TableSortLabel>
                      </TableCell>
                    );
                  } else if (item === '' && index === 0) {
                    return (
                      <TableCell align='left'>
                        <Checkbox
                          indeterminate={indeterminate_check}
                          checked={all_checked}
                          disabled={cant_select_all}
                          onChange={onCheckAll}
                        />
                      </TableCell>
                    );
                  } else if (item === 'Aluno') {
                    return <TableCell align={is_invoices_view ? 'center' : 'left'}>Aluno</TableCell>;
                  }
                  return (
                    <TableCell align='center' key={item}>
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicesDisposition.map((invoice, index) => {
                const isLast = index === invoices.length - 1;
                const statusInfo =
                  is_representative_view &&
                  [InvoiceStatusEnum.ACTIVE, InvoiceStatusEnum.TO_PAYMENT_SERVICE].includes(
                    invoice.status as InvoiceStatusEnum,
                  )
                    ? 'Em aberto'
                    : is_representative_view && InvoiceStatusEnum.FINISHED === invoice.status
                    ? 'Paga'
                    : invoiceStatusOptions.find((option) => option.value === invoice.status)?.label;
                const expirationDate =
                  invoice.expiration_date && format(new Date(invoice.expiration_date), 'dd/MM/yyyy');
                const chargeStatusInfo = invoiceChargeStatusOptions.find(
                  (option) => option.value === invoice.charge_status,
                )?.label;
                const punctualityDate =
                  invoice.punctuality_expiration_date &&
                  format(new Date(invoice.punctuality_expiration_date), 'dd/MM/yyyy');

                const invoiceItems = invoice_items
                  .filter((invoice_item) => invoice_item.invoice_id === ~~invoice.id)
                  .sort((a, b) => Number(b.active) - Number(a.active));
                const activeInvoiceItems = invoiceItems;
                const onCheckInvoice = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  setSelectedInvoices &&
                    setSelectedInvoices((current) => {
                      if (checked) {
                        return current.concat(~~invoice.id);
                      } else {
                        return current.filter((item) => item !== ~~invoice.id);
                      }
                    });
                };

                const is_expired = invoiceIsExpired(invoice);
                const is_invoice_expired = is_expired && invoice.status !== InvoiceStatusEnum.FINISHED;
                const cantSelectInvoice = [InvoiceStatusEnum.CANCELED, InvoiceStatusEnum.RENEGOTIATED].includes(
                  invoice.status as InvoiceStatusEnum,
                );
                const invoiceDescription = uniq(activeInvoiceItems.map((invoice_item) => invoice_item.description));
                const customColumnWidth = is_representative_view ? `${80 / 7}%` : 'initial';
                const liquid_value = toFixedNumber(invoice.charge - invoice.conditional_discount_charge);
                const wallet_charge_text =
                  invoice.wallet_charge > 0
                    ? liquid_value - toFixedNumber(invoice.wallet_charge) <= 0
                      ? 'CT'
                      : 'CP'
                    : '';
                const wallet_charge_title = `${
                  invoice.wallet_charge > 0
                    ? (liquid_value - toFixedNumber(invoice.wallet_charge) <= 0 ? 'Crédito Total' : 'Crédito Parcial') +
                      `: ${formatToCurrency(invoice.wallet_charge)}`
                    : ''
                }`;
                const charge_value =
                  invoice.status === InvoiceStatusEnum.FINISHED
                    ? invoice.charge +
                      (invoice.accumulated_fines.accumulated_fees || 0) +
                      (invoice.accumulated_fines.accumulated_penalty || 0)
                    : invoice.charge;
                return (
                  <React.Fragment key={invoice.code}>
                    <TableRow className='table-body-row' key={invoice.code}>
                      {!is_representative_view && (
                        <>
                          <TableCell>
                            <Checkbox
                              checked={selectedInvoices?.includes(~~invoice.id)}
                              disabled={cantSelectInvoice}
                              onChange={onCheckInvoice}
                            />
                          </TableCell>
                          {is_invoices_view && is_above_school_director && (
                            <TableCell align='center'>{invoice.company_name}</TableCell>
                          )}
                          <TableCell align='center'>{invoice.code}</TableCell>
                          <TableCell align='center'>{parseText(invoice.representative_name, 5)}</TableCell>
                        </>
                      )}
                      {is_representative_view ||
                        (is_invoices_view && (
                          <>
                            <TableCell
                              style={{ minWidth: is_invoices_view ? 'unset' : '10vw' }}
                              align={is_invoices_view ? 'center' : 'left'}
                            >
                              {invoice.student}
                            </TableCell>
                          </>
                        ))}
                      <TableCell style={{ width: customColumnWidth }} align='center'>
                        <span
                          css={css`
                            color: ${is_invoice_expired ? colors.lightRed : 'inherit'};
                          `}
                        >
                          {statusInfo}
                        </span>
                      </TableCell>
                      {!is_representative_view && (
                        <TableCell align='center'>
                          <span>{chargeStatusInfo}</span>
                        </TableCell>
                      )}
                      <TableCell style={{ width: customColumnWidth }} align='center'>
                        {expirationDate}
                      </TableCell>
                      <TableCell style={{ width: customColumnWidth }} align='center'>
                        {punctualityDate}
                      </TableCell>
                      <TableCell style={{ width: customColumnWidth }} align='center'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '3px' }}>
                          {formatToCurrency(charge_value)}
                          {!is_representative_view && invoice.wallet_charge > 0 && (
                            <Tooltip title={wallet_charge_title}>
                              <div
                                style={{
                                  alignItems: 'center',
                                  backgroundColor: colors.green,
                                  borderRadius: '20px',
                                  color: 'white',
                                  cursor: 'default',
                                  display: 'flex',
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  justifyContent: 'center',
                                  padding: '3px',
                                }}
                              >
                                {wallet_charge_text}
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align='center'>{formatToCurrency(invoice.conditional_discount_charge)}</TableCell>
                      {is_representative_view && (
                        <TableCell style={{ width: customColumnWidth }} align='center'>
                          <Tooltip
                            title={
                              <div style={{ display: 'grid' }}>
                                {invoiceDescription.map((desc) => (
                                  <span style={{ color: 'white' }} key={desc}>
                                    {desc}
                                  </span>
                                ))}
                              </div>
                            }
                          >
                            <Info />
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell style={{ width: customColumnWidth }} align='center'>
                        <InvoiceActions
                          changeInvoiceStepTab={changeInvoiceStepTab}
                          expandedInvoiceDescription={expandedInvoiceDescription}
                          expandedInvoiceRenegotiations={expandedInvoiceRenegotiations}
                          expandedInvoices={expandedInvoices}
                          expandedRenegotiations={expandedRenegotiations}
                          expandedSchedules={expandSchedules}
                          expandedTransactions={expandedTransactions}
                          handleUpdateResources={handleUpdateResources}
                          invoice_description={invoiceDescription}
                          invoice={invoice}
                          is_representative_view={is_representative_view}
                          loadingInvoices={loadingInvoices}
                          resetFilters={resetFilters}
                          setExpandedInvoiceDescription={setExpandedInvoiceDescription}
                          setExpandedInvoiceRenegotiations={setExpandedInvoiceRenegotiations}
                          setExpandedInvoices={setExpandedInvoices}
                          setExpandedRenegotiations={setExpandedRenegotiations}
                          setExpandedSchedules={setExpandedSchedules}
                          setExpandedTransactions={setExpandedTransactions}
                          setLoadingInvoices={setLoadingInvoices}
                          student_name={invoice.student}
                          is_invoices_view={is_invoices_view}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, ...(isLast && { borderBottom: 0 }) }}
                        colSpan={columns.length + 1}
                      >
                        <Collapse
                          in={expandedInvoiceDescription.includes(~~invoice.id)}
                          timeout='auto'
                          unmountOnExit
                          mountOnEnter
                        >
                          <SummaryRow
                            invoice={invoice}
                            loadingInvoices={loadingInvoices}
                            setLoadingInvoices={setLoadingInvoices}
                            load_resources
                            open={expandedInvoiceDescription.includes(~~invoice.id)}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, ...(isLast && { borderBottom: 0 }) }}
                        colSpan={columns.length + 1}
                      >
                        <Collapse
                          in={expandedInvoiceRenegotiations.includes(~~invoice.id)}
                          timeout='auto'
                          unmountOnExit
                          mountOnEnter
                        >
                          <RenegotiationRow
                            handleUpdateResources={handleUpdateResources}
                            load_resources
                            loadingInvoices={loadingInvoices}
                            setLoadingInvoices={setLoadingInvoices}
                            invoice={invoice}
                            open={expandedInvoiceRenegotiations.includes(~~invoice.id)}
                            is_invoice_renegotiation_row
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, ...(isLast && { borderBottom: 0 }) }}
                        colSpan={columns.length + 1}
                      >
                        <Collapse
                          in={expandedRenegotiations.includes(~~invoice.id)}
                          timeout='auto'
                          unmountOnExit
                          mountOnEnter
                        >
                          <RenegotiationRow
                            load_resources
                            loadingInvoices={loadingInvoices}
                            setLoadingInvoices={setLoadingInvoices}
                            invoice={invoice}
                            open={expandedRenegotiations.includes(~~invoice.id)}
                            is_invoice_renegotiation_row={false}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, ...(isLast && { borderBottom: 0 }) }}
                        colSpan={columns.length + 1}
                      >
                        <Collapse in={expandSchedules.includes(~~invoice.id)} timeout='auto' unmountOnExit mountOnEnter>
                          <SchedulesRow
                            loadingInvoices={loadingInvoices}
                            setLoadingInvoices={setLoadingInvoices}
                            load_resources
                            invoice={invoice}
                            open={expandSchedules.includes(~~invoice.id)}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, ...(isLast && { borderBottom: 0 }) }}
                        colSpan={columns.length + 1}
                      >
                        <Collapse
                          in={expandedTransactions.includes(~~invoice.id)}
                          timeout='auto'
                          unmountOnExit
                          mountOnEnter
                        >
                          <TransactionsRow
                            loadingInvoices={loadingInvoices}
                            setLoadingInvoices={setLoadingInvoices}
                            load_resources
                            invoice={invoice}
                            open={expandedTransactions.includes(~~invoice.id)}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, ...(isLast && { borderBottom: 0 }) }}
                        colSpan={columns.length + 1}
                      >
                        <Collapse
                          in={expandedInvoices.includes(~~invoice.id)}
                          timeout='auto'
                          unmountOnExit
                          mountOnEnter
                        >
                          <InvoiceItemsRow
                            load_resources
                            loadingInvoices={loadingInvoices}
                            setLoadingInvoices={setLoadingInvoices}
                            invoice={invoice}
                            open={expandedInvoices.includes(~~invoice.id)}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
            {handlePageChange && pagination && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, 50]}
                    colSpan={columns?.length + 1}
                    count={pagination.totalCount}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.pageNumber}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangePageSize}
                    labelRowsPerPage='Itens Por página'
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default InvoiceTable;
