/* eslint-disable camelcase */
import { first, isEmpty, isFinite, orderBy } from 'lodash'
import { compactSum, convertToDate, cpfValidation, isEmailValid, isIsoDate, removeSpecialSymbols, toFixedNumber, totalInvoiceValues, } from './functions'
import { AxiosResponse } from 'axios'
import { AnyAction } from '@reduxjs/toolkit'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import AssessmentIcon from '@mui/icons-material/Assessment'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SchoolIcon from '@mui/icons-material/School';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { ContractAttrs } from '../store/contracts'
import { RegistrationProductAttrs } from '../store/registration_products'
import { DocumentRequirementAttrs, EmergencyContactsAttrs, RegistrationAttrs, RegistrationProductSubsidyAttrs } from '../store/registrations'
import { RegistrationClosureAttrs } from '../store/registration_closures'
import { SiGoogleclassroom } from "react-icons/si";
import ClipboardIcon from '../components/icon/ClipboardIcon'
import ContractIcon from '../components/icon/ContractIcon'
import { AccountBalanceWallet, AccountTree, CalendarMonth, CollectionsBookmark, ConfirmationNumber, Groups, Receipt } from '@mui/icons-material'
import { SubsidyAttrs } from '../store/subsidies'
import { PaymentCollectionAttrs, PaymentOptionAttrs } from '../store/payment_options'
import { ProductAttrs, ProductPeriodAttrs } from '../store/products'
import { RegistrationConnectionAttrs } from '../store/registration_connections'
import { RegistrationDocumentAttrs } from '../store/registration_documents'
import { RepresentativeAttrs, RepresentativeProductAttrs } from '../store/representatives'
import { ContractSettingSignerAttrs, ContractSettingAttrs } from '../store/contract_settings'
import { DiscountTypesAttrs } from '../store/discount_types'
import { CourseAttrs } from '../store/courses'
import { KtwelveAttrs } from '../store/ktwelves'
import { ClassroomAttrs } from '../store/classrooms'
import { ProductFamilyAttrs } from '../store/product_families'
import { UserAlergyAttrs } from '../store/alergies'
import { UserDeficiencyAtrrs } from '../store/deficiencies'
import { AccountAttrs } from '../store/accounts'
import { ProfileDashboardAttrs } from '../store/profile_dashboards'
import { AddressAttrs, UserAttrs } from '../store/users'
import { InvoiceAttrs } from '../store/invoices'
import { InvoiceItemAttrs } from '../store/invoice_items'
import { BillingAttrs } from '../store/billings'
import { InvoiceRenegotiationAttrs, RenegotiationsAttrs } from '../store/renegotiations'
import { CompanyAttrs } from '../store/companies'
import { ScheduleAttrs } from '../store/schedules'
import { TransactionAttrs } from '../store/transactions'
import { WalletAttrs } from '../store/wallets'
import { WalletCreditAttrs } from '../store/wallet_credits'
import { CoordinatorAttrs } from '../store/coordinators'
import { TeacherAttrs } from '../store/teachers'
import { SubjectAttrs } from '../store/subjects'
import { CurriculumAttrs } from '../store/curriculums'
import { CurriculumSubjectAttrs } from '../store/curriculum_subjects'
import { CompositionAttrs } from '../store/compositions'
import { CompositionPeriodAttrs } from '../store/composition_periods'
import { KtwelveCurriculumAttrs } from '../store/ktwelve_curriculums'
import { KtwelveSubjectAttrs } from '../store/ktwelve_subjects'
import { ExamPlacementAttrs } from '../store/exam_placements'
import { FormulaAttrs } from '../store/formulas'
import { OperationAttrs } from '../store/operations'
import { SubjectPeriodAttrs } from '../store/subject_periods'
import { UserRaceAttrs } from '../store/races'
import { RoomAttrs } from '../store/rooms'
import { RoomStudentAttrs } from '../store/room_students'
import { ClassTimeAttrs } from '../store/class_times'
import { RoomScheduleAttrs } from '../store/room_schedules'
import { ClassTimeSubjectAttrs } from '../store/class_time_subjects'
import { TeacherClassTimeAttrs } from '../store/teacher_class_times'
import { TeacherSubjectAttrs } from '../store/teacher_subjects'
import { RoomClassTimeAttrs } from '../store/room_class_times'
import { CalendarEventAttrs } from '../store/calendar_events'
import { StudentAbsenceAttrs } from '../store/student_absences'
import AcademicIcon from '../components/icon/AcademicIcon'
import AdminIcon from '../components/icon/AdmIcon'
import PedagogicIcon from '../components/icon/PedagogicIcon'
import FinancialIcon from '../components/icon/FinancialIcon'
import SettingIcon from '../components/icon/SettingsIcon'
import { SubjectPeriodRegistrationAttrs } from '../store/subject_period_registrations'
import { ExamAttrs } from '../store/exams'
import { RegistrationResultAttrs } from '../store/registration_results'
import { KtwelveCurriculumRegistrationRegistrationAttrs } from '../store/ktwelve_curriculum_registrations'
import { AuditAttrs } from '../store/audits'
import { RiCalendarScheduleLine } from 'react-icons/ri'

export enum Role {
  FINANCIAL_REPRESENTATIVE = 'financial_representative',
  FUND_ADMIN = 'fund_admin',
  GROUP_ADMIN = 'group_admin',
  HUB_ADMIN = 'hub_admin',
  INVESTOR = 'investor',
  PEDAGOGICAL_FINANCIAL_REPRESENTATIVE = 'pedagogical_financial_representative',
  PEDAGOGICAL_REPRESENTATIVE = 'pedagogical_representative',
  PUBLISHER_ADMIN = 'publisher_admin',
  SCHOOL_ADMIN = 'school_admin',
  SCHOOL_SECRETARY = 'school_secretary',
  STUDENT = 'student',
  SUPERUSER = 'superuser',
  COORDINATOR = 'coordinator',
  TEACHER = 'teacher',
  PEDAGOGICAL_ADMIN = 'pedagogical_admin',
  SCHOOL_MANAGER = 'school_manager'
}

export const role_hierarchy = {
  [Role.SUPERUSER]: 0,
  [Role.FUND_ADMIN]: 1,
  [Role.HUB_ADMIN]: 2,
  [Role.GROUP_ADMIN]: 3,
  [Role.SCHOOL_ADMIN]: 4,
  [Role.PUBLISHER_ADMIN]: 4,
  [Role.SCHOOL_SECRETARY]: 5,
  [Role.COORDINATOR]: 6,
  [Role.TEACHER]: 7,
  [Role.STUDENT]: 8,
  [Role.FINANCIAL_REPRESENTATIVE]: 8,
  [Role.PEDAGOGICAL_REPRESENTATIVE]: 8,
  [Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE]: 8,
  [Role.INVESTOR]: 8
}

export type RoleTypes = `${Role}`

export enum Company {
  FUND = 'fund',
  HUB = 'hub',
  GROUP = 'group',
  SCHOOL = 'school',
  PUBLISHER = 'publisher'
}

export const colors = {
  lightBlue: '#EDF2F7',
  grayBlue: '#C5D4E3',
  darkGrayBlue: '#9DAEBF',
  blue: '#5FBFED',
  cyanBlue: "#3A78BC",
  darkBlue: '#31314F',
  green: '#60DFC8',
  lightGreen: '#9ccf8c',
  lightRed: '#FFA1A1',
  lightPurple: '#9999f7'
}

export interface RepresentativesInvoiceViewGraphData {
  user: string
  product: string
  contract: string
  contract_status: ContractStatusEnum
  contract_id: string
  billings: BillingAttributes[]
  invoice_items: InvoiceItemAttributes[]
  invoices: InvoiceAttributes[]
}

export interface ProductsViewAttributes extends ProductAttributes {
  classroom?: string
  ktwelve?: string
  course?: string
  company?: string
}

export interface RecordDates {
  created_at?: string
  updated_at?: string
}
export interface RegistrationResultAttributes extends RegistrationResultAttrs, AddId {}
export interface ExamAttributes extends ExamAttrs, AddId {}
export interface SubjectPeriodRegistrationAttributes extends SubjectPeriodRegistrationAttrs, AddId {}
export interface PaymentOptionAttributes extends PaymentOptionAttrs, AddId, RecordDates { }
export interface SubsidyAttributes extends SubsidyAttrs, AddId { }
export interface ProductPeriodAttributes extends ProductPeriodAttrs, AddId { }
export interface DocumentRequirementAttributes extends DocumentRequirementAttrs, AddId { }
export interface RoomAttributes extends RoomAttrs, AddId { }
export interface RoomScheduleAttributes extends RoomScheduleAttrs, AddId { }
export interface RoomScheduleFormAttributes extends RoomScheduleAttributes {
  class_times_attributes: NestedClassTimeAttributes[]
}

export interface ClassTimeSubjectFormAttributes extends ClassTimeSubjectAttributes {
  teacher_class_times_attributes: NestedTeacherClassTimeAttributes[]
}


export interface ClassTimeSubjectAttributes extends ClassTimeSubjectAttrs, AddId { }
export interface NestedClassTimeSubjectAttributes extends ClassTimeSubjectFormAttributes, NestedAttributesBase {
  teacher_class_times_attributes: NestedTeacherClassTimeAttributes[]
}
export interface TeacherClassTimeAttributes extends TeacherClassTimeAttrs, AddId { }
export interface NestedTeacherClassTimeAttributes extends TeacherClassTimeAttributes, NestedAttributesBase { }
export interface ClassTimeAttributes extends ClassTimeAttrs, AddId { }
export interface CalendarEventAttributes extends CalendarEventAttrs, AddId {}
export interface RoomClassTimeAttributes extends RoomClassTimeAttrs, AddId {}
export interface NestedRoomClassTimeAttributes extends Partial<RoomClassTimeAttributes>, NestedAttributesBase {}
export interface ClassTimeSubjectDisplayTableAttributes extends ClassTimeSubjectAttributes {
  teacher_class_times_attributes: TeacherClassTimeAttributes[];
}

export interface ClassTimeDisplayTableAttributes extends ClassTimeAttributes {
  class_time_subjects_attributes: ClassTimeSubjectDisplayTableAttributes[];
  room_class_times_attributes: RoomClassTimeAttributes[];
}
export interface ClassTimeFormAttributes extends Partial<ClassTimeAttributes> {
  class_time_subjects_attributes: Partial<NestedClassTimeSubjectAttributes>[]
  room_class_times_attributes: Partial<NestedRoomClassTimeAttributes>[]
}
export interface NestedClassTimeAttributes extends ClassTimeFormAttributes, NestedAttributesBase {
}

export interface RoomStudentAttributes extends RoomStudentAttrs, AddId { }
export interface StudentAbsenceAttributes extends StudentAbsenceAttrs, AddId { }
export interface NestedAttributesBase {
  _destroy?: boolean
}
export interface SubsidiesAttributes extends SubsidyAttrs, AddId { }
export interface NestedSubsidiesAttributes extends SubsidiesAttributes, NestedAttributesBase { }
export interface ProductPeriodsAttributes extends ProductPeriodAttrs, NestedAttributesBase { }
export interface PaymentCollectionAttributes extends PaymentCollectionAttrs, AddId { }
export interface PaymentCollectionNestedAttributes extends PaymentCollectionAttributes, NestedAttributesBase { }
export interface PaymentOptionsAttributes extends Omit<PaymentOptionAttrs, 'product_id'>, Omit<NestedAttributesBase, 'id'>, RecordDates {
  id?: string
  product_id?: number
}
export interface DocumentRequirementsAttributes extends DocumentRequirementAttrs, NestedAttributesBase { }

export interface ContractSettingsAttributes extends ContractSettingAttrs, AddId { }
export interface ContractSettingsViewAttributes extends ContractSettingsAttributes, AddId {
  company: string
}

export interface ContractSettingSignersAttributes extends ContractSettingSignerAttrs, AddId { }
export interface NestedContractSettingSignersAttributes extends ContractSettingSignersAttributes, NestedAttributesBase {
  file?: FormFileType
  name?: string
}

export interface ContractSettingFormAttributes extends ContractSettingsAttributes {
  contract_setting_signers_attributes?: NestedContractSettingSignersAttributes[]
}
export interface DiscountTypesAttributes extends DiscountTypesAttrs, AddId { }
export interface DiscountTypesViewAttributes extends DiscountTypesAttributes {
  company: string
}

export interface SubsidiesNestedAttributes extends SubsidyAttributes, NestedAttributesBase { }
export interface ProductPeriodsNestedAttributes extends ProductPeriodAttributes, NestedAttributesBase { }
export interface PaymentOptionsNestedAttributes extends PaymentOptionAttributes, NestedAttributesBase {
  payment_collections_attributes?: PaymentCollectionNestedAttributes[]
}
export interface DocumentRequirementsNestedAttributes extends DocumentRequirementAttributes, NestedAttributesBase { }
export interface RoomsNestedAttributes extends RoomAttributes, NestedAttributesBase { }
export interface ProductsFormAttributes extends Partial<ProductAttributes> {
  document_requirements_attributes: DocumentRequirementsNestedAttributes[]
  subsidies_attributes: SubsidiesNestedAttributes[]
  periods_attributes: ProductPeriodsNestedAttributes[]
  payment_options_attributes: PaymentOptionsNestedAttributes[]
  rooms_attributes?: RoomsNestedAttributes[]
}

export interface ProfileDashboardAttributes extends ProfileDashboardAttrs, AddId { }
export interface ProfileDashboardsDashboardView extends ProfileDashboardAttributes {
  handleSelect: () => Promise<void>
}

export interface DashboardViewData {
  current: string;
  data: {
    id: string;
    items_per_row: number;
  }[];
}
export interface NestedProfileDashboardAttributes extends ProfileDashboardAttributes, NestedAttributesBase {
  dashboard_logo?: FormFileType
}
export interface ProfileDashboardFormAttributes {
  profile_dashboards_attributes: NestedProfileDashboardAttributes[]
}

export interface CompanyAttributes extends CompanyAttrs, AddId { }

export interface ProductAttributes extends ProductAttrs, AddId { }

export const semiAnualPortionsConfig = [
  {
    portion: 1,
    months: [1, 2, 3, 4, 5, 6]
  },
  {
    portion: 2,
    months: [7, 8, 9, 10, 11, 12]
  }
]




export enum Paths {
  ASAAS_PAYMENT_SETTINGS_FORM = '/asaas_webhook_settings/form',
  CALENDAR_EVENTS = '/calendar_events',
  CLASSROOM_FORM = '/classrooms/form',
  CLASSROOM_LIST = '/classrooms',
  COMPANY_FORM = '/companies/form',
  CONTRACT_SETTINGS_FORM = '/contract_settings/form',
  CONTRACT_SETTINGS_LIST = '/contract_settings',
  CONTRACTS_LIST = '/contracts',
  COORDINATORS_FORM = '/coordinators/form',
  COORDINATORS_LIST = '/coordinators',
  COURSES_FORM = '/courses/form',
  COURSES_LIST = '/courses',
  CURRICULUMS_LIST = '/curriculums',
  CURRICULUMS_FORM = '/curriculums/form',
  CURRICULUMS_COMPOSITIONS_LIST = '/curriculums/form/compositions',
  CURRICULUM_CURRICULUM_KTWELVES = '/curriculums/form/curriculum_ktwelves',
  DASHBOARD = '/dashboard',
  DISCOUNT_TYPES_FORM = '/discount_types/form',
  DISCOUNT_TYPES_LIST = '/discount_types',
  HOME = '/',
  INVOICES = '/invoices',
  KTWELVES_FORM = '/ktwelves/form',
  KTWELVES_LIST = '/ktwelves',
  LOGIN = '/login',
  PRODUCT_FAMILY_FORM = '/product_families/form',
  PRODUCT_FAMILY_LIST = '/product_families',
  PRODUCTS_FORM = '/products/form',
  PRODUCTS_LIST = '/products',
  PROFILE_DASHBOARDS_FORM = '/profile_dashboards/form',
  PROFILE_DASHBOARDS_LIST = '/profile_dashboards',
  REGISTRATION_DETAILS = '/secretary/registrations/details',
  REGISTRATIONS = '/secretary/registrations',
  REGISTRATIONS_FORM = '/secretary/registrations/form',
  ROOMS_LIST = '/rooms',
  REPRESENTATIVE_INVOICES = '/payments',
  SECRETARY = '/secretary',
  SUBJECTS_LIST = '/subjects',
  SUBJECTS_FORM = '/subjects/form',
  TEACHERS_LIST = '/teachers',
  MY_CLASS_TIMES = '/my_class_times',
  TEACHERS_FORM = '/teachers/form',
  USERS_FORM = '/users/form',
  USERS_LIST = '/users',
  WALLETS_LIST = '/wallets'
}

export const defaultCoordinatorFormInitialValues = (company_id: number) => ({
  nationality_country: {
    phonecode: '55',
    flag: '🇧🇷',
    label: 'Brazil',
    value: 'BR',
  },
  accounts_attributes: [
    {
      company_id,
      coordinators_attributes: []
    },
  ],
  company_id
});

export const defaultTeacherFormInitialValues = (company_id: number) => ({
  nationality_country: {
    phonecode: '55',
    flag: '🇧🇷',
    label: 'Brazil',
    value: 'BR',
  },
  accounts_attributes: [
    {
      company_id,
      teachers_attributes: [] as NestedAccountAttributesTeacherForm[]
    },
  ],
  company_id
});

export type UserFormFields =
  'avatar' |
  'name' |
  'additional_data.social_name' |
  'marital_status' |
  'gender' |
  'birthdate' |
  'race' |
  'email' |
  'additional_data.personal_email' |
  'phone' |
  'nationality_country' |
  'nationality_state' |
  'nationality_city' |
  'document_cpf' |
  'document_cnpj' |
  'address_attributes.zipcode' |
  'address_attributes.street' |
  'address_attributes.complement' |
  'address_attributes.neighbourhood' |
  'address_attributes.country_id' |
  'address_attributes.state_id' |
  'address_attributes.city_id' |
  'document_number' |
  'user_alergies' |
  'user_deficiencies'


export interface RoutePermissions {
  path: Paths,
  permission: ProfileTypes[],
  companies: Company[]
}


export const private_routes_permissions: RoutePermissions[] = [
  {
    path: Paths.HOME,
    permission: [Role.SUPERUSER, Role.FUND_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.COMPANY_FORM,
    permission: [Role.SUPERUSER, Role.FUND_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.USERS_LIST,
    permission: [Role.SUPERUSER, Role.FUND_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.USERS_FORM,
    permission: [Role.SUPERUSER, Role.FUND_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.SECRETARY,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.HUB_ADMIN,
      Role.GROUP_ADMIN,
      Role.SCHOOL_ADMIN,
      Role.SCHOOL_SECRETARY,
      Role.PUBLISHER_ADMIN,
      Role.SCHOOL_MANAGER
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.REGISTRATIONS,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.HUB_ADMIN,
      Role.GROUP_ADMIN,
      Role.SCHOOL_ADMIN,
      Role.SCHOOL_SECRETARY,
      Role.PUBLISHER_ADMIN,
      Role.SCHOOL_MANAGER
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.REGISTRATIONS_FORM,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.HUB_ADMIN,
      Role.GROUP_ADMIN,
      Role.SCHOOL_ADMIN,
      Role.SCHOOL_SECRETARY,
      Role.PUBLISHER_ADMIN,
      Role.SCHOOL_MANAGER
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.REGISTRATION_DETAILS,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.HUB_ADMIN,
      Role.GROUP_ADMIN,
      Role.SCHOOL_ADMIN,
      Role.SCHOOL_SECRETARY,
      Role.PUBLISHER_ADMIN,
      Role.SCHOOL_MANAGER
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.CONTRACTS_LIST,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.HUB_ADMIN,
      Role.GROUP_ADMIN,
      Role.SCHOOL_ADMIN,
      Role.SCHOOL_SECRETARY,
      Role.PUBLISHER_ADMIN,
      Role.SCHOOL_MANAGER
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.DASHBOARD,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.INVESTOR,
      Role.SCHOOL_ADMIN,
      Role.HUB_ADMIN,
      Role.GROUP_ADMIN,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL],
  },
  {
    path: Paths.PROFILE_DASHBOARDS_LIST,
    permission: [Role.SUPERUSER, Role.FUND_ADMIN, Role.INVESTOR, Role.SCHOOL_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.PROFILE_DASHBOARDS_FORM,
    permission: [Role.SUPERUSER, Role.FUND_ADMIN, Role.INVESTOR, Role.SCHOOL_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.PRODUCTS_LIST,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.HUB_ADMIN,
      Role.GROUP_ADMIN,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.PRODUCTS_FORM,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.HUB_ADMIN,
      Role.GROUP_ADMIN,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.CONTRACT_SETTINGS_LIST,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.ASAAS_PAYMENT_SETTINGS_FORM,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.CONTRACT_SETTINGS_FORM,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.DISCOUNT_TYPES_LIST,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.DISCOUNT_TYPES_FORM,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.CLASSROOM_LIST,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.CLASSROOM_FORM,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.COURSES_LIST,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.COURSES_FORM,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.KTWELVES_LIST,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.KTWELVES_FORM,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.PRODUCT_FAMILY_LIST,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.PRODUCT_FAMILY_FORM,
    permission: [
      Role.SUPERUSER,
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP]
  },
  {
    path: Paths.REPRESENTATIVE_INVOICES,
    permission: [
      Role.SUPERUSER,
      Role.FINANCIAL_REPRESENTATIVE,
      Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.INVOICES,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.HUB_ADMIN,
      Role.GROUP_ADMIN,
      Role.SCHOOL_ADMIN,
      Role.SCHOOL_SECRETARY,
      Role.PUBLISHER_ADMIN,
      Role.SCHOOL_MANAGER
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.WALLETS_LIST,
    permission: [
      Role.SUPERUSER,
      Role.FUND_ADMIN,
      Role.HUB_ADMIN,
    ],
    companies: [Company.FUND, Company.HUB]
  },
  {
    path: Paths.COORDINATORS_LIST,
    permission: [
      Role.SUPERUSER,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.COORDINATORS_FORM,
    permission: [
      Role.SUPERUSER,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.TEACHERS_LIST,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.TEACHERS_FORM,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.SUBJECTS_LIST,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.SUBJECTS_FORM,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.CURRICULUMS_LIST,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.CURRICULUMS_FORM,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.CURRICULUM_CURRICULUM_KTWELVES,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.CURRICULUMS_COMPOSITIONS_LIST,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.ROOMS_LIST,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN,
      Role.TEACHER
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.CALENDAR_EVENTS,
    permission: [
      Role.SUPERUSER,
      Role.COORDINATOR,
      Role.SCHOOL_MANAGER,
      Role.PEDAGOGICAL_ADMIN
    ],
    companies: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL]
  },
  {
    path: Paths.MY_CLASS_TIMES,
    permission: [
      Role.TEACHER
    ],
    companies: [Company.SCHOOL]
  }
]


export const somaAppUrl: { [key: string]: string } = {
  local: 'http://localhost:8000',
  staging: 'https://stag-app.plataformasoma.net',
  production: 'https://app.plataformasoma.net'
}

export const ssoUrl: { [key: string]: any } = {
  local: 'http://localhost:8001/',
  staging: 'https://staging-fractalid.fractaltecnologia.com.br/',
  production: 'https://fractalid.fractaltecnologia.com.br/'
}

export const fetchRegistrationIncludes = [
  'account.user.address',
  'account.user.user_alergies',
  'account.user.user_deficiencies',
  'account.user.accounts',
  'account.company',
  'invoices.schedules',
  'invoices.transactions',
  'invoices.renegotiation',
  'invoices.wallet_credits',
  'representatives.account.wallet.wallet_credits',
  'representatives.account.wallet.transactions',
  'invoices.invoice_renegotiation.renegotiation',
  'contracts.billings.invoice_items',
  'contracts.representative_product',
  'representatives.representative_products',
  'representatives.account.user.address',
  'products.document_requirements',
  'registration_connections.registration.account.user',
  'registration_documents',
  'registration_products.representative_products',
  'registration_products.registration_product_subsidies.subsidy',
  'registration_products.registration_closure',
  'emergency_contacts',
  'registration_products.payment_option.payment_collections'
].join(',')
export interface DefaultAction<P, A = AnyAction> {
  type: A;
  payload: P;
  meta: any
}

export const defaultPagination = {
  pageCount: 0,
  totalCount: 0,
  pageSize: 10,
  pageNumber: 0
}

export type CompanyTypes = `${Company}`


export interface Companies {
  id: CompanyTypes;
  value: CompanyTypes;
  label: string;
  parentCompanies: CompanyTypes[],
  profileOptions: RoleTypes[]
  canHaveProfileDashboards: RoleTypes[]
}

export interface InactiveRegistrationProductItem {
  id: string;
  registration_id: string;
  product: ProductAttributes
  closure: RegistrationClosureAttributes
}


export const companyTypes: Companies[] = [
  {
    id: Company.FUND,
    value: Company.FUND,
    label: 'Fundo',
    parentCompanies: [],
    profileOptions: [Role.SUPERUSER, Role.FUND_ADMIN, Role.INVESTOR],
    canHaveProfileDashboards: [Role.FUND_ADMIN, Role.INVESTOR]
  },
  {
    id: Company.HUB,
    value: Company.HUB,
    label: 'Hub',
    parentCompanies: [Company.FUND],
    profileOptions: [Role.HUB_ADMIN, Role.INVESTOR],
    canHaveProfileDashboards: [Role.HUB_ADMIN, Role.INVESTOR, Role.PEDAGOGICAL_ADMIN]
  },
  {
    id: Company.GROUP,
    value: Company.GROUP,
    label: 'Grupo',
    parentCompanies: [Company.FUND, Company.HUB],
    profileOptions: [Role.GROUP_ADMIN, Role.INVESTOR],
    canHaveProfileDashboards: [Role.GROUP_ADMIN, Role.INVESTOR, Role.PEDAGOGICAL_ADMIN]
  },
  {
    id: Company.SCHOOL,
    value: Company.SCHOOL,
    label: 'Escola',
    parentCompanies: [Company.FUND, Company.HUB, Company.GROUP],
    profileOptions: [Role.SCHOOL_ADMIN, Role.SCHOOL_SECRETARY, Role.STUDENT, Role.FINANCIAL_REPRESENTATIVE, Role.PEDAGOGICAL_REPRESENTATIVE, Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE, Role.COORDINATOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN],
    canHaveProfileDashboards: [Role.SCHOOL_ADMIN, Role.INVESTOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN]
  },
  {
    id: Company.PUBLISHER,
    value: Company.PUBLISHER,
    label: 'Editora',
    parentCompanies: [Company.FUND, Company.HUB, Company.GROUP],
    profileOptions: [Role.PUBLISHER_ADMIN],
    canHaveProfileDashboards: []
  }
]
export interface CompanyOption {
  value: string | number
  label: string
  kind: CompanyTypes
  child_ids: number[]
}

export type ProfileTypes = `${Role}`

export enum OperationKindEnum {
  ADDITION = 'addition',
  SUBTRACTION = 'subtraction',
  MULTIPLICATION = 'multiplication',
  DIVISION = 'division',
  MAX_VALUE = 'max_value',
  MIN_VALUE = 'min_value',
  OPERABLE_INPUT = 'operable_input',
  NUMBER_INPUT = 'number_input',
  EXAM_PLACEMENT_ORDER = 'exam_placement_order',
  FORMULA_STEP = 'formula_step',
  COMPOSITION_PERIOD_ORDER = 'composition_period_order'
}

export const operationKindOptions = [
  {
    label: 'Adição',
    value: OperationKindEnum.ADDITION
  },
  {
    label: 'Subtração',
    value: OperationKindEnum.SUBTRACTION
  },
  {
    label: 'Multiplicação',
    value: OperationKindEnum.MULTIPLICATION
  },
  {
    label: 'Divisão',
    value: OperationKindEnum.DIVISION
  },
  {
    label: "Maior Valor",
    value: OperationKindEnum.MAX_VALUE
  },
  {
    label: "Menor Valor",
    value: OperationKindEnum.MIN_VALUE
  },
  {
    label: 'Número',
    value: OperationKindEnum.NUMBER_INPUT
  },
  {
    label: 'Avaliação por ordem',
    value: OperationKindEnum.EXAM_PLACEMENT_ORDER
  },
  {
    label: 'Média por ordem',
    value: OperationKindEnum.FORMULA_STEP
  },
  {
    label: 'Média final(período) por ordem',
    value: OperationKindEnum.COMPOSITION_PERIOD_ORDER
  },
  {
    label: 'Avaliação ou Fórmula específica',
    value: OperationKindEnum.OPERABLE_INPUT
  },
]


export enum OperationOperableType {
  EXAM_PLACEMENT = 'ExamPlacement',
  FORMULA = 'Formula',
  COMPOSITION_PERIOD = 'CompositionPeriod'
}

export enum FormulableType {
  COMPOSITION_PERIOD = 'CompositionPeriod',
  SUBJECT_PERIOD = 'SubjectPeriod'
}

export enum ExamPlaceableType {
  COMPOSITION_PERIOD = 'CompositionPeriod',
  SUBJECT_PERIOD = 'SubjectPeriod'
}


export const operationOperableOptions = [
  {
    label: 'Avaliação',
    value: OperationOperableType.EXAM_PLACEMENT
  },
  {
    label: 'Fórmula/Média',
    value: OperationOperableType.FORMULA
  },
  {
    label: 'Fórmula/Média Final (período)',
    value: OperationOperableType.COMPOSITION_PERIOD
  }
]

export enum RoomScheduleWeekDaysEnum {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday'
}

export const roomScheduleWeekDayOptions = [
  {
    label: 'Domingo',
    value: RoomScheduleWeekDaysEnum.SUNDAY
  },
  {
    label: 'Segunda',
    value: RoomScheduleWeekDaysEnum.MONDAY
  },
  {
    label: 'Terça',
    value: RoomScheduleWeekDaysEnum.TUESDAY
  },
  {
    label: 'Quarta',
    value: RoomScheduleWeekDaysEnum.WEDNESDAY
  },
  {
    label: 'Quinta',
    value: RoomScheduleWeekDaysEnum.THURSDAY
  },
  {
    label: 'Sexta',
    value: RoomScheduleWeekDaysEnum.FRIDAY
  },
  {
    label: 'Sábado',
    value: RoomScheduleWeekDaysEnum.SATURDAY
  }
]

export enum CompositionPeriodKindEnum {
  PARTIAL = 'partial',
  FULL = 'full'
}

export enum ClassTimeKindEnum {
  LECTURE = 'lecture',
  EXAM = 'exam',
  INTERVAL = 'interval',
  EVENT = 'event'
}

export const classTimeKindOptions = [
  {
    label: 'Aula',
    value: ClassTimeKindEnum.LECTURE
  },
  {
    label: 'Prova',
    value: ClassTimeKindEnum.EXAM
  },
  {
    label: 'Recreio',
    value: ClassTimeKindEnum.INTERVAL
  },
  {
    label: "Evento",
    value: ClassTimeKindEnum.EVENT
  }
]


export enum ExamKindEnum {
  DEFAULT = 'default',
  REPLACEMENT = 'replacement'
}

export const examKindOptions = [
  {
    label: 'Padrão',
    value: ExamKindEnum.DEFAULT
  },
  {
    label: 'Reposição',
    value: ExamKindEnum.REPLACEMENT
  },
]

export enum ExamIrregularityEnum {
  OTHER = 'other',
  WITH_JUSTIFICATION = 'with_justification',
  WITHOUT_JUSTIFICATION = 'without_justification',
  INSUFFICIENT_KNOWLEDGE = 'insufficient_knowledge',
  CHEATING = 'cheating'
}

export const examIrregularityOptions = [
  {
    lable: 'Outro',
    value: ExamIrregularityEnum.OTHER
  },
  {
    label: 'Com Justificativa',
    value: ExamIrregularityEnum.WITH_JUSTIFICATION
  },
  {
    label: 'Sem Justificativa',
    value: ExamIrregularityEnum.WITHOUT_JUSTIFICATION
  },
  {
    label: 'Conhecimento Insuficiente',
    value: ExamIrregularityEnum.INSUFFICIENT_KNOWLEDGE,
  },
  {
    label: 'Trapaça',
    value: ExamIrregularityEnum.CHEATING
  }
]

export const compositionPeriodKindOptions = [
  {
    label: 'Parcial',
    value: CompositionPeriodKindEnum.PARTIAL
  },
  {
    label: 'Completo',
    value: CompositionPeriodKindEnum.FULL
  }
]

export interface Profiles {
  id: ProfileTypes;
  canCreateProfile: ProfileTypes[];
  canCreateProfileDashboardsFor: ProfileTypes[];
  canCreateProfileInCompany: CompanyTypes[];
  canCreateCompany: CompanyTypes[];
  canJoinCompany: CompanyTypes[],
  label: string,
  name: string,
  default_route?: Paths
}

export enum AsaasPaymentStatusEnum {
  PENDING = 'PENDING',
  RECEIVED = 'RECEIVED',
  CONFIRMED = 'CONFIRMED',
  OVERDUE = 'OVERDUE',
  REFUNDED = 'REFUNDED',
  RECEIVED_IN_CASH = 'RECEIVED_IN_CASH',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS',
  CHARGEBACK_REQUESTED = 'CHARGEBACK_REQUESTED',
  CHARGEBACK_DISPUTE = 'CHARGEBACK_DISPUTE',
  AWAITING_CHARGEBACK_REVERSAL = 'AWAITING_CHARGEBACK_REVERSAL',
  DUNNING_REQUESTED = 'DUNNING_REQUESTED',
  DUNNING_RECEIVED = 'DUNNING_RECEIVED',
  AWAITING_RISK_ANALYSIS = 'AWAITING_RISK_ANALYSIS'
}

export type AsaasPaymentStatusTypes = `${AsaasPaymentStatusEnum}`

export const asaasPaymentStatusOptions = [
  {
    value: AsaasPaymentStatusEnum.PENDING,
    label: 'Aguardando Pagamento'
  },
  {
    value: AsaasPaymentStatusEnum.RECEIVED,
    label: 'Recebida (saldo já creditado na conta)'
  },
  {
    value: AsaasPaymentStatusEnum.CONFIRMED,
    label: 'Pagamento confirmado (saldo ainda não creditado)'
  },
  {
    value: AsaasPaymentStatusEnum.OVERDUE,
    label: 'Vencida'
  },
  {
    value: AsaasPaymentStatusEnum.REFUNDED,
    label: 'Estornada'
  },
  {
    value: AsaasPaymentStatusEnum.RECEIVED_IN_CASH,
    label: 'Recebida em dinheiro (não gera saldo na conta)'
  },
  {
    value: AsaasPaymentStatusEnum.REFUND_REQUESTED,
    label: 'Estorno Solicitado'
  },
  {
    value: AsaasPaymentStatusEnum.REFUND_IN_PROGRESS,
    label: 'Estorno em processamento'
  },
  {
    value: AsaasPaymentStatusEnum.CHARGEBACK_REQUESTED,
    label: 'Recebido chargeback'
  },
  {
    value: AsaasPaymentStatusEnum.CHARGEBACK_DISPUTE,
    label: 'Em disputa de chargeback'
  },
  {
    value: AsaasPaymentStatusEnum.AWAITING_CHARGEBACK_REVERSAL,
    label: 'Disputa vencida, aguardando repasse da adquirente'
  },
  {
    value: AsaasPaymentStatusEnum.DUNNING_REQUESTED,
    label: 'Em processo de negativação'
  },
  {
    value: AsaasPaymentStatusEnum.AWAITING_RISK_ANALYSIS,
    label: 'Pagamento em análise'
  }
]

export enum KtwelveSubjectKindEnum {
  MANDATORY = 'mandatory',
  ELECTIVE = 'elective',
  OPTIONAL = 'optional'
}

export const ktwelveSubjectKindOptions = [
  {
    label: 'Obrigatória',
    value: KtwelveSubjectKindEnum.MANDATORY
  },
  {
    label: 'Eletiva',
    value: KtwelveSubjectKindEnum.ELECTIVE
  },
  {
    label: 'Opcional',
    value: KtwelveSubjectKindEnum.OPTIONAL
  }
]

export enum KtwelveSubjectEvaluationKindEnum {
  GRADE = 'grade',
  CONCEPT = 'concept',
}

export const ktwelveSubjectEvaluationKindOptions = [
  {
    label: 'Nota',
    value: KtwelveSubjectEvaluationKindEnum.GRADE
  },
  {
    label: 'Conceito',
    value: KtwelveSubjectEvaluationKindEnum.CONCEPT
  },
]

export enum SubjectPeriodRegistrationStatusEnum {
  ENROLLED = 'enrolled',
  RETAINED = 'retained',
  APPROVED = 'approved',
  COUNCIL_APPROVED = 'council_approved',
  TRANSFERRED = 'transferred',
  FINISHED = 'finished'
}

export const subjectPeriodRegistrationOptions = [
  {
    label: "Matriculado",
    value: SubjectPeriodRegistrationStatusEnum.ENROLLED
  },
  {
    label: "Retido",
    value: SubjectPeriodRegistrationStatusEnum.RETAINED
  },
  {
    label: "Aprovado",
    value: SubjectPeriodRegistrationStatusEnum.APPROVED
  },
  {
    label: "Aprovado CC",
    value: SubjectPeriodRegistrationStatusEnum.COUNCIL_APPROVED
  },
  {
    label: "Transferido",
    value: SubjectPeriodRegistrationStatusEnum.TRANSFERRED
  },
  {
    label: "Concluído",
    value: SubjectPeriodRegistrationStatusEnum.FINISHED
  }
]

export enum MenuCategoriesEnum {
  ACADEMIC = "academic",
  ADMINISTRATIVE = "administrative",
  PEDAGOGICAL = "pedagogical",
  FINANCIAL = "financial",
  SETTINGS = "settings"
}

export const MenuCategoriesOptions = [
  {
    value: MenuCategoriesEnum.ACADEMIC,
    label: "Acadêmico",
    icon: AcademicIcon
  },
  {
    value: MenuCategoriesEnum.ADMINISTRATIVE,
    label: "Administrativo",
    icon: AdminIcon
  },
  {
    value: MenuCategoriesEnum.PEDAGOGICAL,
    label: "Pedagógico",
    icon: PedagogicIcon
  },
  {
    value: MenuCategoriesEnum.FINANCIAL,
    label: "Financeiro",
    icon: FinancialIcon
  },
  {
    value: MenuCategoriesEnum.SETTINGS,
    label: "Configurações",
    icon: SettingIcon
  }
]
export interface MenuOptionsType {
  key: string
  label: string
  icon: React.ElementType
  path: `${Paths}`
  profiles?: RoleTypes[]
  companies?: Company[]
  children?: MenuOptionsType[]
  category: MenuCategoriesEnum
}
export const menuOptions: MenuOptionsType[] = orderBy([
  {
    key: 'home',
    label: 'Unidades',
    icon: HomeIcon,
    path: Paths.HOME,
    category: MenuCategoriesEnum.SETTINGS
  },
  {
    key: 'users',
    label: 'Gerenciar Usuários',
    icon: PersonIcon,
    path: Paths.USERS_LIST,
    category: MenuCategoriesEnum.SETTINGS
  },
  {
    key: 'secretary',
    label: 'Alunos',
    icon: ClipboardIcon,
    path: Paths.SECRETARY,
    category: MenuCategoriesEnum.ACADEMIC
  },
  {
    key: 'contracts',
    label: 'Contratos',
    icon: ContractIcon,
    path: Paths.CONTRACTS_LIST,
    category: MenuCategoriesEnum.ADMINISTRATIVE
  },
  {
    key: 'products',
    label: 'Produtos',
    icon: ConfirmationNumber,
    path: Paths.PRODUCTS_LIST,
    category: MenuCategoriesEnum.SETTINGS
  },
  {
    key: 'dashboard',
    label: 'Dashboards',
    icon: AssessmentIcon,
    path: Paths.DASHBOARD,
    category: MenuCategoriesEnum.ADMINISTRATIVE
  },
  {
    key: 'contract_settings',
    label: 'Contratos',
    icon: SettingsApplicationsIcon,
    path: Paths.CONTRACT_SETTINGS_LIST,
    category: MenuCategoriesEnum.SETTINGS
  },
  {
    key: 'discount_types',
    label: 'Tipos descontos',
    icon: LocalOfferIcon,
    path: Paths.DISCOUNT_TYPES_LIST,
    category: MenuCategoriesEnum.FINANCIAL
  },
  {
    key: 'classrooms',
    label: 'Turmas',
    icon: SchoolIcon,
    path: Paths.CLASSROOM_LIST,
    category: MenuCategoriesEnum.SETTINGS
  },
  {
    key: 'courses',
    label: 'Cursos',
    icon: SchoolIcon,
    path: Paths.COURSES_LIST,
    category: MenuCategoriesEnum.SETTINGS
  },
  {
    key: 'ktwelves',
    label: 'Séries',
    icon: SchoolIcon,
    path: Paths.KTWELVES_LIST,
    category: MenuCategoriesEnum.SETTINGS
  },
  {
    key: 'product_families',
    label: 'Familia de produtos',
    icon: MenuBookIcon,
    path: Paths.PRODUCT_FAMILY_LIST,
    category: MenuCategoriesEnum.SETTINGS
  },
  {
    key: 'invoices',
    label: 'Cobranças',
    icon: Receipt,
    path: Paths.INVOICES,
    category: MenuCategoriesEnum.FINANCIAL
  },
  {
    key: 'wallets',
    label: 'Carteiras',
    icon: AccountBalanceWallet,
    path: Paths.WALLETS_LIST,
    category: MenuCategoriesEnum.FINANCIAL
  },
  {
    key: 'coordinators',
    label: "Coordenadores",
    icon: Groups,
    path: Paths.COORDINATORS_LIST,
    category: MenuCategoriesEnum.ACADEMIC
  },
  {
    key: 'teachers',
    label: "Professores",
    icon: Groups,
    path: Paths.TEACHERS_LIST,
    category: MenuCategoriesEnum.ACADEMIC
  },
  {
    key: 'subjects',
    label: "Disciplinas",
    icon: CollectionsBookmark,
    path: Paths.SUBJECTS_LIST,
    category: MenuCategoriesEnum.PEDAGOGICAL
  },
  {
    key: 'curriculums',
    label: 'Matrizes curriculares',
    icon: AccountTree,
    path: Paths.CURRICULUMS_LIST,
    category: MenuCategoriesEnum.PEDAGOGICAL
  },
  {
    key: 'rooms',
    label: 'Salas',
    icon: SiGoogleclassroom,
    path: Paths.ROOMS_LIST,
    category: MenuCategoriesEnum.ACADEMIC
  },
  {
    key: 'calendar_events',
    label: 'Calendário',
    icon: CalendarMonth,
    path: Paths.CALENDAR_EVENTS,
    category: MenuCategoriesEnum.SETTINGS
  },
  {
    key: 'my_class_times',
    label: 'Meus Horários',
    icon: RiCalendarScheduleLine,
    path: Paths.MY_CLASS_TIMES,
    category: MenuCategoriesEnum.ACADEMIC
  }
], 'label').map(item => {
  const private_route = private_routes_permissions.find(route => item.path === route.path)
  return ({
    ...item,
    profiles: private_route?.permission || [],
    companies: private_route?.companies || []
  })
})

export interface DashboardOption {
  icon?: (props: any) => JSX.Element
  id?: string
  url: string
}


export const profiles: Profiles[] = [
  {
    id: Role.SUPERUSER,
    canCreateProfile: [Role.SUPERUSER, Role.FUND_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN, Role.SCHOOL_ADMIN, Role.SCHOOL_SECRETARY, Role.PUBLISHER_ADMIN, Role.PEDAGOGICAL_REPRESENTATIVE, Role.FINANCIAL_REPRESENTATIVE, Role.INVESTOR, Role.STUDENT, Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE, Role.COORDINATOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN, Role.TEACHER],
    canCreateProfileInCompany: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL, Company.PUBLISHER],
    canCreateCompany: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL, Company.PUBLISHER],
    canCreateProfileDashboardsFor: [Role.SUPERUSER, Role.FUND_ADMIN, Role.HUB_ADMIN, Role.GROUP_ADMIN, Role.SCHOOL_ADMIN, Role.INVESTOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN],
    canJoinCompany: [],
    label: 'Superusuário',
    name: Role.SUPERUSER,
    default_route: Paths.HOME,
  },
  {
    id: Role.FUND_ADMIN,
    canCreateProfile: [Role.HUB_ADMIN, Role.GROUP_ADMIN, Role.SCHOOL_ADMIN, Role.SCHOOL_SECRETARY, Role.PUBLISHER_ADMIN, Role.PEDAGOGICAL_REPRESENTATIVE, Role.FINANCIAL_REPRESENTATIVE, Role.INVESTOR, Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE, Role.COORDINATOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN, Role.TEACHER],
    canCreateProfileInCompany: [Company.FUND, Company.HUB, Company.GROUP, Company.SCHOOL, Company.PUBLISHER],
    canCreateCompany: [Company.HUB, Company.GROUP, Company.SCHOOL, Company.PUBLISHER],
    canCreateProfileDashboardsFor: [Role.HUB_ADMIN, Role.GROUP_ADMIN, Role.SCHOOL_ADMIN, Role.INVESTOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN],
    canJoinCompany: [Company.FUND],
    label: 'Administrativo do fundo',
    name: Role.FUND_ADMIN,
    default_route: Paths.HOME,
  },
  {
    id: Role.HUB_ADMIN,
    canCreateProfile: [Role.GROUP_ADMIN, Role.SCHOOL_ADMIN, Role.SCHOOL_SECRETARY, Role.PUBLISHER_ADMIN, Role.PEDAGOGICAL_REPRESENTATIVE, Role.INVESTOR, Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE, Role.COORDINATOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN, Role.TEACHER],
    canCreateProfileInCompany: [Company.HUB, Company.GROUP, Company.SCHOOL, Company.PUBLISHER],
    canCreateCompany: [Company.GROUP, Company.SCHOOL, Company.PUBLISHER],
    canCreateProfileDashboardsFor: [Role.GROUP_ADMIN, Role.SCHOOL_ADMIN, Role.INVESTOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN],
    canJoinCompany: [Company.HUB],
    label: 'Administrativo do hub',
    name: Role.HUB_ADMIN,
    default_route: Paths.HOME,
  },
  {
    id: Role.GROUP_ADMIN,
    canCreateProfile: [Role.SCHOOL_ADMIN, Role.SCHOOL_SECRETARY, Role.PUBLISHER_ADMIN, Role.PEDAGOGICAL_REPRESENTATIVE, Role.INVESTOR, Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE, Role.COORDINATOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN, Role.TEACHER],
    canCreateProfileInCompany: [Company.GROUP, Company.SCHOOL, Company.PUBLISHER],
    canCreateCompany: [Company.SCHOOL, Company.PUBLISHER],
    canCreateProfileDashboardsFor: [Role.SCHOOL_ADMIN, Role.INVESTOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN],
    canJoinCompany: [Company.GROUP],
    label: 'Administrativo do grupo',
    name: Role.GROUP_ADMIN,
    default_route: Paths.HOME,
  },
  {
    id: Role.SCHOOL_MANAGER,
    canCreateProfile: [Role.SCHOOL_SECRETARY, Role.PEDAGOGICAL_REPRESENTATIVE, Role.SCHOOL_ADMIN, Role.STUDENT, Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE, Role.COORDINATOR, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN],
    canCreateProfileInCompany: [Company.SCHOOL],
    canCreateCompany: [],
    canCreateProfileDashboardsFor: [Role.SCHOOL_ADMIN, Role.PEDAGOGICAL_ADMIN],
    canJoinCompany: [Company.SCHOOL],
    label: 'Diretor da escola',
    name: Role.SCHOOL_MANAGER,
    default_route: Paths.SECRETARY,
  },
  {
    id: Role.SCHOOL_ADMIN,
    canCreateProfile: [Role.SCHOOL_SECRETARY, Role.STUDENT, Role.COORDINATOR, Role.PEDAGOGICAL_ADMIN, Role.TEACHER],
    canCreateProfileInCompany: [Company.SCHOOL],
    canCreateCompany: [],
    canCreateProfileDashboardsFor: [],
    canJoinCompany: [Company.SCHOOL],
    label: 'Administrativo da escola',
    name: Role.SCHOOL_ADMIN,
    default_route: Paths.SECRETARY,
  },
  {
    id: Role.PEDAGOGICAL_ADMIN,
    canCreateProfile: [Role.SCHOOL_SECRETARY, Role.STUDENT, Role.COORDINATOR, Role.PEDAGOGICAL_ADMIN, Role.TEACHER],
    canCreateProfileInCompany: [Company.SCHOOL],
    canCreateCompany: [],
    canCreateProfileDashboardsFor: [],
    canJoinCompany: [Company.SCHOOL, Company.HUB, Company.GROUP, Company.FUND],
    label: 'Gestão Pedagógica',
    name: Role.PEDAGOGICAL_ADMIN,
    default_route: Paths.ROOMS_LIST,
  },
  {
    id: Role.SCHOOL_SECRETARY,
    canCreateProfile: [Role.STUDENT, Role.SCHOOL_SECRETARY, Role.PEDAGOGICAL_REPRESENTATIVE, Role.FINANCIAL_REPRESENTATIVE],
    canCreateProfileInCompany: [Company.SCHOOL],
    canCreateCompany: [],
    canCreateProfileDashboardsFor: [],
    canJoinCompany: [Company.SCHOOL],
    label: 'Secretário(a) da escola',
    name: Role.SCHOOL_SECRETARY,
    default_route: Paths.SECRETARY,
  },
  {
    id: Role.COORDINATOR,
    canCreateProfile: [],
    canCreateProfileInCompany: [],
    canCreateCompany: [],
    canCreateProfileDashboardsFor: [],
    canJoinCompany: [Company.SCHOOL],
    label: 'Coordenador(a) da escola',
    name: Role.COORDINATOR,
    default_route: Paths.ROOMS_LIST,
  },
  {
    id: Role.TEACHER,
    canCreateProfile: [],
    canCreateProfileInCompany: [],
    canCreateCompany: [],
    canCreateProfileDashboardsFor: [],
    canJoinCompany: [Company.SCHOOL],
    label: 'Professor',
    name: Role.TEACHER,
    default_route: Paths.ROOMS_LIST,
  },
  {
    id: Role.PUBLISHER_ADMIN,
    canCreateProfile: [Role.SCHOOL_SECRETARY, Role.PEDAGOGICAL_REPRESENTATIVE, Role.FINANCIAL_REPRESENTATIVE, Role.STUDENT],
    canCreateProfileInCompany: [Company.PUBLISHER],
    canCreateCompany: [],
    canCreateProfileDashboardsFor: [],
    canJoinCompany: ['publisher'],
    label: 'Admin da editora',
    name: 'publisher_admin',
  },
  {
    id: Role.PEDAGOGICAL_REPRESENTATIVE,
    canCreateProfileInCompany: [],
    canCreateProfile: [],
    canCreateCompany: [],
    canCreateProfileDashboardsFor: [],
    canJoinCompany: [Company.SCHOOL, Company.PUBLISHER],
    label: 'Reponsável Pedagógico',
    name: Role.PEDAGOGICAL_REPRESENTATIVE,
  },
  {
    id: Role.FINANCIAL_REPRESENTATIVE,
    canCreateProfileInCompany: [],
    canCreateProfile: [],
    canCreateProfileDashboardsFor: [],
    canCreateCompany: [],
    canJoinCompany: [Company.PUBLISHER, Company.SCHOOL],
    label: 'Responsável Financeiro',
    name: Role.FINANCIAL_REPRESENTATIVE,
  },
  {
    id: Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE,
    canCreateProfileInCompany: [],
    canCreateProfile: [],
    canCreateProfileDashboardsFor: [],
    canCreateCompany: [],
    canJoinCompany: [Company.PUBLISHER, Company.SCHOOL],
    label: 'Responsável Pedagógico Financeiro',
    name: Role.PEDAGOGICAL_FINANCIAL_REPRESENTATIVE,
  },
  {
    id: Role.STUDENT,
    canCreateProfileInCompany: [],
    canCreateProfile: [],
    canCreateCompany: [],
    canCreateProfileDashboardsFor: [],
    canJoinCompany: [Company.SCHOOL],
    label: 'Aluno',
    name: Role.STUDENT,
  },
  {
    id: Role.INVESTOR,
    canCreateProfileInCompany: [],
    canCreateProfile: [],
    canCreateProfileDashboardsFor: [],
    canCreateCompany: [],
    canJoinCompany: [Company.FUND],
    label: 'Investidor',
    name: Role.INVESTOR,
  }
]

export type PaginationType = {
  pageCount: number;
  totalCount: number;
  pageSize: number;
  pageNumber: number;
}

export const isAdmin = (role: any) => {
  return [
    Role.FUND_ADMIN,
    Role.GROUP_ADMIN,
    Role.HUB_ADMIN,
    Role.SCHOOL_ADMIN,
    Role.SUPERUSER
  ].includes(role)
}


export const genderOptions = [
  {
    label: 'Masculino',
    value: 'male'
  },
  {
    label: 'Feminino',
    value: 'female'
  },
  {
    label: 'Outro',
    value: 'other'
  }
]

export const civilStateOptions = [
  {
    label: 'Solteiro',
    value: 'single'
  },
  {
    label: 'Casado',
    value: 'married'
  },
  {
    label: 'Divorciado',
    value: 'divorced'
  }
]

export const userConnectionTypes = [
  {
    label: 'Irmão',
    value: 'sibling'
  },
  {
    label: 'Primo',
    value: 'cousin'
  }
]

export enum PendingSignatureStatusEnum {
  PENDING = 'pending',
  SIGNED = 'signed',
}

export type PendingSignaturesStatusTypes = `${PendingSignatureStatusEnum}`

export const pendingSignatureStatusOptions = [
  {
    value: PendingSignatureStatusEnum.PENDING,
    label: 'Pendente'
  },
  {
    value: PendingSignatureStatusEnum.SIGNED,
    label: 'Assinado'
  }
]

export enum ContractSettingServiceEnum {
  IGREE = 'igree',
  ZAP_SIGN = 'zap_sign'
}

export type ContractSettingServiceTypes = `${ContractSettingServiceEnum}`

export const contractSettingServiceOptions = [
  {
    value: ContractSettingServiceEnum.IGREE,
    label: 'Igree'
  },
  {
    value: ContractSettingServiceEnum.ZAP_SIGN,
    label: 'Zap Sign'
  }
]

export enum DiscountTypeEnum {
  PERCENTAGE = 'percentage',
  FULL_VALUE = 'full_value',
  NONE = 'none'
}

export enum PaymentCollectionKindEnum {
  ADVANCE = 'advance',
  PORTION = 'portion'
}

export const paymentCollectionKindOptions = [
  {
    value: PaymentCollectionKindEnum.ADVANCE,
    label: 'Entrada'
  },
  {
    value: PaymentCollectionKindEnum.PORTION,
    label: 'Parcela'
  }
]

export type PaymentCollectionKindTypes = `${PaymentCollectionKindEnum}`

export enum PaymentOptionDateKindEnum {
  FIXED_DATE = 'fixed_date',
  DAYS_AFTER_BILLING = 'days_after_billing'
}

export const paymentOptionDateKindOptions = [
  {
    value: PaymentOptionDateKindEnum.FIXED_DATE,
    label: 'Data Fixa'
  },
  {
    value: PaymentOptionDateKindEnum.DAYS_AFTER_BILLING,
    label: 'Dias após geração da fatura'
  }
]

export type PaymentOptionDateKindTypes = `${PaymentOptionDateKindEnum}`

export type DiscountTypes = `${DiscountTypeEnum}`

export const discountKindOptions = [
  {
    value: DiscountTypeEnum.PERCENTAGE,
    label: 'Porcentagem'
  },
  {
    value: DiscountTypeEnum.FULL_VALUE,
    label: 'Fixo'
  },
  {
    value: DiscountTypeEnum.NONE,
    label: 'Nenhum'
  }
]

export type FullRepresentativeName = 'financial_representatives' | 'pedagogical_representatives' | 'pedagogical_financial_representatives'

export enum RepresentativeEnum {
  FINANCIAL = 'financial',
  PEDAGOGICAL = 'pedagogical',
  PEDAGOGICAL_FINANCIAL = 'pedagogical_financial'
}

export type RepresentativeTypes = `${RepresentativeEnum}`

export const representativeTypes = [
  {
    label: 'Financeiro',
    value: RepresentativeEnum.FINANCIAL
  },
  {
    label: 'Pedagógico',
    value: RepresentativeEnum.PEDAGOGICAL
  },
  {
    label: 'Pedagógico Financeiro',
    value: RepresentativeEnum.PEDAGOGICAL_FINANCIAL
  }
]

export enum RegistrationStatusEnum {
  PENDING = 'pending',
  DRAFT = 'draft',
  DONE = 'done'
}

export type RegistrationStatusTypes = `${RegistrationStatusEnum}`

export enum RegistrationClosureStatusEnum {
  ABANDONED = 'abandoned',
  TRANSFERED = 'transfered',
  RELOCATED = 'relocated'
}

export type RegistrationClosureStatusTypes = `${RegistrationClosureStatusEnum}`

export const registrationStatusOptions = [
  {
    label: 'Pendente',
    value: RegistrationStatusEnum.PENDING
  },
  {
    label: 'Rascunho',
    value: RegistrationStatusEnum.DRAFT
  },
  {
    label: 'Concluído',
    value: RegistrationStatusEnum.DONE
  }
]

export const registrationClosureStatusOptions = [
  {
    label: 'Desistência',
    value: RegistrationClosureStatusEnum.ABANDONED
  },
  {
    label: 'Transferência',
    value: RegistrationClosureStatusEnum.TRANSFERED
  },
  {
    label: 'Remanejamento',
    value: RegistrationClosureStatusEnum.RELOCATED
  }
]

export enum ConnectionEnum {
  GRANDPARENT = 'grandparent',
  PARENT = 'parent',
  SIBLING = 'sibling',
  COMPANY = 'company',
  OTHER = 'other',
  SELF = 'self'
}

export type ConnectionTypes = `${ConnectionEnum}`

export enum TransactionEventEnum {
  RENEGOTIATED = 'renegotiated',
  CHARGE_CREATED = 'charge_created',
  CHARGE_SENT = 'charge_sent',
  CHARGE_CANCELLED = 'charge_cancelled',
  INVOICE_CANCELLED = 'invoice_cancelled',
  CHARGE_PAID = 'charge_paid',
  INVOICE_CREATED = 'invoice_created',
  INVOICE_REFRESHED = 'invoice_refreshed',
  REFUNDED_TO_WALLET = 'refunded_to_wallet',
  CREDIT_CONCEDED = 'credit_conceded',
  CREDIT_RETURNED = 'credit_returned',
  WEBHOOK_EVENT = 'webhook_event',
  DUPLICATED_RECEIVAL = 'duplicated_receival',
  IMPROPER_RECEIVAL = 'improper_receival',
  CHARGE_EMAIL_VIEWED = 'charge_email_viewed',
  USER_EVENT = 'user_event'
}

export type TransactionEventTypes = `${TransactionEventEnum}`

export const TransactionEventOptions = [
  {
    label: "Fatura renegociada",
    value: TransactionEventEnum.RENEGOTIATED
  },
  {
    label: "Cobrança criada no serviço",
    value: TransactionEventEnum.CHARGE_CREATED
  },
  {
    label: "Cobrança enviada",
    value: TransactionEventEnum.CHARGE_SENT
  },
  {
    label: "Cobrança cancelada no serviço",
    value: TransactionEventEnum.CHARGE_CANCELLED
  },
  {
    label: "Fatura cancelada",
    value: TransactionEventEnum.INVOICE_CANCELLED
  },
  {
    label: "Cobrança paga",
    value: TransactionEventEnum.CHARGE_PAID
  },
  {
    label: "Fatura criada",
    value: TransactionEventEnum.INVOICE_CREATED
  },
  {
    label: "Fatura atualizada",
    value: TransactionEventEnum.INVOICE_REFRESHED
  },
  {
    label: "Fatura estornada para carteira",
    value: TransactionEventEnum.REFUNDED_TO_WALLET
  },
  {
    label: "Crédito concedido",
    value: TransactionEventEnum.CREDIT_CONCEDED
  },
  {
    label: "Crédito retornado",
    value: TransactionEventEnum.CREDIT_RETURNED
  },
  {
    label: "Evento webhook",
    value: TransactionEventEnum.WEBHOOK_EVENT
  },
  {
    label: "Recebimento Duplicado",
    value: TransactionEventEnum.DUPLICATED_RECEIVAL
  },
  {
    label: "Evento webhook",
    value: TransactionEventEnum.IMPROPER_RECEIVAL
  },
  {
    label: "Email visualizado",
    value: TransactionEventEnum.CHARGE_EMAIL_VIEWED
  },
  {
    label: "Evento de usuário",
    value: TransactionEventEnum.USER_EVENT
  }
]

export enum ScheduleStatusEnum {
  PENDING = 'pending',
  SUCCESSFUL = 'successful',
  FAILURE = 'failure',
  ABORTED = 'aborted'
}

export type ScheduleStatusTypes = `${ScheduleStatusEnum}`

export const scheduleStatusOptions = [
  {
    label: "Pendente",
    value: ScheduleStatusEnum.PENDING
  },
  {
    label: "Concluída",
    value: ScheduleStatusEnum.SUCCESSFUL
  },
  {
    label: "Falha",
    value: ScheduleStatusEnum.FAILURE
  },
  {
    label: "Cancelado",
    value: ScheduleStatusEnum.ABORTED
  }
]

export enum ScheduleKindEnum {
  CHARGE = 'charge',
  PRE_PUNCTUALITY_NOTIFICATION = 'pre_punctuality_notification',
  PUNCTUALITY_NOTIFICATION = 'punctuality_notification',
  PRE_EXPIRATION_NOTIFICATION = 'pre_expiration_notification',
  EXPIRATION_NOTIFICATION = 'expiration_notification',
  POST_EXPIRATION_NOTIFICATION = 'post_expiration_notification',
  FINISH_INVOICE_WITH_NO_CHARGE = 'finish_invoice_with_no_charge'
}

export type ScheduleKindTypes = `${ScheduleKindEnum}`

export const scheduleKindOptions = [
  {
    label: "Cobrança",
    value: ScheduleKindEnum.CHARGE
  },
  {
    label: 'Notificação pré-pontualidade',
    value: ScheduleKindEnum.PRE_PUNCTUALITY_NOTIFICATION
  },
  {
    label: 'Notificação pontualidade',
    value: ScheduleKindEnum.PUNCTUALITY_NOTIFICATION
  },
  {
    label: 'Notificação pré-vencimento',
    value: ScheduleKindEnum.PRE_EXPIRATION_NOTIFICATION
  },
  {
    label: 'Notificação Vencimento',
    value: ScheduleKindEnum.EXPIRATION_NOTIFICATION
  },
  {
    label: 'Notificação pós-vencimento',
    value: ScheduleKindEnum.POST_EXPIRATION_NOTIFICATION
  },
  {
    label: 'Finalizar fatura sem cobrança',
    value: ScheduleKindEnum.FINISH_INVOICE_WITH_NO_CHARGE
  }
]

export enum InvoiceStatusEnum {
  TO_PAYMENT_SERVICE = "to_payment_service",
  ACTIVE = "active",
  CANCELED = "canceled",
  FINISHED = "finished",
  RENEGOTIATED = "renegotiated"
}

export type InvoiceStatusTypes = `${InvoiceStatusEnum}`

export const invoiceStatusOptions = [
  {
    label: "Envio pendente",
    value: InvoiceStatusEnum.TO_PAYMENT_SERVICE
  },
  {
    label: "Ativa",
    value: InvoiceStatusEnum.ACTIVE
  },
  {
    label: "Concluída",
    value: InvoiceStatusEnum.FINISHED
  },
  {
    label: "Renegociado",
    value: InvoiceStatusEnum.RENEGOTIATED
  },
  {
    label: "Cancelada",
    value: InvoiceStatusEnum.CANCELED
  }
]

export enum InvoiceChargeStatusEnum {
  AWAITING_CHARGEBACK_REVERSAL = "awaiting_chargeback_reversal",
  CHARGEBACK_REQUESTED = "chargeback_requested",
  REFUND_IN_PROGRESS = 'refund_in_progress',
  CONFIRMED = "confirmed",
  CHARGEBACK_DISPUTE = "chargeback_dispute",
  EXPIRED = "expired",
  LOSS = "loss",
  DUNNING_RECEIVED = "dunning_received",
  DUNNING_REQUESTED = "dunning_requested",
  NOT_CHARGEABLE = "not_chargeable",
  PARTIALLY_REFUNDED = "partially_refunded",
  PENDING_PAYMENT = "pending_payment",
  RECEIVED = "received",
  RECEIVED_IN_CASH = "received_in_cash",
  REFUNDED = "refunded",
  REFUND_REQUESTED = "refund_requested"
}


export type RepresentativeInvoicePageData = {
  accounts: AccountsAttributes[];
  billings: BillingAttributes[];
  contracts: ContractAttributes[];
  invoice_items: InvoiceItemAttributes[];
  invoices: InvoiceAttributes[];
  products: ProductAttributes[]
  registrations: RegistrationAttributes[]
  renegotiations: RenegotiationAttributes[]
  invoice_renegotiations: InvoiceRenegotiationAttributes[]
  representatives: RepresentativeAttributes[];
  users: UserAttributes[];
  wallet_credits: WalletCreditAttributes[]
  wallets: WalletAttributes[]
};


export type InvoiceChargeStatusTypes = `${InvoiceChargeStatusEnum}`

export const invoiceChargeStatusOptions = [
  {
    label: "Cobrança desabilitada",
    value: InvoiceChargeStatusEnum.NOT_CHARGEABLE
  },
  {
    label: 'Recebida',
    value: InvoiceChargeStatusEnum.RECEIVED
  },
  {
    label: "Aguardando pagamento",
    value: InvoiceChargeStatusEnum.PENDING_PAYMENT
  },
  {
    label: "Vencida",
    value: InvoiceChargeStatusEnum.EXPIRED
  },
  {
    label: "Recebido em Dinheiro",
    value: InvoiceChargeStatusEnum.RECEIVED_IN_CASH
  },
  {
    label: "Estornada",
    value: InvoiceChargeStatusEnum.REFUNDED
  },
  {
    label: "Estornada parcialmente",
    value: InvoiceChargeStatusEnum.PARTIALLY_REFUNDED
  },
  {
    label: "Confirmada",
    value: InvoiceChargeStatusEnum.CONFIRMED
  },
  {
    label: "Em processo de estorno",
    value: InvoiceChargeStatusEnum.REFUND_IN_PROGRESS,
  },
  {
    label: "Chargeback",
    value: InvoiceChargeStatusEnum.CHARGEBACK_REQUESTED
  },
  {
    label: "Contestação de Chargeback",
    value: InvoiceChargeStatusEnum.CHARGEBACK_DISPUTE
  },
  {
    label: "Aguardando cancelamento de chargeback",
    value: InvoiceChargeStatusEnum.AWAITING_CHARGEBACK_REVERSAL
  },
  {
    label: "Negativação",
    value: InvoiceChargeStatusEnum.DUNNING_RECEIVED
  },
  {
    label: "Em processo de negativação",
    value: InvoiceChargeStatusEnum.DUNNING_REQUESTED
  },
  {
    label: "Perda",
    value: InvoiceChargeStatusEnum.LOSS
  },
  {
    label: "Estorno requisitado",
    value: InvoiceChargeStatusEnum.REFUND_REQUESTED
  }
]

export enum InvoicePaymentOptionEnum {
  BANK_BILLET = 'bank_billet',
  CASH = 'cash',
  CREDIT_CARD = 'credit_card',
  PIX = 'pix',
  NOT_PAYABLE = 'not_payable'
}

export type InvoicePaymentOptionTypes = `${InvoicePaymentOptionEnum}`

export const invoicePaymentOptions = [
  {
    label: 'Boleto',
    value: InvoicePaymentOptionEnum.BANK_BILLET
  },
  {
    label: 'Dinheiro',
    value: InvoicePaymentOptionEnum.CASH
  },
  {
    label: "Cartão de crédito",
    value: InvoicePaymentOptionEnum.CREDIT_CARD
  },
  {
    label: "Pix",
    value: InvoicePaymentOptionEnum.PIX
  },
  {
    label: "N/A",
    value: InvoicePaymentOptionEnum.NOT_PAYABLE
  }
]

export enum SubjectKindEnum {
  COMON = 'common',
  DIVERSIFIED = 'diversified',
  FLEXIBLE = 'flexible',
}

export const subjectKindOptions = [
  {
    label: 'Base Nacional Comum',
    value: SubjectKindEnum.COMON
  },
  {
    label: 'Parte Diversificada',
    value: SubjectKindEnum.DIVERSIFIED
  },
  {
    label: 'Parte Flexível Obrigatória',
    value: SubjectKindEnum.FLEXIBLE
  },
]

export enum SubjectFieldEnum {
  HUMAN = 'human',
  NATURE = 'nature',
  LANGUAGE = 'language',
  MATH = 'math'
}

export const subjectFieldOptions = [
  {
    label: 'Ciências Humanas e Suas Tecnologias',
    value: SubjectFieldEnum.HUMAN
  },
  {
    label: 'Ciências da Natureza e Suas Tecnologias',
    value: SubjectFieldEnum.NATURE
  },
  {
    label: 'Linguagens, Códigos e Suas Tecnologias',
    value: SubjectFieldEnum.LANGUAGE
  },
  {
    label: 'Matemática e Suas Tecnologias',
    value: SubjectFieldEnum.MATH
  }
]

export enum InvoiceKindEnum {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export type InvoiceKindTypes = `${InvoiceKindEnum}`

export type invoiceKindOptions = [
  {
    label: 'Fatura Única',
    value: InvoiceKindEnum.SINGLE
  },
  {
    label: 'Fatura Parcelada',
    value: InvoiceKindEnum.MULTIPLE
  }
]

export enum BillingStatusEnum {
  UNPAID = 'unpaid',
  PAID = 'paid'
}

export type BillingStatusTypes = `${BillingStatusEnum}`

export const BillingStatusOptions = [
  {
    label: "Pago",
    value: BillingStatusEnum.PAID
  },
  {
    label: "Não pago",
    value: BillingStatusEnum.UNPAID
  }
]

export const relationshipOptions = [
  {
    label: 'Avô/Avó',
    value: ConnectionEnum.GRANDPARENT
  },
  {
    label: 'Pai/Mãe',
    value: ConnectionEnum.PARENT
  },
  {
    label: 'Irmão/Irmã',
    value: ConnectionEnum.SIBLING
  },
  {
    label: 'Empresa',
    value: ConnectionEnum.COMPANY
  },
  {
    label: 'Próprio aluno',
    value: ConnectionEnum.SELF
  },
  {
    label: 'Outro',
    value: ConnectionEnum.OTHER
  }
]

export enum PeriodEnum {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  NIGHT = 'night',
  FULL_TIME = 'full_time',
  HALF_TIME = 'half_time'
}

export type PeriodTypes = `${PeriodEnum}`

export const periodOptions = [
  {
    label: 'Manhã',
    value: PeriodEnum.MORNING
  },
  {
    label: 'Tarde',
    value: PeriodEnum.AFTERNOON
  },
  {
    label: 'Noite',
    value: PeriodEnum.NIGHT
  },
  {
    label: 'Integral',
    value: PeriodEnum.FULL_TIME
  },
  {
    label: 'Semi-Integral',
    value: PeriodEnum.HALF_TIME
  }
]

export enum ProductTypesEnum {
  KIT = 'kit',
  AFTER_SCHOOL = 'after_school',
  REGULAR = 'regular',
  SCHOOL_PRODUCT = 'school_product',
  OTHER = 'other'
}

export enum ContractStatusEnum {
  TO_SIGN_SERVICE = 'to_sign_service',
  PENDING_SIGNATURE = 'pending_signature',
  SIGNED = 'signed',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  REFUSED = 'refused'
}

export type ContractStatusTypes = `${ContractStatusEnum}`

export type ProductTypes = `${ProductTypesEnum}`

export const productTypeOptions = [
  {
    type: 0,
    label: 'Combo',
    value: ProductTypesEnum.KIT
  },
  {
    type: 1,
    label: 'Contra-Turno',
    value: ProductTypesEnum.AFTER_SCHOOL
  },
  {
    type: 2,
    label: 'Regular',
    value: ProductTypesEnum.REGULAR
  },
  {
    type: 3,
    label: 'Produto da escola',
    value: ProductTypesEnum.SCHOOL_PRODUCT
  },
  {
    type: 4,
    label: 'Outros',
    value: ProductTypesEnum.OTHER
  }
]

export const contractStatusOptions = [
  {
    value: ContractStatusEnum.TO_SIGN_SERVICE,
    label: 'Aguardando envio'
  },
  {
    value: ContractStatusEnum.PENDING_SIGNATURE,
    label: 'Aguardando assinatura'
  },
  {
    value: ContractStatusEnum.SIGNED,
    label: 'Assinado'
  },
  {
    value: ContractStatusEnum.CANCELED,
    label: 'Cancelado'
  },
  {
    value: ContractStatusEnum.EXPIRED,
    label: 'Expirado'
  },
  {
    value: ContractStatusEnum.REFUSED,
    label: 'Recusado'
  }
]

export const monthOptions = [
  {
    value: 0,
    label: 'Entrada'
  },
  {
    value: 1,
    label: 'Janeiro'
  },
  {
    value: 2,
    label: 'Fevereiro'
  },
  {
    value: 3,
    label: 'Março'
  },
  {
    value: 4,
    label: 'Abril'
  },
  {
    value: 5,
    label: 'Maio'
  },
  {
    value: 6,
    label: 'Junho'
  },
  {
    value: 7,
    label: 'Julho'
  },
  {
    value: 8,
    label: 'Agosto'
  },
  {
    value: 9,
    label: 'Setembro'
  },
  {
    value: 10,
    label: 'Outubro'
  },
  {
    value: 11,
    label: 'Novembro'
  },
  {
    value: 12,
    label: 'Dezembro'
  }
]

export const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
const phoneRegex = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/

export const validation = {
  checkPunctualityExpirationDate: (value: any, allValues: any) => {
    const punctuality_expiration_date = value
    if (!(punctuality_expiration_date instanceof Date)) return undefined
    const expiration_date = allValues.expiration_date
    if (expiration_date instanceof Date && expiration_date.getTime() < punctuality_expiration_date.getTime()) {
      return 'Data de pontualidade não pode ser depois da data de vencimento'
    }
    return undefined
  },
  checkExpirationDate: (value: any, allValues: any) => {
    const expiration_date = value
    if (!(expiration_date instanceof Date)) return undefined
    const punctuality_expiration_date = allValues.punctuality_expiration_date
    if (punctuality_expiration_date instanceof Date && expiration_date.getTime() < punctuality_expiration_date.getTime()) {
      return 'Data de vencimento não pode ser antes da data de pontualidade'
    }
    return undefined
  },
  required: (value: any) => {
    return !isEmpty(value) || isFinite(value) ? undefined : 'Preenchimento obrigatório'
  },
  dateRequired: (value: Date | number | string) => {
    return (typeof value === 'string' ? (isIsoDate(value) || convertToDate(value)) : value instanceof Date || !isNaN(value)) ? undefined : 'Preenchimento obrigatório'
  },
  validEmail: (value: string) => ((isEmpty(value) || (!isEmpty(value) && isEmailValid(value))) ? undefined : 'Digite um email válido'),
  validCpf: (value: string | { value: string }) => {
    if (!value) return 'CPF inválido'
    const parsedValue = typeof value === 'object' ? value.value : value
    const withoutSpecialSymbols = removeSpecialSymbols(parsedValue)
    return cpfValidation(withoutSpecialSymbols) ? undefined : 'CPF inválido'
  },
  validCnpj: (value: string | DefaultOptionType) => {
    if (!value) return 'CNPJ inválido'
    let checkedValue: any = value
    if (typeof value === 'object') {
      checkedValue = value.value
    }
    return (removeSpecialSymbols(checkedValue as string).length === 14 ? undefined : 'CNPJ inválido')
  },
  // validDateRange:(value: Date, allValues: {starts_at: Date, ends_at: Date}, otherProps: object, name: string, field_props: object) => {
  //   const splitted_name = name.split('.')
  //   const path = splitted_name[0]
  //   const field_name  = splitted_name[1]
  //   const class_time_object = get(allValues, path) as ClassTimeAttributes
  //   if(field_name === 'starts_at') {
  //     const related_ends_at = class_time_object.ends_at
  //     const 
  //   }
  //   return undefined
  // },
  validInvoiceRenegotiation: (current_invoice_renegotiations: InvoiceRenegotiationFormAttributes[], allValues: RenegotiationFormAttributes) => {
    const invoice_reference = current_invoice_renegotiations.length === 1 ? first(current_invoice_renegotiations)?.invoice_attributes as InvoiceFormAttributes : allValues.invoice_reference as InvoiceFormAttributes
    const result = totalInvoiceValues({
      invoice: invoice_reference,
      invoice_items: invoice_reference.invoice_items_attributes,
      wallet_credits: invoice_reference.wallet_credits_attributes as NestedWalletCreditAttributes[]
    })

    const mapped = current_invoice_renegotiations.map(invoice_renegotiation => {
      const current_invoice_renegotiation_invoice = invoice_renegotiation.invoice_attributes
      const { punctuality_expiration_date, expiration_date } = current_invoice_renegotiation_invoice
      const current_invoice_renegotiation_invoice_items = current_invoice_renegotiation_invoice.invoice_items_attributes
      const { current_charge, current_conditional_charge } = totalInvoiceValues({
        invoice_items: current_invoice_renegotiation_invoice_items,
        invoice: current_invoice_renegotiation_invoice,
        wallet_credits: []
      })
      return ({
        current_charge,
        current_conditional_charge,
        expiration_date,
        punctuality_expiration_date,
        invoice_items_attributes: current_invoice_renegotiation_invoice.invoice_items_attributes
      })
    })
    const total_current_charge = compactSum(mapped.map(item => item.current_charge))
    const total_current_conditional_charge = compactSum(mapped.map(item => item.current_conditional_charge))
    const there_is_punctuality_value_without_date = mapped.some(item => {
      const conditional_charge = toFixedNumber(item.current_conditional_charge)
      const first_evaluation = Boolean(conditional_charge > 0 && !item.punctuality_expiration_date)
      const second_evaluation = (Boolean(conditional_charge === 0 && item.punctuality_expiration_date !== undefined && item.punctuality_expiration_date !== null))
      const result = first_evaluation || second_evaluation
      return result

    })
    const there_is_invalid_values = mapped.some(item => {
      const current_charge = toFixedNumber(item.current_charge)
      const conditional_charge = toFixedNumber(item.current_conditional_charge)
      return (current_charge < 0 || (current_charge > 0 && current_charge <= 10)) || conditional_charge < 0 || item.invoice_items_attributes.some(invoice_item => {
        const result = (invoice_item.base_values?.contract_full_billing_percentage_base || 0) < 0 || (invoice_item.base_values?.contract_conditional_discount_billing_percentage_base || 0) < 0
        return result
      })
    })
    const there_is_invoice_without_expiration_date = mapped.some(item => !item.expiration_date)
    if ((toFixedNumber(total_current_charge) < toFixedNumber(result.current_charge)) || (toFixedNumber(total_current_conditional_charge) < toFixedNumber(result.current_conditional_charge))) {
      return "Cobrança com valores incompatíveis com valor original"
    }

    if (there_is_punctuality_value_without_date) {
      return "Não se pode gerar cobrança com valor de pontualidade sem data de pontualidade"
    }

    if (there_is_invalid_values) {
      return "Não se pode gerar cobrança com valor negativo ou inválido"
    }

    if (there_is_invoice_without_expiration_date) {
      return 'Há faturas sem vencimento'
    }
    return undefined
  },
  validAccountsAttributes: (current_accounts_attributes: AccountFormAttributes[]) => {
    const thereAreNoAccounts = (current_accounts_attributes || []).filter(item => !item._destroy).length === 0
    if (thereAreNoAccounts) {
      return 'Não há contas cadastradas'
    }
    return undefined
  },
  validProfileAttributes: (current_profiles_attributes: ProfilesAttributes[]) => {
    const thereAreNoProfiles = (current_profiles_attributes || []).filter(item => !item._destroy).length === 0
    if (thereAreNoProfiles) {
      return 'Não há perfis cadastrados'
    }
    return undefined
  },
  closurePenaltyValidaiton: (value: any) => {
    return (value === 0 || value >= 10) ? undefined : 'Multa não pode ser menor que 10 reais'
  },
  validPhone: (value: string) => {
    if (!value || !value.includes('+55')) {
      return undefined
    }
    const result = phoneRegex.test(value)
    return result ? undefined : 'Número de celular invalido'
  }
}

export interface RequestParams {
  filters: {
    [key: string]: string | any[]
  }
}

export interface WithRequestParams {
  params?: RequestParams
}

export interface AddId {
  id: string
}

export interface JsonFormat<A, B, C = void> {
  id: string
  type: A
  attributes: B,
  relationships: C
}


// export type CompanyJson = JsonFormat<'companies',CompanyAttrs>
export interface JsonResponseFormat<A, B = void> extends AxiosResponse {
  data: {
    data: A
    included: B,
    meta: {
      page_count: number,
      total_count: number
    }
  }
}

export interface CustomJsonResponseFormat<A> extends AxiosResponse {
  data: A
}

export interface ProfilesAttributes {
  _destroy?: boolean
  id?: string
  role: RoleTypes
}
export interface AccountsAttributes extends AccountAttrs, AddId { }
export interface NestedAccountsAttributes extends AccountsAttributes, NestedAttributesBase { }
export interface NestedAccountAttributesStudentForm extends Partial<NestedAccountsAttributes> {
  registrations_attributes: Partial<NestedRegistrationAttributesStudentForm>[]
}

export interface NestedRegistrationAttributesStudentForm extends RegistrationAttributes, NestedAttributesBase, AddId {
  emergency_contacts_attributes: NestedEmergencyContactAttributes[]
  registration_connections_attributes: NestedRegistrationConnectionAttributes[]
}
export interface NestedAccountAttributesRepresentativeForm extends Partial<NestedAccountsAttributes> {
  representatives_attributes: NestedRepresentativeAttributes[]
}
export interface NestedAccountAttributesCoordinatorForm extends Partial<NestedAccountsAttributes> {
  coordinators_attributes: NestedCoordinatorAttributes[]
  company_id?: number
}

export interface NestedAccountAttributesTeacherForm extends Partial<NestedAccountsAttributes> {
  teachers_attributes: NestedTeacherAttributes[]
  company_id?: number
}
export interface AccountFormAttributes extends Omit<NestedAccountsAttributes, 'active' | 'user_id' | 'student_code' | 'user_name'> {
  company_name?: string
  profiles_attributes: ProfilesAttributes[]
}

export interface CoordinatorAccountFormAttributes extends AccountsAttributes {
  profiles_attributes: ProfilesAttributes[]
  coordinators_attributes: NestedCoordinatorAttributes[]
}

export interface RoomFormAttributes extends RoomAttributes {
  room_students_attributes?: NestedRoomStudentAttributes[]
}


export interface DefaultOptionType {
  label: string | number,
  value: string | number
}

export interface CountryOptions extends DefaultOptionType {
  flag: string,
  phonecode: string
}

export interface StateOptions extends DefaultOptionType {
  isoCode: string,
  countryCode: string
}

export type OrderOptions = 'asc' | 'desc'

export interface LocationState {
  classroom_id: number
  company_id: number
  company_kind: CompanyTypes
  company_name: string
  contract_setting_id: number
  coordinator_id: number
  copy: boolean
  course_id: number
  curriculum_id: number
  discount_type_id: number
  initialStep: number
  ktwelve_id: number
  product_family_id: number
  product_id: number
  profile_dashboard_id: number
  registration_closure_id: number
  registration_id: number
  subject_id: number
  teacher_id: number
  user_id: number
  ktwelve_curriculum_form: boolean
  calendar_event_id: number
}
export interface ContractAttributes extends ContractAttrs {
  id: string
}

export interface ContractStepTableAttributes extends ContractAttributes {
  representative_product: RepresentativeProductAttributes
}
export interface ProductFamilyAttributes extends ProductFamilyAttrs {
  id?: string
}

export interface ClassroomAttributes extends ClassroomAttrs {
  id?: string
}

export interface SubjectAttributes extends SubjectAttrs, AddId {

}

export interface CoordinatorAttributes extends CoordinatorAttrs, AddId {

}

export interface CurriculumAttributes extends CurriculumAttrs, AddId {

}

export interface TeacherAttributes extends TeacherAttrs, AddId {

}


export interface ClassroomViewAttributes extends ClassroomAttributes {
  ktwelve?: KtwelvesAttributes
  company?: string
}

export interface ContractsViewData {
  accounts: AccountsAttributes[]
  classrooms: ClassroomAttributes[]
  companies: CompanyAttributes[]
  billings: BillingAttributes[]
  contracts: ContractAttributes[]
  invoice_items: InvoiceItemAttributes[]
  invoices: InvoiceAttributes[]
  ktwelves: KtwelvesAttributes[]
  payment_options: PaymentOptionAttributes[]
  payment_collections: PaymentCollectionAttributes[]
  products: ProductAttributes[]
  registrations: RegistrationAttributes[]
  registration_products: RegistrationProductAttributes[]
  representative_products: RepresentativeProductAttributes[]
  representatives: RepresentativeAttributes[]
  users: UserAttributes[]
}

export enum ChargeGenerationConfigEnum {
  MANUALLY = 'manually',
  ON_SCHEDULE = 'on_schedule',
  ON_INVOICE_GENERATION = 'on_invoice_generation',
  ON_CONTRACT_SIGN = 'on_contract_sign'
}

export enum PaymentOptionPortionsEnum {
  MONTHLY = 'monthly',
  SEMI_ANUALY = 'semi_anual',
  YEARLY = 'yearly'
}

export const paymentOptionPortionsOptions = [
  {
    label: "Mensal",
    value: PaymentOptionPortionsEnum.MONTHLY
  },
  {
    label: "Semestral",
    value: PaymentOptionPortionsEnum.SEMI_ANUALY
  },
  {
    label: "Anual",
    value: PaymentOptionPortionsEnum.YEARLY
  }
]

export type ChargeGenerationConfigType = `${ChargeGenerationConfigEnum}`

export enum PaymentServiceEnum {
  ASAAS = 'asaas',
  SICOOB = 'sicoob'
}

export const chargeGenerationConfigOptions = [
  {
    label: 'Gerar cobranças manualmente',
    value: ChargeGenerationConfigEnum.MANUALLY
  },
  {
    label: 'Gerar cobranças nas regras de agendamento',
    value: ChargeGenerationConfigEnum.ON_SCHEDULE
  },
  {
    label: 'Gerar cobrança na geração da fatura',
    value: ChargeGenerationConfigEnum.ON_INVOICE_GENERATION
  },
  {
    label: 'Gerar cobrança na assinatura do contrato',
    value: ChargeGenerationConfigEnum.ON_CONTRACT_SIGN
  }
]

export enum RenegotiationStatusEnum {
  ACTIVE = 'active',
  CANCELED = 'canceled'
}

export type RenegotiationStatusTypes = `${RenegotiationStatusEnum}`

export const renegotiationStatusOptions = [
  {
    label: 'Ativa',
    value: RenegotiationStatusEnum.ACTIVE
  },
  {
    label: 'Cancelada',
    value: RenegotiationStatusEnum.CANCELED
  },
]

export interface AuditAttributes extends AuditAttrs , AddId {}
export enum AuditActionEnum {
  CREATE='create',
  DESTROY = 'destroy',
  UPDATE='update'
}
export const auditActionOptions = [
  {
    label: "Inclusão",
    value: AuditActionEnum.CREATE
  },
  {
    label: "Remoção",
    value: AuditActionEnum.DESTROY
  },
  {
    label: "Alteração",
    value: AuditActionEnum.UPDATE 
  }
]
export interface CoursesAttributes extends CourseAttrs {
  id?: string
}

export interface KtwelvesAttributes extends KtwelveAttrs {
  id?: string
}

export interface KtwelvesViewAttributes extends KtwelvesAttributes {
  course?: CoursesAttributes
}

export interface WalletsViewAttributes extends WalletAttributes {
  name: string;
  document_number: string;
  company: string;
}

export interface FormattedCompanyData {
  name: string,
  description: string,
  document: string,
  kind: CompanyTypes,
  additional_data: {
    primary_color: string,
    secondary_color: string
  },
  parent_id: number | string,
  child_ids: number[]
  descendants_ids: number[]
  ancestry: string
  logo_url: string,
  logo_attachment_id: number
  id: string | number
}

export interface FormattedUserData {
  name: string
  phone: string
  email: string
  profiles: ProfileTypes[]
  document_type: string
  document_number: string
  state: string | null
  city: string | null
  company: string | undefined
  id: string
  avatar_url: string
}

export interface FormFileType {
  url?: string,
  id?: number,
  name?: string,
  size?: string,
  file?: File
}

export type onConfirm = ({ setLoading, handleClose, setLoadingMessage }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>, handleClose: () => void, setLoadingMessage: React.Dispatch<React.SetStateAction<string>> }) => void

export interface FormattedRepresentativeData {
  user_id: string;
  name: string
  birthdate: string
  birthplace: string
  document_number: string
  document_type: string
  email: string
  ethnicity_ids: (string | number)[]
  fractal_id: number
  gender: string
  marital_status?: string
  nationality: string
  occupation: string
  phone: string
  profiles: ProfileTypes[]
  race_ids: (string | number)[]
  spokesperson: string
  address: {
    user_id: number;
    country_id: number;
    state_id: number;
    city_id: number;
    street: string;
    complement: string;
    neighbourhood: string;
    number: string;
    zipcode: string;
  };
  account_id: number;
  connection: ConnectionTypes
  connection_description: string;
  kind: RepresentativeTypes
  registration_product_ids: (string | number)[];
  registration_id: number;
  id: string;
}

export interface CurrentStudentData {
  id: string;
  address_attributes?: NestedAddressAttributes;
  user_alergies_attributes?: NestedUserAlergyAttributes[];
  user_deficiencies_attributes?: NestedUserDeficiencyAttributes[];
  account: {
    active: boolean;
    user_id: number;
    company_id: number;
    student_code: string;
    id: string;
  }
  name: string;
  email: string;
  gender: string;
  phone: string;
  birthdate: string;
  document_type: string;
  document_number: string;
  nationality: string;
  birthplace: string;
  marital_status: string;
  fractal_id: string | number;
  additional_data: {
    social_name: string
    personal_email: string
  };
  profiles: ProfileTypes[];
  avatar_attachment_id: number
  avatar_url: string
  race_ids: number[]
  ethnicity_ids: number[]
  emergency_contacts_attributes?: NestedEmergencyContactAttributes[]
  accounts_attributes: NestedAccountAttributesStudentForm[]
}

export enum PaymentSimulationBillingKindEnum {
  PORTION = 'portion',
  REGISTRATION_FEE = 'registration_fee'
}

export type PaymentSimulationBillingKindType = `${PaymentSimulationBillingKindEnum}`

export interface PaymentSimulationBillings {
  kind: PaymentSimulationBillingKindType,
  final_value?: number
  value?: number
  initial_value?: number
  month?: string
  portion?: number
  punctuality_included: boolean
  subsidy_discount: number
  financial_discount: number
  total: {
    final: number,
    full: number,
    final_financial: number
  }
}

export interface InvoiceFilters {
  statusFilter: InvoiceStatusTypes[];
  chargeStatusFilter: InvoiceChargeStatusTypes[];
  registrationFilter: string;
  representativeFilter: string;
  fromExpirationDateFilter: Date | null;
  toExpirationDateFilter: Date | null;
}

export type PaymentSimulationPaymentOption = Omit<PaymentOptionAttrs, 'product_id' | 'active' | 'expiration_day' | 'punctuality_expiration_day' | 'fees' | 'penalty' | 'active_starts_at' | 'active_ends_at'>

export interface PaymentSimulationPayment {
  total: {
    full: number
    final: number
    annual_discount: number
    portion_discount: number
    portions: number
    total_fin_discount: number
  },
  punctuality_discount: number
  portion_discount: number
  value_per_portion?: number
  billings: PaymentSimulationBillings[],
  payment_option: PaymentSimulationPaymentOption
}

export interface PaymentDataItems {
  payment: PaymentSimulationPayment
  product: {
    description: string
    id: number
    name: string
  }
  registration_product_id: number
}

export interface PaymentSimulation {
  selected_items: PaymentDataItems[]
}

export interface RegistrationProductSubsidyAttributes extends RegistrationProductSubsidyAttrs, AddId { }

export interface RegistrationAttributes extends RegistrationAttrs, AddId { }
export interface NestedRegistrationProductSubsidyAttributes extends RegistrationProductSubsidyAttributes, NestedAttributesBase { }

export interface UpdateRegistrationProductFormAttributes extends RegistrationProductAttributes {
  registration_product_subsidies_attributes: NestedRegistrationProductSubsidyAttributes[]
}
export interface RegistrationFormData extends Partial<RegistrationAttributes> {
  registration_connections_attributes?: NestedRegistrationConnectionAttributes[];
  emergency_contacts_attributes?: NestedEmergencyContactAttributes[];
  representatives_attributes?: NestedRepresentativeAttributes[]
  registration_products_attributes?: NestedRegistrationProductsAttributes[]
  registration_documents_attributes?: NestedRegistrationDocumentsAttributes[]
}

export interface SubjectPeriodAttributes extends SubjectPeriodAttrs, AddId { }
export interface TeacherSubjectAttributes extends TeacherSubjectAttrs, AddId { }
export interface NestedTeacherSubjectAttributes extends TeacherSubjectAttributes, NestedAttributesBase {}


export interface FormulaFormData extends FormulaAttributes {
  operation_attributes: NestedOperationAttributes
}

export interface ExamPlacementAttributes extends ExamPlacementAttrs, AddId { }
export interface FormulaAttributes extends FormulaAttrs, AddId { }
export interface OperationAttributes extends OperationAttrs, AddId {
  label?: string
}
export interface NestedOperationAttributes extends OperationAttributes, NestedAttributesBase { }
export interface NestedExamPlacementAttributes extends ExamPlacementAttributes, NestedAttributesBase { }
export interface CurriculumSubjectAttributes extends CurriculumSubjectAttrs, AddId { }
export interface NestedCurriculumSubjectAttributes extends CurriculumSubjectAttributes, NestedAttributesBase { }
export interface CompositionAttributes extends CompositionAttrs, AddId { }
export interface CompositionPeriodAttributes extends CompositionPeriodAttrs, AddId { }
export interface NestedCompositionPeriodAttributes extends CompositionPeriodAttributes, NestedAttributesBase {
  exam_placements_attributes: NestedExamPlacementAttributes[]
}
export interface NestedCompositionAttributes extends CompositionAttributes, NestedAttributesBase {
  composition_periods_attributes: NestedCompositionPeriodAttributes[]
}

export interface KtwelveSubjectAttributes extends KtwelveSubjectAttrs, AddId { }

export interface NestedKtwelveSubjectAttributes extends KtwelveSubjectAttributes, NestedAttributesBase { }

export interface KtwelveCurriculumAttributes extends KtwelveCurriculumAttrs, AddId {
  ktwelve_subjects_attributes?: NestedKtwelveSubjectAttributes[]
}
export interface KtwelveCurriculumRegistrationAttributes extends KtwelveCurriculumRegistrationRegistrationAttrs, AddId {}

export interface NestedKtwelveCurriculumAttributes extends KtwelveCurriculumAttributes, NestedAttributesBase { }

export interface RegistrationConnectionAttributes extends RegistrationConnectionAttrs, AddId { }

export interface NestedRegistrationConnectionAttributes extends RegistrationConnectionAttributes, NestedAttributesBase { }
export interface RegistrationClosureAttributes extends RegistrationClosureAttrs, AddId { }

export interface RegistrationDocumentsAttributes extends RegistrationDocumentAttrs, AddId { }
export interface NestedRegistrationDocumentsAttributes extends Partial<RegistrationDocumentsAttributes>, NestedAttributesBase { }
export interface RepresentativeAttributes extends RepresentativeAttrs, AddId { }
export interface NestedRepresentativeAttributes extends Partial<RepresentativeAttributes>, NestedAttributesBase { }
export interface NestedCoordinatorAttributes extends Partial<CoordinatorAttributes>, NestedAttributesBase { }
export interface NestedRoomStudentAttributes extends RoomStudentAttributes, NestedAttributesBase { }
export interface NestedTeacherAttributes extends Partial<TeacherAttributes>, NestedAttributesBase { }
export interface EmergencyContactAttributes extends EmergencyContactsAttrs, AddId { }
export interface NestedEmergencyContactAttributes extends EmergencyContactAttributes, NestedAttributesBase {
  country_code?: {
    value: string;
    label: string;
    countryValue: string | number;
  }
}
export interface BaseInvoiceValues {
  renegotiation_allowance: number;
  contractual_addition: number;
  installment_addition: number;
  anticipation_discount: number;
  expiration_date: Date | string
  fees: number
  penalty: number
}
export interface UserRaceAttributes extends UserRaceAttrs, AddId { }
export interface NestedUserRaceAttributes extends UserRaceAttributes, NestedAttributesBase { }
export interface UserAlergyAttributes extends UserAlergyAttrs, AddId { }
export interface NestedUserAlergyAttributes extends UserAlergyAttributes, NestedAttributesBase { }
export interface UserDeficiencyAttributes extends UserDeficiencyAtrrs, AddId { }
export interface NestedUserDeficiencyAttributes extends UserDeficiencyAttributes, NestedAttributesBase { }
export interface RepresentativeProductAttributes extends RepresentativeProductAttrs, AddId { }
export interface InvoiceAttributes extends InvoiceAttrs, AddId { }

export interface InvoiceItemAttributes extends InvoiceItemAttrs, AddId { }
export interface InvoiceFormAttributes extends Partial<Omit<InvoiceAttributes, 'expiration_date' | 'punctuality_expiration_date'>> {
  invoice_items_attributes: Partial<InvoiceItemAttributes>[]
  wallet_credits_attributes?: NestedWalletCreditAttributes[]
  expiration_date?: Date | string
  punctuality_expiration_date?: Date | string
}

export interface CurriculumFormAttributes extends CurriculumAttributes {
  curriculum_subjects_attributes: NestedCurriculumSubjectAttributes[]
  compositions_attributes: NestedCompositionAttributes[]
}
export interface InvoiceRenegotiationAttributes extends Partial<InvoiceRenegotiationAttrs>, AddId { }
export interface InvoiceRenegotiationFormAttributes extends Omit<InvoiceRenegotiationAttributes, 'id'> {
  invoice_attributes: InvoiceFormAttributes
}
export interface RenegotiationAttributes extends RenegotiationsAttrs, AddId { }
export interface RenegotiationFormAttributes extends Partial<RenegotiationAttributes> {
  invoices_attributes: InvoiceFormAttributes[]
  invoice_renegotiations_attributes: InvoiceRenegotiationFormAttributes[]
  invoice_reference?: InvoiceFormAttributes
  remaining_invoice_renegotiations_attributes?: InvoiceRenegotiationFormAttributes[]
}
export interface InstallmentNestedInvoiceItemAttributes extends Omit<InvoiceItemAttributes, 'id' | 'code'>, NestedAttributesBase { }
export interface NestedInvoiceItemAttributes extends Partial<InvoiceItemAttributes>, NestedAttributesBase { }
export interface NestedInstallmentsAttributes extends InvoiceFormAttributes, NestedAttributesBase { }
export interface BillingAttributes extends BillingAttrs, AddId { }
export interface ScheduleAttributes extends ScheduleAttrs, AddId { }
export interface TransactionAttributes extends TransactionAttrs, AddId { }
export interface WalletAttributes extends WalletAttrs, AddId { }
export interface WalletCreditAttributes extends WalletCreditAttrs, AddId { }
export interface NestedWalletCreditAttributes extends Partial<WalletCreditAttributes>, NestedAttributesBase { }
export interface CurrentRegistrationData extends RegistrationAttrs, AddId {
  emergency_contacts_attributes: NestedEmergencyContactAttributes[]
  registration_connections_attributes: NestedRegistrationConnectionAttributes[];
}

export interface ConnectionOption {
  name: string
  value: string
}

export interface ElementView {
  element: React.ElementType,
  props: object,
  step: number,
  label: string
  path?: Paths
}

export interface UserOptionsData {
  id: string;
  name: string;
  email: string;
  gender: string;
  phone: string;
  birthdate: string;
  document_type: string;
  document_number: string;
  nationality: string;
  birthplace: string;
  marital_status: string;
  fractal_id: string | number;
  additional_data: AdditionalData;
  profiles: ProfileTypes[]
  avatar_attachment_id: number;
  avatar_url: string;
  race_ids: number[];
  ethnicity_ids: number[];
  account?: {
    id: string;
    active: boolean;
    user_id: number;
    company_id: number;
    student_code: string;
  }
  accounts_attributes?: NestedAccountAttributesRepresentativeForm[] | NestedAccountAttributesCoordinatorForm[] | NestedAccountAttributesTeacherForm[] | NestedAccountAttributesStudentForm[]
  user_deficiencies_attributes?: NestedUserDeficiencyAttributes[]
  user_alergies_attributes?: NestedUserAlergyAttributes[];
  address_attributes?: NestedAddressAttributes
  user_races_attributes?: NestedUserRaceAttributes[]
}

export interface AddressAttributes extends Omit<AddressAttrs, 'city_id' | 'country_id' | 'state_id'>, AddId {
  state_id: string
  street: string
  zipcode: string
  complement: string
  number: string
  city_id: string
  country_id: string
  neighbourhood: string
}

export interface NestedAddressAttributes extends AddressAttributes, AddId { }

export interface AdditionalData {
  passport_emission?: string | Date | number
  passport_expiry?: string | Date | number
  passport_origin?: string
  social_name?: string
  personal_email?: string
}

export interface StudentStepFormData {
  id?: string
  user_alergies_attributes: NestedUserAlergyAttributes[];
  user_deficiencies_attributes: NestedUserDeficiencyAttributes[],
  address_attributes: NestedAddressAttributes
  additional_data: AdditionalData
  name: string;
  email: string;
  gender: string;
  birthdate: Date | string;
  phone: string;
  document_number: string;
  nationality_country: CountryOptions
  nationality_state: StateOptions
  nationality_city: DefaultOptionType
  avatar?: FormFileType
  accounts_attributes: NestedAccountAttributesStudentForm[]
  user_races_attributes: NestedUserRaceAttributes[]
}

export interface CurrentResponsibleData extends RepresentativeAttributes {
  kinds: RepresentativeTypes[];
  representativeIds: {
    kind: RepresentativeTypes;
    id: string;
  }[];
}

export interface ResponsibleStepFormData {
  id: string
  name: string
  email: string
  gender: string
  birthdate: string
  phone: string
  document_number: string
  additional_data: {
    personal_email: string
  }
  address_attributes?: NestedAddressAttributes
  country_code: {
    value: string;
    label: string;
    countryValue: string | number;
  } | undefined;
  nationality_country: CountryOptions
  nationality_state: StateOptions
  nationality_city: DefaultOptionType
  accounts_attributes: NestedAccountAttributesRepresentativeForm[]
}

export interface CoordinatorFormData {
  id: string
  name: string
  email: string
  gender: string
  birthdate: string
  birthplace?: string
  nationality?: string
  phone: string
  company_id?: number
  document_number: string
  additional_data: {
    personal_email: string
  }
  address_attributes?: NestedAddressAttributes
  country_code: {
    value: string;
    label: string;
    countryValue: string | number;
  } | undefined;
  nationality_country: CountryOptions
  nationality_state: StateOptions
  nationality_city: DefaultOptionType
  accounts_attributes: NestedAccountAttributesCoordinatorForm[]
  coordinator_attributes?: NestedCoordinatorAttributes[]
  user_id?: string
  account_id?: string
}

export interface TeacherFormData {
  id: string
  name: string
  email: string
  gender: string
  birthdate: string
  birthplace?: string
  nationality?: string
  phone: string
  company_id?: number
  document_number: string
  additional_data: {
    personal_email: string
  }
  address_attributes?: NestedAddressAttributes
  country_code: {
    value: string;
    label: string;
    countryValue: string | number;
  } | undefined;
  nationality_country: CountryOptions
  nationality_state: StateOptions
  nationality_city: DefaultOptionType
  accounts_attributes: NestedAccountAttributesTeacherForm[]
}


export interface UserAttributes extends UserAttrs, AddId { }
export interface RepresentativeFormUserAttributes extends Omit<UserAttributes, 'profiles' | 'avatar' | 'fractal_id' | 'avatar_attachment_id' | 'avatar_url' | 'race_ids' | 'ethnicity_ids'>, AddId { }
export interface ResponsibleStepFormInitialValues extends Partial<RepresentativeFormUserAttributes> {
  address_attributes?: NestedAddressAttributes
  accounts_attributes: NestedAccountAttributesRepresentativeForm[]
  nationality_country?: CountryOptions
  nationality_state?: StateOptions
  nationality_city?: DefaultOptionType | string
}

export interface ProductStepFormData {
  conceded_by: string
  product_id: string
  financial_responsible: number[]
  payment_option: PaymentOptionAttributes
  starts_at: string
}

export interface ProductOptions extends ProductAttrs, DefaultOptionType {
  periods: any
}

export interface ComercialDiscountOptions {
  description: string;
  discount: number;
  status: string;
  discount_type_id: number;
  kind: DiscountTypes
  product_id: number;
  portions: number[];
  changeable: boolean;
  include_registration: boolean;
  label: string | undefined;
  value: string;
  discount_type: {
    company_id: number;
    name: string;
    description: string;
    suggested_kind: DiscountTypes
    suggested_discount: number;
    id: string;
  } | null;
}

export interface ContractPartialsData {
  partialName: string;
  partialValue: number;
  service: boolean;
  comercialDiscount: number;
  paymentValue: number;
  initialPartialValue: number;
  financialDiscount?: number;
  portion: number;
  subsidies?: {
    id: string
    value: number
  }[]
}


export interface ContractData {
  parsedPartials: {
    partialName: string;
    service: boolean;
    partialValue: string;
    comercialDiscount: string;
    paymentValue: string;
    initialPartialValue: string;
  }[];
  parsedResult: {
    fullValue: string;
    fullDiscountValue: string;
    resultValue: string;
  };
  result: {
    fullValue: number;
    fullDiscountValue: number;
    resultValue: number;
  };
  partials: ContractPartialsData[];
}

export interface NewContractDataPartials {
  partialName: string;
  service: boolean;
  comercialDiscount: number
  paymentValue: number
  disabled: boolean
}

export interface ParsedNewContractDataPartials {
  partialName: string;
  service: boolean;
  comercialDiscount: string
  paymentValue: string
  disabled: boolean
}
export interface NewContractData {
  partials: NewContractDataPartials[]
  parsedPartials: ParsedNewContractDataPartials[]
  results: {
    fullValue: number;
    fullDiscountValue: number;
  }
  parsedResults: {
    fullValue: string;
    fullDiscountValue: string;
  }
}
export interface NewContractResults {
  fullValue: number;
  fullDiscountValue: number;
}

export interface AppliedCommercialDiscountPartials extends ContractPartialsData {
  discountValue: number
}

export interface Subsidies extends SubsidyAttrs {
  id: string
}

export interface AppliedComercialDiscounts {
  subsidy_id: string
  id: string;
  label: string;
  description?: string;
  portions: number[]
  total: number;
  discount: number;
  discountKind: DiscountTypes;
  parsedTotal: string;
  stored: boolean;
  comments: string;
}

export interface ParsedPaymentData {
  partialName: string;
  partialValue: string;
  paymentValue: string;
  financialDiscount: string;
  service: boolean;
  partialLabel: string;
  disabled?: boolean;
  portion: number;
}

export interface PaymentDataResult {
  fullValue: number
  fullDiscountValue: number
  finalFullValue: number
}

export interface ParsedPaymentDataResult {
  fullValue: string
  fullDiscountValue: string
  finalFullValue: string
}


export interface PaymentDataPartials {
  partialName: string
  partialValue: number
  paymentValue: number
  financialDiscount: number
  disabled?: boolean
  service: boolean
  portion: number;
}

export interface PaymentData {
  parsedPartials: ParsedPaymentData[];
  parsedResult: ParsedPaymentDataResult;
  result: PaymentDataResult;
  partials: PaymentDataPartials[];
}

export interface RegDetailsRepresentativeData {
  address: {
    id: string;
    user_id: number;
    country_id: number;
    state_id: number;
    city_id: number;
    street: string;
    complement: string;
    neighbourhood: string;
    number: string;
    zipcode: string;
    city?: string;
    state?: string;
    country?: string
  };
  id: string;
  type: "representatives";
  account_id: number;
  connection: ConnectionTypes
  connection_description: string;
  kind: RepresentativeTypes
  registration_id: number;
  spokesperson: string;
  occupation: string;
  name: string
  email: string
  gender: string
  phone: string
  birthdate: string
  document_type: string
  document_number: string
  nationality: string
  birthplace: string
  marital_status: string
  fractal_id: number
  additional_data: {
    social_name: string
  },
  profiles: ProfileTypes[]
  avatar_attachment_id: number
  avatar_url: string
  race_ids: number[]
  ethnicity_ids: number[]
}

export interface ProductStepRegistrationProduct extends RegistrationProductAttributes, AddId {
  payment_simulation: PaymentSimulationData
  product: ProductAttributes
  closure?: RegistrationClosureAttributes
  contracts: ContractStepTableAttributes[]
  representative_products: RepresentativeProductAttributes[]
  representatives: RepresentativeAttributes[]
  accounts: AccountsAttributes[]
  users: UserAttributes[]
}

export interface PaymentSimulationDataResult {
  tableValue: number;
  initialValue: number;
  discountValue: number;
  total: number;
}

export interface ParsedPaymentSimulationDataResult {
  tableValue: string;
  initialValue: string;
  discountValue: string;
  total: string;

}

export interface PaymentSimulationPaymentInfo {
  code?: string;
  month?: string;
  tableValue: string;
  initialValue: string;
  discountValue: string;
  total: string;
}

export interface ParsedPaymentSimulationData {
  value: string;
  discount: string;
  subtotal: string;
  id: number;
  kind: string;
  result: PaymentSimulationPaymentInfo;
  paymentInfo: PaymentSimulationPaymentInfo[];
}

export interface PaymentSimulationData {
  parsedResult: ParsedPaymentSimulationDataResult;
  value: string;
  discount: string;
  subtotal: string;
  id: number;
  paymentInfo: PaymentSimulationPaymentInfo[];
  result: PaymentSimulationDataResult;
}


export interface RegDetailsCurrentRegistrationData {
  id: string;
  company_id: string;
  representatives: {
    financial_representatives: RegDetailsRepresentativeData[];
    pedagogical_representatives: RegDetailsRepresentativeData[];
    pedagogical_financial_representatives: RegDetailsRepresentativeData[];
  };
  account_id: number;
  code: string;
  ends_at: string;
  starts_at: string;
  status: string;
  payment_simulation: PaymentSimulation;
  user_data: {
    id: number;
    name: string;
    email: string;
  };
  products?: {
    kind: ProductTypes
    price: string
    discount: string
    subtotal: string
    description: string
    id: string
  }[]
  documents?: {
    name: string
    url: string
    filename: string
    date: string
    id: string
  }[]

}

export interface RegDetailsCurrentStudentData {
  address: {
    id: string;
    user_id: number;
    country_id: number;
    state_id: number;
    city_id: number;
    street: string;
    complement: string;
    neighbourhood: string;
    number: string;
    zipcode: string;
    city?: string
    country?: string
    state?: string
  } | undefined
  alergies: {
    alergy: string;
    description: string;
    alergyId: number;
    stored: boolean;
  }[];
  deficiencies: {
    deficiency: string;
    description: string;
    deficiencyId: number;
    stored: boolean;
  }[];
  user_connections: {
    kind: string;
    kindLabel: string | undefined;
    connectionId: number;
    connectionLabel: string;
    stored: boolean;
  }[];
  id: string;
  name: string;
  email: string;
  gender: string;
  phone: string;
  birthdate: string;
  document_type: string;
  document_number: string;
  nationality: string;
  birthplace: string;
  marital_status: string;
  fractal_id: number;
  additional_data: {
    social_name: string;
  };
  profiles: ProfileTypes[]
  avatar_attachment_id: number;
  avatar_url: string;
  race_ids: number[];
  ethnicity_ids: number[];
  race?: string
  ethnicity?: string
}

export interface FormattedRegistrationViewRepData {
  name: string;
  email: string;
  gender: string;
  phone: string;
  birthdate: string;
  document_type: string;
  document_number: string;
  nationality: string;
  birthplace: string;
  marital_status: string;
  fractal_id: string | number;
  additional_data: object;
  profiles: ProfileTypes[]
  type: "representatives";
  account_id: number;
  connection: ConnectionTypes
  connection_description: string;
  kind: RepresentativeTypes;
  registration_id: number;
  id: string;
}

export interface NestedRepresentativeProductAttributes extends RepresentativeProductAttrs, AddId { }
export interface RegistrationProductFormAttributes extends Partial<RegistrationProductAttrs> {
  representative_products_attributes: Partial<NestedRepresentativeProductAttributes>[]
}
export interface RegistrationProductAttributes extends RegistrationProductAttrs, AddId { }
export interface NestedRegistrationProductsAttributes extends Partial<RegistrationProductAttributes>, NestedAttributesBase { }
export interface FormattedRegistrationViewRegData extends RegistrationAttrs {
  id: string
  account: AccountsAttributes
  user: {
    name?: string | undefined;
    email?: string | undefined;
    gender?: string | undefined;
    phone?: string | undefined;
    birthdate?: string | undefined;
    document_type?: string | undefined;
    document_number?: string | undefined;
    nationality?: string | undefined;
    birthplace?: string | undefined;
    marital_status?: string | undefined;
    fractal_id?: string | number | undefined;
    additional_data?: object | undefined;
    profiles?: ProfileTypes[] | undefined;
    account_id?: number | undefined;
    user_id?: string | undefined;
  };
  representatives: {
    financial_representatives?: FormattedRegistrationViewRepData[] | undefined;
    pedagogical_representatives?: FormattedRegistrationViewRepData[] | undefined;
  };
  classroom: {
    classroom: string;
    ktwelve: string;
  };
  registration_products: RegistrationProductAttrs[]
  registration_closure: RegistrationClosureAttrs & {
    id: string
  } | null
}

export interface CompanyFormData extends Partial<CompanyAttributes> {
  state?: string
  street?: string
  zipcode?: string
  complement?: string
  number?: string
  city?: string
  neighbourhood?: string
  logo?: FormFileType
  email_header_banner?: FormFileType
  primary_color?: string
  secondary_color?: string
  id?: string
}

export const monetaryValueMaskProps = {
  prefix: 'R$',
  allowNegative: false,
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  allowedDecimalSeparators: [',', '.'],
  isAllowed: (value: {
    formattedValue: string;
    value: string;
    floatValue: number;
  }) => {
    const { floatValue } = value;
    if (floatValue >= 0 || floatValue === undefined) {
      return true;
    } else {
      return false;
    }
  },
}

export const percentageMaskProps = {
  allowNegative: false,
  suffix: '%',
  allowedDecimalSeparators: [',', '.'],
  isAllowed: (value: {
    formattedValue: string;
    value: string;
    floatValue: number;
  }) => {
    const { floatValue } = value;
    if ((floatValue >= 0 && floatValue <= 100) || floatValue === undefined) {
      return true;
    } else {
      return false;
    }
  },
}
