/* eslint-disable camelcase */
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import {
  ClassTimeDisplayTableAttributes,
  ClassTimeSubjectAttributes,
} from '../utils/constants';
import ClassPlanForm from '../components/form/ClassPlanForm';
import { UPDATE_CLASS_TIME_SUBJECT } from '../store/class_time_subjects';

interface ClassPlanFormAttributes {
  class_times_attributes: ClassTimeDisplayTableAttributes[]
}

const ClassPlanFormContainer = (props: {
  class_times: ClassTimeDisplayTableAttributes[]
  onSave: (data: ClassTimeSubjectAttributes[]) => void
}) => {
  const { class_times, onSave } = props;
  const dispatch = useDispatch();
  const class_times_attributes = {
    class_times_attributes: class_times.map(item => ({
    id: item.id,
    ends_at: item.ends_at,
    starts_at: item.starts_at,
   class_time_subjects_attributes: item.class_time_subjects_attributes.map(cts => ({
    id: cts.id,
    subject_name: cts.subject_name,
    class_plan: cts.class_plan
   })),
  }))} as ClassPlanFormAttributes

  const onSubmit = React.useCallback(
    async (data: typeof class_times_attributes) => {
      try {

        const new_data = [] as ClassTimeSubjectAttributes[]
        for (const class_time of data.class_times_attributes) {
          for(const class_time_subject of class_time.class_time_subjects_attributes) {
            const {id, class_plan} = class_time_subject
            const response = await dispatch(
              UPDATE_CLASS_TIME_SUBJECT.request({
                id,
                data: {
                  class_plan
                },
              }),
            );
            const { data: { data } } = response
            const formatted_data = {
              id: data.id,
              ...data.attributes
            }
            new_data.push(formatted_data)
          }
        }
        dispatch(
          success({
            message: 'Plano de aula salvo com sucesso.',
          }),
        );
        await onSave(new_data);
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar plano de aula.',
          }),
        );
      }
    },
    [],
  );

  return (
    <ClassPlanForm
      initialValues={class_times_attributes}
      onSubmit={onSubmit}
    />
  );
};

export default ClassPlanFormContainer;
