/* eslint-disable camelcase */
import { find, isNil, keys } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CompositionPeriodAttributes,
  CompositionPeriodKindEnum,
  FormulaAttributes,
  FormulableType,
  FormulaFormData,
  LocationState,
  NestedOperationAttributes,
  OperationAttributes,
} from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { CREATE_FORMULA, FETCH_FORMULA, UPDATE_FORMULA } from '../store/formulas';
import { OperationJson } from '../store/operations';
import FormulasForm from '../components/form/FormulasForm';

const childrenOperationUpdater = (
  currentChildrenOperation: NestedOperationAttributes[],
  initialChildrenOperation: NestedOperationAttributes[],
) => {
  const sorted: NestedOperationAttributes[] = [];
  currentChildrenOperation.forEach((children_operation) => {
    const related = find(initialChildrenOperation, (initial) => initial.id === children_operation.id);
    if (isNil(related)) {
      sorted.push(children_operation);
      return;
    }
    const omit = ['id', '_destroy'];
    const result = verifyDifferentValues(children_operation, related, omit) as NestedOperationAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentChildrenOperations = children_operation.children_attributes;
      const initialChildrenOperations = related?.children_attributes;
      const children_attributes = childrenOperationUpdater(
        currentChildrenOperations as NestedOperationAttributes[],
        initialChildrenOperations as NestedOperationAttributes[],
      );
      const formattedPaymentOption = {
        ...result,
        children_attributes,
      };
      sorted.push(formattedPaymentOption);
    }
  });
  return sorted as NestedOperationAttributes[];
};

const operationUpdater = (
  currentOperationAttribute: OperationAttributes,
  initialOperationAttribute: OperationAttributes,
) => {
  const { children_attributes, ...dataRest } = currentOperationAttribute;
  const { id, ...rest } = verifyDifferentValues(dataRest, initialOperationAttribute, [
    'id',
    'formula_id',
  ]) as OperationAttributes;
  const currentChildrenOperations = currentOperationAttribute.children_attributes;
  const initialChildrenOperations = initialOperationAttribute?.children_attributes;
  const updated_children_attributes = childrenOperationUpdater(
    currentChildrenOperations as NestedOperationAttributes[],
    initialChildrenOperations as NestedOperationAttributes[],
  );
  return {
    id,
    ...rest,
    children_attributes: updated_children_attributes,
  };
};

const FormulasFormContainer = (props: {
  formula_id?: string;
  formulable_id?: number;
  formulable_type?: FormulableType;
  composition_period_id: string;
  composition_period_kind: CompositionPeriodKindEnum
  subject_period_id?: string
  onSave: () => void;
  close_form?: () => void;
  composition_periods: CompositionPeriodAttributes[]
  subject_period_ids?: number[]
}) => {
  const { formula_id, formulable_id, formulable_type, onSave, close_form, composition_period_id, composition_periods, subject_period_id, composition_period_kind, subject_period_ids } = props;
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [initialValues, setInitialValues] = React.useState<Partial<FormulaFormData> | null>(null);
  const [loaded, setLoaded] = React.useState(false);

  const isUpdating = !isNil(initialValues?.id);

  const loadFormula = React.useCallback(async () => {
    if (formula_id) {
      const response = await dispatch(
        FETCH_FORMULA.request({
          id: formula_id,
          params: {
            filters: {
              include: ['operation'].join(','),
            },
          },
        }),
      );
      const {
        data: { included = [], data },
      } = response;
      const root_operation = find(
        included,
        (incl) =>
          incl.type === 'operations' && incl.attributes.parent_id === null && incl.attributes.formula_id === ~~data.id,
      ) as OperationJson;
      const formatted_root_operation = {
        id: root_operation.id,
        ...root_operation.attributes,
      };

      const formatted_data = {
        id: data.id,
        ...data.attributes,
        operation_attributes: { ...formatted_root_operation },
      };
      setInitialValues(formatted_data);
    } else {
      setInitialValues({
        formulable_id,
        formulable_type,
        threshold: 7,
        step: 1,
      });
    }
  }, [location]);

  const initFormulaForm = React.useCallback(async () => {
    await loadFormula();
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: FormulaFormData) => {
      const { operation_attributes, ...dataRest } = data;
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(dataRest, initialValues, [
            'id',
            'formulable_id',
            'formulable_type',
          ]) as FormulaAttributes;
          const updated_operation_attributes = operationUpdater(
            operation_attributes,
            initialValues?.operation_attributes as OperationAttributes,
          );
          await dispatch(
            UPDATE_FORMULA.request({
              id: initialValues?.id as string,
              data: {
                ...rest,
                operation_attributes: updated_operation_attributes,
              },
            }),
          );
        } else {
          await dispatch(
            CREATE_FORMULA.request({
              data: {
                ...data,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Fórmula salva com sucesso.',
          }),
        );
        await onSave();
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar fórmula.',
          }),
        );
      }
    },
    [initialValues, isUpdating, history],
  );

  React.useEffect(() => {
    initFormulaForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <FormulasForm
      composition_period_id={composition_period_id}
      subject_period_id={subject_period_id}
      composition_period_kind={composition_period_kind}
      initialValues={initialValues}
      close_form={close_form}
      onSubmit={onSubmit}
      composition_periods={composition_periods}
      subject_period_ids={subject_period_ids}
    />
  );
};

export default FormulasFormContainer;
