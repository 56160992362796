import React from 'react'
import ptBr from 'date-fns/locale/pt-BR'

import { makeStyles, DefaultTheme } from '@mui/styles'

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { IconButton, TextField, TextFieldProps } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import CloseIcon from '@mui/icons-material/Close'

import DateIcon from '../../icon/DateIcon'
import { colors } from '../../../utils/constants'

type Props = {
  disabled: boolean
  small: boolean
}


const useStyles = makeStyles<DefaultTheme, Props>(() => ({
  inputArea: {
    display: 'grid',
    '& span': {
      justifySelf: 'flex-start',
      color: colors.darkGrayBlue,
      fontSize: '0.8rem'
    },
    '& span.error': {
      color: colors.lightRed
    },
    '& span.error:empty:before': {
      content: '"\\200b"'
    },
    '& .wrapper':  {
      background: (props) => (props.disabled ? colors.lightBlue : 'white'),
      borderRadius: '4px',
      border: `1px solid ${colors.grayBlue}`,
      display: 'flex',
      width: '100%'
    },
    '& div.inputArea': {
      display: 'grid',
      justifyItems: 'flex-start',
      cursor: 'pointer',
      padding: props => props.small ? '0rem 0.75rem' : '0.5rem 0.75rem',
      position: 'relative',
      width: 'inherit !important',
      height: '2.5rem',
      '& input': {
        height: '100%'
      },
      '& label': {
        color: colors.darkGrayBlue,
        fontSize: '10px',
        whiteSpace: 'pre',
        textOverflow: 'ellipsis',
        textAlign: 'start'
      },
      '& svg': {
        position: 'absolute',
        right: '1rem',
        top: '25%',
        cursor: 'inherit',
        zIndex: '1'
      },
      '& ::placeholder': {
        color: colors.grayBlue,
        opacity: '1',
        fontSize: '16px'
      },
      '& span': {
        justifySelf: 'flex-start',
        fontSize: '16px',
        color: colors.darkBlue
      },
      '& .placeholder': {
        color: colors.grayBlue,
        fontSize: '16px'
      }
    },
    '& .clear': {
      '& svg': {
        color: colors.lightRed
      }
    },
  },
  muiPicker: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      width: '100%',
      '& .MuiOutlinedInput-input': {
        padding: 'unset',
        'font-family': 'Hauora, sans-serif',
        color: colors.darkBlue
      },
      '& .MuiInputAdornment-root': {
        position: 'absolute',
        right: '0',
        '& button svg': {
          position: 'initial'
        }
      },
      '& fieldset': {
        border: 'none'
      }
    }
  }
}))

const DatePickerComponent = (props: any) => {
  const { label, input, datePickerProps, placeholder, meta, small, clearFieldFunction, PickerComponent = DatePicker, ...rest } = props
  const classes = useStyles({
    disabled: input.disabled || rest.disabled,
    small
  })
  const inputRef = React.useRef<HTMLInputElement>()
  return (
    <div className={classes.inputArea} id={`input-${input.name}`}>
      <div className='wrapper'>
        <div className='inputArea' onClick={() => inputRef?.current?.focus()}>
            {!!label && <label> {label} </label>}
            <div className={classes.muiPicker}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBr}>
                <PickerComponent
                  clearable
                  inputVariant='outlined'
                  keyboardIcon={<DateIcon />}
                  clearText='Limpar'
                  components={{
                    OpenPickerIcon: DateIcon,
                  }}
                  autoOk
                  helperText=''
                  inputFormat='dd/MM/yyyy'
                  animateYearScrolling
                  inputProps={{
                    ref: inputRef,
                  }}
                  {...input}
                  value={input.value === '' ? null : input.value}
                  onChange={(date: Date) => {
                    input.onChange(date)
                  }}
                  onBlur={() => {
                    input.onBlur()
                  }}
                  renderInput={(props: TextFieldProps) => {
                    return <TextField data-testid={input.name} {...props} inputProps={{ ...props.inputProps, placeholder: placeholder }} />
                  }}
                  {...datePickerProps}
                />
              </LocalizationProvider>
            </div>
        </div>
        {input.value !== undefined && input.value !== '' && input.value !== null && clearFieldFunction && (
        <IconButton
          className='clear'
          onClick={(e) => {
            e.stopPropagation()
            clearFieldFunction()
          }}
        >
          <CloseIcon />
        </IconButton>            
        )}
      </div>
      <span className='error'>{meta && meta.error}</span>
    </div>
  )
}

export default DatePickerComponent
