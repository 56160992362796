/* eslint-disable no-lone-blocks */
import React from 'react'
import { makeStyles, DefaultTheme } from '@mui/styles'
import { colors } from '../../../utils/constants'
import { formatBytes } from '../../../utils/functions'

type Props = {
  disabled: boolean
}

const useStyles = makeStyles<DefaultTheme, Props>(() => ({
  inputArea: {
    display: 'grid',
    maxHeight: 'inherit',
    '& span': {
      justifySelf: 'flex-start',
      color: colors.darkGrayBlue,
      fontSize: '0.8rem'
    },
      '& label': {
        cursor: 'pointer',
        color: colors.darkBlue,
        position: 'absolute',
        maxHeight: 'inherit',
        height: '100%',
        width: '100%',
        left: '0',
        top: '0'
      },
      '& input': {
        maxHeight: 'inherit',
        border: 'none',
        outline: 'none',
        padding: '0.25rem',
        width: '100%',
        display: 'none'
      },
      '& .delete': {
        position: 'absolute',
        right: '1rem',
        cursor: 'pointer',
        bottom: '0.25rem',
        color: colors.lightRed,
        fontSize: '2.5rem',
        '&:hover':{
          color: 'red'
        }
      },
      '& ::placeholder': {
        color: colors.grayBlue,
        fontSize: '16px'
      },
    }
}))

const UploadComponent = (props: any) => {
  const { label, input, meta, clearField, ...rest } = props
  const { onChange, value, ...restInput } = input
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const classes = useStyles({
    disabled: input.disabled || rest.disabled
  })
  const [autoResetKey, setAutoResetKey] = React.useState(new Date().toISOString())

  return (
    <div className={classes.inputArea} id={`input-${input.name}`}>
      <div>
        <label
          htmlFor={input.name}
        />
        <input
          key={autoResetKey}
          data-testid={input.name}
          id={input.name}
          type='file'
          ref={inputRef}
          onChange={(e) => {
            const file =  !!e && !!e.target && !!e.target.files && e.target.files[0]
            if(file) {
              input.onChange({
                name: file.name,
                size: formatBytes(file.size),
                file
              })
            }
            setAutoResetKey(new Date().toISOString())
            input.onBlur()
          }}
          {...restInput}
          {...rest}
        />
      </div>
      {meta && meta.error && <span>{meta.error}</span>}
    </div>
  )
}

export default UploadComponent
