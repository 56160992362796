/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaHistory } from "react-icons/fa";
import { colors, RoomStudentAttributes, RoomAttributes } from '../../utils/constants';
import { format, parseISO } from 'date-fns';
import { map, orderBy } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { Circle } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import { RoomStudentJson } from '../../store/room_students';
import { FETCH_ROOM } from '../../store/rooms';
import PenIcon from '../icon/PenIcon';
import RoomStudentsFormContainer from '../../containers/RoomStudentsFormContainer';
import RoomStudentLogTable from './RoomStudentLogTable';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const RoomStudentsTable = ({ room }: { room: RoomAttributes }) => {
  const [editRoomStudent, setEditingRoomStudent] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);
  const [room_students, setRoomStudents] = React.useState<RoomStudentAttributes[]>([]);
  const [expandedStudentLog, setExpandedStudentLog] = React.useState<number[]>([]);
  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const fetchRoomStudents = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_ROOM.request({
          id: room.id,
          params: {
            filters: {
              include: 'room_students',
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      const room_students_attributes = map(
        included.filter((incl) => incl.type === 'room_students') as RoomStudentJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      setRoomStudents(room_students_attributes);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar periodos',
        }),
      );
      setLoading(false);
    }
  }, [room]);

  const init = async () => {
    await fetchRoomStudents();

  }
  React.useEffect(() => {
    init()
  }, []);

  if (loading){
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='subject-periods'>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell align='center'>Nome</TableCell>
          <TableCell align='center'>Sala</TableCell>
          <TableCell align='center'>Status</TableCell>
          <TableCell align='center'>Início</TableCell>
          <TableCell align='center'>Fim</TableCell>
          <TableCell align='right'>
            {' '}
            <button disabled={editRoomStudent.length > 0} onClick={() => setCreate(!create)} className='green small'>
              <span> Adicionar aluno</span>
            </button>
          </TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8} align='right'>
            <Collapse in={create} timeout='auto' unmountOnExit>
              <RoomStudentsFormContainer
                onSave={async () => {
                  await fetchRoomStudents();
                  setCreate(false);
                }}
                close_form={() => setCreate(false)}
                room={room}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        {orderBy(room_students, 'student_name').map((room_student) => {
          const isActive = room_student.active
          const isActiveLabel = isActive ? 'Ativo' : 'Inativo';
          const activeColorStyle = { color: isActive ? colors.green : colors.lightRed };
          const close_editing_form = () => {
            setEditingRoomStudent((current) => current.filter((item) => item !== ~~room_student.id));
          };
          return (
            <React.Fragment key={room_student.id}>
              <TableRow>
                <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Circle style={activeColorStyle} />
                    <span style={activeColorStyle}>{isActiveLabel}</span>
                  </div>
                </TableCell>
                <TableCell align='center'>{room_student.student_name}</TableCell>
                <TableCell align='center'>{room_student.room_name}</TableCell>
                <TableCell align='center'>{room_student.status_name}</TableCell>
                <TableCell align='center'>{format(parseISO(room_student.starts_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>{format(parseISO(room_student.ends_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='right'>
                  <TooltipButton
                    tooltipProps={{
                      title: expandedStudentLog.includes(~~room_student.id)
                        ? 'Ocultar log de alterações no registro do aluno'
                        : 'Ver log de alterações no registro do aluno',
                    }}
                    Icon={FaHistory}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedStudentLog((current) => {
                          if (current.includes(~~room_student.id)) {
                            return current.filter((item) => item !== ~~room_student.id);
                          } else {
                            return current.concat(~~room_student.id);
                          }
                        }),
                    }}
                  />
                  <TooltipButton
                    tooltipProps={{
                      title: editRoomStudent.includes(~~room_student.id)
                        ? 'Cancelar edição'
                        : 'Editar aluno',
                    }}
                    Icon={PenIcon}
                    iconButtonProps={{
                      disabled:
                        create || (editRoomStudent.length > 0 && !editRoomStudent.includes(~~room_student.id)),
                      onClick: () =>
                        setEditingRoomStudent((current) => {
                          if (current.includes(~~room_student.id)) {
                            return current.filter((item) => item !== ~~room_student.id);
                          } else {
                            return current.concat(~~room_student.id);
                          }
                        }),
                    }}
                  />{' '}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={editRoomStudent.includes(~~room_student.id)} timeout='auto' unmountOnExit>
                    <RoomStudentsFormContainer room_student_id={~~room_student.id} onSave={fetchRoomStudents} close_form={close_editing_form} room={room} />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={expandedStudentLog.includes(~~room_student.id)} timeout='auto' unmountOnExit>
                    <RoomStudentLogTable room_student_id={room_student.id} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default RoomStudentsTable;
