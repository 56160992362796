import { createPromiseAction } from "redux-saga-promise-actions"
import { ExamPlaceableType, JsonFormat, JsonResponseFormat, WithRequestParams } from "../../utils/constants"
import { AxiosResponse } from "axios"
import { OperationJson } from "../operations"

export interface ExamPlacementAttrs {
  exam_placeable_type: ExamPlaceableType
  exam_placeable_id: number
  name: string
  mandatory: boolean
  order: number
}

export type ExamPlacementJson = JsonFormat<'exam_placements', ExamPlacementAttrs>
export type FetchExamPlacementsResponse = JsonResponseFormat<ExamPlacementJson[], (OperationJson)[]>
export type FetchExamPlacementResponse = JsonResponseFormat<ExamPlacementJson, (OperationJson)[]>
export type FetchExamPlacementsPayload = WithRequestParams

export interface FetchExamPlacementPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateExamPlacementPayload {
  data: object
}

export interface UpdateExamPlacementPayload extends CreateExamPlacementPayload {
  id: string
}

export interface DeleteExamPlacementPayload {
  id: string
}


export const FETCH_EXAM_PLACEMENTS = createPromiseAction('exam_placements: FETCH_EXAM_PLACEMENTS')<FetchExamPlacementsPayload, FetchExamPlacementsResponse, any>()
export const FETCH_EXAM_PLACEMENT  = createPromiseAction('exam_placements: FETCH_EXAM_PLACEMENT')<FetchExamPlacementPayload, FetchExamPlacementResponse, any>()
export const CREATE_EXAM_PLACEMENT = createPromiseAction('exam_placements: CREATE_EXAM_PLACEMENTS')<CreateExamPlacementPayload, FetchExamPlacementResponse, any>()
export const UPDATE_EXAM_PLACEMENT = createPromiseAction('exam_placements: UPDATE_EXAM_PLACEMENTS')<UpdateExamPlacementPayload, FetchExamPlacementResponse, any>()
export const DELETE_EXAM_PLACEMENT = createPromiseAction('exam_placements: DELETE_EXAM_PLACEMENTS')<DeleteExamPlacementPayload, AxiosResponse, any>()