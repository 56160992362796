/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { compose } from 'redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { DefaultOptionType, Role, validation, RoomFormAttributes } from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { useDispatch, useSelector } from 'react-redux';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import AutoCompleteComponent from '../input/form/autocomplete';
import { find, isEmpty } from 'lodash';
import { FETCH_PRODUCTS } from '../../store/products';
import { error, warning } from 'react-notification-system-redux';
import { KtwelveCurriculumJson } from '../../store/ktwelve_curriculums';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      paddingBottom: '1 rem 0',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const form_name = 'roomForm';

interface CustomProductOption extends DefaultOptionType {
  ktwelve_curriculum_id?: number
}

const RoomForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: RoomFormAttributes;
  close_form: () => void;
}) => {
  const { handleSubmit, onSubmit, initialValues, close_form } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [productOptions, setProductOptions] = React.useState<CustomProductOption[]>([]);
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector(form_name);
  const companyIdValue = formValues(state, 'company_id') as string;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);

  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_director && !initialValues?.id;

  const fetchProductOptionsMethod = React.useCallback(
    async (value: string) => {
      try {
        setLoading(true);
        const products = await dispatch(
          FETCH_PRODUCTS.request({
            params: {
              filters: {
                'q[name_start]': value,
                'q[company_id_eq]': companyIdValue,
                'q[product_family_roomable_eq]': 'true',
                'include': 'ktwelve_curriculums'
              },
            },
          }),
        );
        if (products) {
          const {
            data: { data, included },
          } = products;
          const formattedData = data.reduce((acc, {attributes, id}) => {
            const ktwelve_curriculum = find(included, incl => incl.type === 'ktwelve_curriculums' && incl.attributes.year === attributes.year && incl.attributes.ktwelve_degree_id === attributes.ktwelve_degree_id) as KtwelveCurriculumJson

            return acc.concat({
              label: attributes.name,
              value: id,
              ...(ktwelve_curriculum ? {ktwelve_curriculum_id: ~~ktwelve_curriculum.id} : {})
            })
          }, [] as CustomProductOption[])
          setProductOptions(formattedData);
          setLoading(false);
        }
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de salas',
          }),
        );
      }
    },
    [companyIdValue],
  );

  const handleSelectProduct = (e: React.ChangeEvent<any> | undefined, value: string) => {
    if (value) {
      const found_product = find(productOptions, opt => opt.value === value)
      if(!found_product?.ktwelve_curriculum_id) {
        dispatch(
          warning(
            {
              message: 'Não é possível adicionar o produto selecionado pois a série ainda não está inserida em uma matriz curricular'
            }
          )
        )
        e?.preventDefault()
      } else {
        dispatch(
          change(
            form_name, 'ktwelve_curriculum_id', found_product.ktwelve_curriculum_id
          )
        )
      }
    }
  };

  React.useEffect(() => {
    if (initialValues.id) {
      setProductOptions([
        {
          label: initialValues.product_name,
          value: initialValues.product_id.toString(),
        },
      ]);
    }
  }, []);
  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar Sala</span>
      <form className={classes.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <Field
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}

        {companyIdValue && (
          <>
            <div style={{ display: 'grid', gridTemplateColumns: '33% 33% 33%', justifyContent: 'space-between' }}>
              <Field
                name='name'
                component={InputComponent}
                label={'Nome'}
                placeholder={'Insira o nome da sala'}
                validate={[validation.required]}
              />
              <Field
                name='product_id'
                label={'Produto'}
                placeholder={'Produto'}
                component={AutoCompleteComponent}
                onChange={handleSelectProduct}
                autoCompleteProps={{
                  disabled: !isEmpty(initialValues.product_id),
                  filterOptions: (x: any) => x,
                  loading,
                  getValueOnChange: true,
                  fetchOptions: fetchProductOptionsMethod,
                }}
                options={productOptions}
                validate={[validation.required]}
              />
            </div>
            <div className={classes.buttons}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  close_form();
                }}
                className='red small'
              >
                <span> Cancelar </span>
              </button>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar sala </span>
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(RoomForm);
