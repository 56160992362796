import { createPromiseAction } from "redux-saga-promise-actions"
import { JsonFormat, JsonResponseFormat, WithRequestParams } from "../../utils/constants"
import { AxiosResponse } from "axios"
import { KtwelveSubjectJson } from "../ktwelve_subjects"
import { CompositionJson } from "../compositions"
import { CompositionPeriodJson } from "../composition_periods"
import { ExamPlacementJson } from "../exam_placements"

export interface KtwelveCurriculumRegistrationRegistrationAttrs {
  registration_id: number
  ktwelve_curriculum_id: number
}

export type KtwelveCurriculumRegistrationJson = JsonFormat<'ktwelve_curriculum_registrations', KtwelveCurriculumRegistrationRegistrationAttrs>
export type FetchKtwelveCurriculumRegistrationsResponse = JsonResponseFormat<KtwelveCurriculumRegistrationJson, (KtwelveSubjectJson|CompositionJson|CompositionPeriodJson|ExamPlacementJson)[]>
export type FetchKtwelveCurriculumRegistrationResponse = JsonResponseFormat<KtwelveCurriculumRegistrationJson, (KtwelveSubjectJson|CompositionJson|CompositionPeriodJson|ExamPlacementJson)[]>
export type FetchKtwelveCurriculumRegistrationsPayload = WithRequestParams

export interface FetchKtwelveCurriculumRegistrationPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateKtwelveCurriculumRegistrationPayload {
  data: object
}

export interface UpdateKtwelveCurriculumRegistrationPayload extends CreateKtwelveCurriculumRegistrationPayload {
  id: string
}

export interface DeleteKtwelveCurriculumRegistrationPayload {
  id: string
}


export const FETCH_KTWELVE_CURRICULUM_REGISTRATIONS = createPromiseAction('ktwelve_curriculum_registration: FETCH_KTWELVE_CURRICULUM_REGISTRATIONS')<FetchKtwelveCurriculumRegistrationsPayload, FetchKtwelveCurriculumRegistrationsResponse, any>()
export const FETCH_KTWELVE_CURRICULUM_REGISTRATION  = createPromiseAction('ktwelve_curriculum_registration: FETCH_KTWELVE_CURRICULUM_REGISTRATION')<FetchKtwelveCurriculumRegistrationPayload, FetchKtwelveCurriculumRegistrationResponse, any>()
export const CREATE_KTWELVE_CURRICULUM_REGISTRATION = createPromiseAction('ktwelve_curriculum_registration: CREATE_KTWELVE_CURRICULUM_REGISTRATION')<CreateKtwelveCurriculumRegistrationPayload, FetchKtwelveCurriculumRegistrationResponse, any>()
export const UPDATE_KTWELVE_CURRICULUM_REGISTRATION = createPromiseAction('ktwelve_curriculum_registration: UPDATE_KTWELVE_CURRICULUM_REGISTRATION')<UpdateKtwelveCurriculumRegistrationPayload, FetchKtwelveCurriculumRegistrationResponse, any>()
export const DELETE_KTWELVE_CURRICULUM_REGISTRATION = createPromiseAction('ktwelve_curriculum_registration: DELETE_KTWELVE_CURRICULUm')<DeleteKtwelveCurriculumRegistrationPayload, AxiosResponse, any>()
