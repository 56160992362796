/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reduxForm,
  Field,
  FieldArray,
  WrappedFieldArrayProps,
  FormSection,
  change,
  formValueSelector,
  GenericField,
  untouch,
} from 'redux-form';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ExpandMore } from '@mui/icons-material';
import { debounce, find, get, head, isEmpty, isNil, toString } from 'lodash';

import {
  colors,
  DefaultOptionType,
  DiscountTypes,
  DocumentRequirementsNestedAttributes,
  LocationState,
  monetaryValueMaskProps,
  Paths,
  PaymentOptionsAttributes,
  ProductPeriodsNestedAttributes,
  ProductsFormAttributes,
  productTypeOptions,
  SubsidiesNestedAttributes,
  validation,
  PaymentOptionsNestedAttributes,
  Role,
  ProductFamilyAttributes,
  ProductTypesEnum,
  SubsidyAttributes,
  RoomsNestedAttributes,
} from '../../utils/constants';
import SelectComponent from '../input/form/select';
import InputComponent from '../input/form/input';
import TextAreaComponent from '../input/form/textarea';
import MaskedInputComponent from '../input/form/masked';
import {
  dateRange,
  evaluate_permissions,
  getCompanyFilterOptions,
  getMonthInteger,
  parseMonetaryValue,
  resetFields,
} from '../../utils/functions';
import TabPanel from '../shared/TabPanel';
import AddButton from '../shared/AddButton';
import PaymentOptionsForm from './PaymentOptionForm';
import SubsidyForm from './SubsidyForm';
import ProductPeriodForm from './ProductPeriodForm';
import DocumentRequirementForm from './DocumentRequirementForm';
import AutoCompleteComponent from '../input/form/autocomplete';
import { RootState } from '../../store/configureStore';
import { isBefore } from 'date-fns';
import DeleteButton from '../shared/DeleteButton';
import DatePickerComponent from '../input/form/datepicker';
interface CompanyIdCustomProps {
  label: string;
  options: DefaultOptionType[];
  placeholder: string;
}

const FieldCustom = Field as new () => GenericField<CompanyIdCustomProps>;

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    tabStyle: any;
    accordion: any;
    accordionSummary: any;
    buttons: any;
    formBlock: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      padding: '2rem 10%',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
    },
    tabStyle: {
      '& .Mui-selected': {
        background: '#FDFEFF',
        borderRadius: '1rem 1rem 0 0',
      },
    },
    accordion: {
      background: 'inherit',
      'box-shadow': 'none',
      'border-bottom': `1px solid ${colors.grayBlue}`,
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiAccordionSummary-content': {
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'inherit',
      },
    },
    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
    formBlock: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'rgb(255,255,255, 0.5)',
      pointerEvents: 'none',
    },
  }),
);

const renderProductPeriodsFormFields = (props: WrappedFieldArrayProps) => {
  const { fields } = props;
  const dispatch = useDispatch();

  const deleteProductPeriodForm = React.useCallback(
    (currentPeriod: ProductPeriodsNestedAttributes, period: string, index: number) => {
      currentPeriod?.id ? dispatch(change('productForm', `${period}._destroy`, true)) : fields.remove(index);
    },
    [fields],
  );

  const addPeriod = React.useCallback(() => {
    const lastPeriod = fields.get(fields.length - 1);
    const newStartsAt = !isEmpty(lastPeriod) && !isNil(lastPeriod) ? lastPeriod.ends_at : new Date();
    fields.push({
      starts_at: newStartsAt,
    });
  }, [fields]);
  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar período</span>
        <AddButton onClick={addPeriod} />
      </div>
      {fields.map((period, index, fields) => {
        const lastPeriod: ProductPeriodsNestedAttributes = fields.get(index - 1);
        const currentPeriod: ProductPeriodsNestedAttributes = fields.get(index);
        const nextPeriod: ProductPeriodsNestedAttributes = fields.get(index + 1);
        const starts_at_limits = {
          start: !isEmpty(lastPeriod) && !isNil(lastPeriod) ? lastPeriod.ends_at : '',
          end: currentPeriod?.ends_at
            ? currentPeriod.ends_at
            : !isEmpty(nextPeriod) && !isNil(nextPeriod)
            ? nextPeriod.ends_at
            : '',
        };

        const ends_at_limits = {
          start: currentPeriod?.starts_at ? currentPeriod.starts_at : '',
          end: !isEmpty(nextPeriod) && !isNil(nextPeriod) ? nextPeriod.ends_at : '',
        };
        const wasDestroyed = currentPeriod?._destroy;
        if (wasDestroyed) {
          return null;
        }
        return (
          <FormSection key={period} name={period}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ProductPeriodForm starts_at_limits={starts_at_limits} ends_at_limits={ends_at_limits} />
              <DeleteButton
                tooltip='Excluir período'
                onClick={() => {
                  deleteProductPeriodForm(currentPeriod, period, index);
                }}
              />
            </div>
          </FormSection>
        );
      })}
    </div>
  );
};

interface CustomDocumentRequirementProps extends WrappedFieldArrayProps {
  documentOptions: DefaultOptionType[];
}

const renderDocumentRequirementsFormFields = (props: CustomDocumentRequirementProps) => {
  const { fields, documentOptions } = props;
  const dispatch = useDispatch();
  const addDocRequirement = React.useCallback(() => {
    fields.push({});
  }, [fields]);

  const deleteDocRequirement = React.useCallback(
    (currentDocReq: DocumentRequirementsNestedAttributes, documentReq: string, index: number) => {
      currentDocReq?.id ? dispatch(change('productForm', `${documentReq}._destroy`, true)) : fields.remove(index);
    },
    [fields],
  );
  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar documentos de matrícula</span>
        <AddButton onClick={addDocRequirement} />
      </div>
      {fields.map((documentReq, index, fields) => {
        const currentDocReq: DocumentRequirementsNestedAttributes = fields.get(index);
        const wasDestroyed = currentDocReq._destroy;
        if (wasDestroyed) {
          return null;
        }
        return (
          <FormSection key={documentReq} name={documentReq}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DocumentRequirementForm documentOptions={documentOptions} />
              <DeleteButton
                onClick={() => {
                  deleteDocRequirement(currentDocReq, documentReq, index);
                }}
              />
            </div>
          </FormSection>
        );
      })}
    </div>
  );
};

const renderRoomsFormFields = (props: WrappedFieldArrayProps) => {
  const { fields  } = props;
  const dispatch = useDispatch();
  const addRoom = React.useCallback(() => {
    fields.push({});
  }, [fields]);

  const deleteRoom = React.useCallback(
    (currentRoom: RoomsNestedAttributes, room: string, index: number) => {
      currentRoom?.id ? dispatch(change('productForm', `${room}._destroy`, true)) : fields.remove(index);
    },
    [fields],
  );
  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar sala</span>
        <AddButton onClick={addRoom} />
      </div>
      {fields.map((room, index, fields) => {
        const currentRoom: RoomsNestedAttributes = fields.get(index);
        const wasDestroyed = currentRoom._destroy;
        if (wasDestroyed) {
          return null;
        }
        return (
          <FormSection key={room} name={room}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Field
                name='name'
                component={InputComponent}
                label={'Nome'}
                placeholder={'Insira o nome da sala'}
                validate={[validation.required]}
              />
              <DeleteButton
                onClick={() => {
                  deleteRoom(currentRoom, room, index);
                }}
              />
            </div>
          </FormSection>
        );
      })}
    </div>
  );
};

const PaymentOptionFormAccordion = ({
  paymentOptionAttr,
  currentPaymentOptionAttr,
  deletePaymentOptionMethod,
  index,
  periodsAttributes,
  registrationFee,
}: {
  paymentOptionAttr: string;
  currentPaymentOptionAttr: PaymentOptionsNestedAttributes;
  deletePaymentOptionMethod: (e: React.MouseEvent<HTMLButtonElement>) => void;
  index: number;
  periodsAttributes: ProductPeriodsNestedAttributes[];
  registrationFee: string;
}) => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile },
  } = state;
  const name = currentPaymentOptionAttr?.name || 'Nova opção de pagamento ' + index;
  const wasDestroyed = currentPaymentOptionAttr._destroy;
  const disablePortions =
    currentPaymentOptionAttr && currentPaymentOptionAttr.created_at
      ? isBefore(new Date(currentPaymentOptionAttr.created_at), new Date('10-27-2022'))
      : false;

  if (wasDestroyed) {
    return null;
  }
  return (
    <Accordion key={paymentOptionAttr} className={classes.accordion} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary className={classes.accordionSummary}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ExpandMore />
          <span>{name}</span>
        </div>
        {evaluate_permissions.is_school_admin_or_above(profile.role) && (
          <DeleteButton onClick={deletePaymentOptionMethod} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <FormSection name={paymentOptionAttr}>
          <PaymentOptionsForm
            currentPaymentOption={currentPaymentOptionAttr}
            paymentOptionPrefix={paymentOptionAttr}
            disablePortions={disablePortions}
            periodsAttributes={periodsAttributes}
            registrationFee={registrationFee}
          />
        </FormSection>
      </AccordionDetails>
    </Accordion>
  );
};

interface CustomPaymentOptionProps extends WrappedFieldArrayProps {
  periodsAttributes: ProductPeriodsNestedAttributes[];
  registrationFee: string;
}

const renderPaymentOptionsFormFields = (props: CustomPaymentOptionProps) => {
  const { fields, periodsAttributes, registrationFee } = props;
  const dispatch = useDispatch();
  const {
    auth: { profile },
  } = useSelector((state: RootState) => state);
  const addPaymentOption = React.useCallback(() => {
    fields.push({
      advance_discount_value: 0,
      portion_discount_value: 0,
      punctuality_discount_value: 0,
    });
  }, [fields]);
  const deletePaymentOption = React.useCallback(
    (currentPaymentOption: PaymentOptionsAttributes, paymentOption: string, index: number) => {
      currentPaymentOption?.id
        ? dispatch(change('productForm', `${paymentOption}._destroy`, true))
        : fields.remove(index);
    },
    [fields],
  );
  return (
    <div>
      {evaluate_permissions.is_school_admin_or_above(profile.role) && (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          <span>Adicionar opção de pagamento</span>
          <AddButton onClick={addPaymentOption} />
        </div>
      )}
      {fields.map((paymentOptionAttr, index, fields) => {
        const currentPaymentOptionAttr: PaymentOptionsNestedAttributes = fields.get(index);
        const deletePaymentOptionMethod = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          deletePaymentOption(currentPaymentOptionAttr, paymentOptionAttr, index);
        };
        return (
          <PaymentOptionFormAccordion
            key={paymentOptionAttr}
            deletePaymentOptionMethod={deletePaymentOptionMethod}
            currentPaymentOptionAttr={currentPaymentOptionAttr}
            paymentOptionAttr={paymentOptionAttr}
            periodsAttributes={periodsAttributes}
            index={index}
            registrationFee={registrationFee}
          />
        );
      })}
    </div>
  );
};

interface CustomSubsidyFormProps {
  discountTypesOptions: DefaultOptionType[];
}

const SubsidyFormAccordion = ({
  subsidy,
  subsidyKind,
  deleteSubsidy,
  monthsRange,
  discountTypesOptions,
  name,
  current_subsidy
}: {
  subsidy: string;
  deleteSubsidy: (e: React.MouseEvent<HTMLButtonElement>) => void;
  monthsRange: number[];
  subsidyKind: DiscountTypes;
  discountTypesOptions: DefaultOptionType[];
  name: string;
  current_subsidy: SubsidyAttributes
}) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.accordion} key={subsidy} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary className={classes.accordionSummary}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ExpandMore />
          <span>{name}</span>
        </div>
        <DeleteButton onClick={deleteSubsidy} />
      </AccordionSummary>
      <AccordionDetails>
        <FormSection name={subsidy}>
          <SubsidyForm
            current_subsidy={current_subsidy}
            monthsRange={monthsRange}
            subsidyKind={subsidyKind}
            discountTypesOptions={discountTypesOptions}
          />
        </FormSection>
      </AccordionDetails>
    </Accordion>
  );
};

const renderSubsidiesFormFields = (props: WrappedFieldArrayProps & CustomSubsidyFormProps) => {
  const { fields, discountTypesOptions } = props;
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const formValues = formValueSelector('productForm');
  const periodsAttributes = formValues(state, 'periods_attributes') as ProductPeriodsNestedAttributes[];
  const activePeriod = head(periodsAttributes) as ProductPeriodsNestedAttributes;
  const startingRange = [0];
  const monthsRange = startingRange.concat(
    activePeriod ? dateRange(activePeriod.starts_at, activePeriod?.ends_at).map((item) => getMonthInteger(item)) : [],
  );

  const addSubsidy = React.useCallback(() => {
    fields.push({});
  }, [fields]);

  const deleteSubsidyMethod = React.useCallback(
    (currentSubsidy: SubsidiesNestedAttributes, subsidy: string, index: number) => {
      currentSubsidy.id ? dispatch(change('productForm', `${subsidy}._destroy`, true)) : fields.remove(index);
    },
    [fields],
  );
  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar desconto comercial</span>
        <AddButton onClick={addSubsidy} />
      </div>
      {fields.map((subsidy, index, fields) => {
        const currentSubsidyAttr: SubsidiesNestedAttributes = fields.get(index);
        const name = currentSubsidyAttr?.name || 'Novo desconto comercial';
        const subsidyKind = currentSubsidyAttr.kind;
        const wasDestroyed = currentSubsidyAttr._destroy;
        const deleteSubsidy = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          deleteSubsidyMethod(currentSubsidyAttr, subsidy, index);
        };
        if (wasDestroyed) {
          return null;
        }
        return (
          <SubsidyFormAccordion
            key={subsidy}
            current_subsidy={currentSubsidyAttr}
            name={name}
            subsidy={subsidy}
            subsidyKind={subsidyKind}
            deleteSubsidy={deleteSubsidy}
            monthsRange={monthsRange}
            discountTypesOptions={discountTypesOptions}
          />
        );
      })}
    </div>
  );
};

const ProductForm = ({
  classroomOptions,
  productFamilyOptions,
  contractSettingOptions,
  loadClassroomOptions,
  clearClassroomField,
}: {
  loadClassroomOptions: ({ name }: { name?: string | undefined }) => Promise<void>;
  classroomOptions: DefaultOptionType[];
  productFamilyOptions: ProductFamilyAttributes[];
  contractSettingOptions: DefaultOptionType[];
  clearClassroomField: () => void;
}) => {
  const dispatch = useDispatch()
  const formValues = formValueSelector('productForm');
  const state = useSelector((state: RootState) => state)

  const productFamilyValue = formValues(state, 'product_family_id') as string;
  const sale_starts_at = formValues(state, 'sale_starts_at') as string;
  const sale_ends_at = formValues(state, 'sale_ends_at') as string;
  const currentProductFamily = find(productFamilyOptions, product_family => toString(get(product_family, 'id')) === toString(productFamilyValue)) as ProductFamilyAttributes
  const invoices_through_product = currentProductFamily?.invoices_through_product

  const handleProductFamilyChange = (_: React.ChangeEvent<any> | undefined, value: any) => {
    const chosenProductFamily = find(productFamilyOptions, product_family => toString(get(product_family, 'id')) === toString(value)) as ProductFamilyAttributes

    if (chosenProductFamily.invoices_through_product) {
    resetFields('productForm', ['classroom_id', 'contract_setting_id'], dispatch, change, untouch)
     dispatch(change(
      'productForm', 'kind', ProductTypesEnum.OTHER
     ))
    }
  };
  const debouncedLoadClassroomOptions = React.useCallback((_: any, value: string, reason: string) => {
    const debounceFunc = debounce(() => {
      loadClassroomOptions({ name: value });
    }, 1000);
    if (value && reason === 'input') {
      debounceFunc();
    }
  }, []);
  const formattedProductFamilyOptions = productFamilyOptions.map(item => {
    return({
      value: item.id,
      label: item.name
    })
  })
  return (
    <>
      <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
        <Field
          name='product_family_id'
          component={SelectComponent}
          options={formattedProductFamilyOptions}
          label={'Família de produtos'}
          placeholder={'Família de produtos'}
          onChange={handleProductFamilyChange}
          validate={[validation.required]}
        />
        <Field
          name='contract_setting_id'
          component={SelectComponent}
          options={contractSettingOptions}
          label={'Configuração do contrato'}
          placeholder={'Configuração do contrato'}
          disabled={invoices_through_product}
        />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '32% 32% 32%', justifyContent: 'space-between' }}>
        <Field
          name={`classroom_id`}
          component={AutoCompleteComponent}
          label={'Turma'}
          placeholder={'Selecione a turma do produto'}
          options={classroomOptions}
          autoCompleteProps={{
            getValueOnChange: true,
            valueKey: 'label',
            showDropdownIcon: true,
            clearable: true,
            onClearField: clearClassroomField,
            onInputChange: debouncedLoadClassroomOptions,
          }}
          disabled={invoices_through_product}
        />
        <Field
          name='kind'
          component={SelectComponent}
          options={productTypeOptions}
          label={'Tipo de produto'}
          placeholder={'Selecione o tipo do produto'}
          validate={[validation.required]}
          disabled={invoices_through_product}
        />
        <Field
          label='Ano letivo'
          component={InputComponent}
          name='year'
          onlyNumbers
          placeholder={'Insira o ano letivo do produto'}
          validate={[validation.required]}
        />
      </div>
      <Field
        name='name'
        component={InputComponent}
        label={'Nome'}
        placeholder={'Insira o nome do produto'}
        validate={[validation.required]}
      />
      <Field
        name='description'
        component={TextAreaComponent}
        label={'Descrição'}
        placeholder={'Insira a descrição do produto'}
        validate={[validation.required]}
      />
      <div style={{ display: 'grid', gridTemplateColumns: '32% 32% 32%', justifyContent: 'space-between' }}>
        <Field
          name='quantity'
          component={InputComponent}
          label={'Quantidade'}
          onlyNumbers
          placeholder={'Quantidade de vagas'}
          validate={[validation.required]}
        />
        <Field
          name='price'
          component={MaskedInputComponent}
          label={'Valor (Mês)'}
          parse={parseMonetaryValue}
          maskProps={monetaryValueMaskProps}
          placeholder={'Insira o valor mensal'}
          validate={[validation.required]}
        />
        <Field
          name='registration_fee'
          component={MaskedInputComponent}
          label={'Entrada'}
          parse={parseMonetaryValue}
          maskProps={monetaryValueMaskProps}
          placeholder={'Insira o valor da entrada'}
        />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
        <Field
          name={`sale_starts_at`}
          label={'Aberto a venda a partir de:'}
          placeholder={'01/01/2021'}
          component={DatePickerComponent}
          datePickerProps={{
              ...(sale_ends_at ? {maxDate:sale_ends_at } : {} ),
              showDropdownIcon: true,
          }}
        />
        <Field
          name={`sale_ends_at`}
          label={'Aberto a venda até:'}
          placeholder={'01/01/2021'}
          component={DatePickerComponent}
          datePickerProps={{
            ...(sale_starts_at ? {minDate: sale_starts_at } : {} ),
            showDropdownIcon: true,
          }}
        />
      </div>

    </>
  );
};

const ProductFormBody = ({
  classroomOptions,
  productFamilyOptions,
  contractSettingOptions,
  discountTypesOptions,
  documentOptions,
  handleSubmit,
  onSubmit,
  initialValues,
  loadClassroomOptions,
  clearClassroomField,
  periodsAttributes,
  registrationFee,
  initProductsForm,
}: {
  classroomOptions: DefaultOptionType[];
  productFamilyOptions: ProductFamilyAttributes[];
  contractSettingOptions: DefaultOptionType[];
  discountTypesOptions: DefaultOptionType[];
  documentOptions: DefaultOptionType[];
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: ProductsFormAttributes;
  loadClassroomOptions: ({ name }: { name?: string | undefined }) => Promise<void>;
  clearClassroomField: () => void;
  periodsAttributes: ProductPeriodsNestedAttributes[];
  registrationFee: string;
  initProductsForm: (company_id?: any) => Promise<void>;
}) => {
  const [tab, setTab] = React.useState('product');
  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector('productForm');
  const companyIdValue = formValues(state, 'company_id') as string;
  const productFamilyIdValue = formValues(state, 'product_family_id') as string;


  const location = useLocation<LocationState>();
  const classes = useStyles();
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  const currentProductName = initialValues?.name;
  const current_product_family = find(productFamilyOptions, pfo => pfo.id === productFamilyIdValue)
  const is_roomable = current_product_family?.roomable
  const isCopy = location?.state?.copy;
  const title =
    isCopy && currentProductName
      ? `Copiar ${currentProductName}`
      : currentProductName
      ? `Editar ${currentProductName}`
      : 'Salvar produto';
  const mustSelectCompanyId = is_above_school_director && !initialValues?.id;
  const handleCompanyChange = (_: React.ChangeEvent<any> | undefined, value: any) => {
    if (value) {
      initProductsForm(value);
    }
  };
  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>{title}</span>
      {mustSelectCompanyId && (
        <>
          <span style={{ alignSelf: 'self-start' }} className='subtitle-one'>
            {' '}
            Unidade{' '}
          </span>
          <FieldCustom
            label={'Unidade'}
            placeholder={'Selecionar unidade'}
            name='company_id'
            component={SelectComponent}
            options={companies_options as DefaultOptionType[]}
            onChange={handleCompanyChange}
            validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
          />
        </>
      )}
      {companyIdValue && (
        <div>
          <Tabs className={classes.tabStyle} value={tab} onChange={handleTabChange}>
            <Tab label='Produto' value={'product'} />
            <Tab label='Períodos' value={'product_periods'} />
            <Tab label='Descontos comerciais' value={'subsidies'} />
            <Tab label='Opções de pagamento' value='payment_options' />
            <Tab label='Documentos da matrícula' value={'document_requirements'} />
            {is_roomable && <Tab label='Salas' value='rooms' />}
          </Tabs>
          <form className={classes.form}>
            <TabPanel value={tab} index={'product'}>
              <ProductForm
                clearClassroomField={clearClassroomField}
                loadClassroomOptions={loadClassroomOptions}
                classroomOptions={classroomOptions}
                productFamilyOptions={productFamilyOptions}
                contractSettingOptions={contractSettingOptions}
              />
            </TabPanel>
            <TabPanel value={tab} index={'product_periods'}>
              <FieldArray name='periods_attributes' component={renderProductPeriodsFormFields} />
            </TabPanel>
            <TabPanel value={tab} index={'payment_options'}>
              <FieldArray
                name='payment_options_attributes'
                component={renderPaymentOptionsFormFields}
                periodsAttributes={periodsAttributes}
                registrationFee={registrationFee}
              />
            </TabPanel>
            <TabPanel value={tab} index={'subsidies'}>
              <FieldArray
                name='subsidies_attributes'
                component={renderSubsidiesFormFields}
                discountTypesOptions={discountTypesOptions}
              />
            </TabPanel>
            <TabPanel value={tab} index={'document_requirements'}>
              <FieldArray
                name='document_requirements_attributes'
                component={renderDocumentRequirementsFormFields}
                documentOptions={documentOptions}
              />
            </TabPanel>
            <TabPanel value={tab} index={'rooms'}>
              <FieldArray
                name='rooms_attributes'
                component={renderRoomsFormFields}
              />
            </TabPanel>
            <div className={classes.buttons}>
              <Link to={Paths.PRODUCTS_LIST} className='red small'>
                <span> Cancelar </span>
              </Link>
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar produto </span>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'productForm',
  }),
)(ProductFormBody);
