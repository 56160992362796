/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { ClassTimeFormAttributes, ClassTimeKindEnum, JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'
import { ClassTimeSubjectJson } from '../class_time_subjects'
import { TeacherClassTimeJson } from '../teacher_class_times'
import { RoomClassTimeJson } from '../room_class_times'
import { StudentAbsenceJson } from '../student_absences'
import { RoomStudentJson } from '../room_students'

export interface ClassTimeAttrs {
  company_id: number
  starts_at: string
  ends_at: string
  location: string
  kind: ClassTimeKindEnum
  active: boolean
}
export type ClassTimeJson = JsonFormat<'class_times', ClassTimeAttrs>

export type FetchClassTimesResponse = JsonResponseFormat<ClassTimeJson[], (
  ClassTimeSubjectJson | TeacherClassTimeJson | RoomClassTimeJson | StudentAbsenceJson | RoomStudentJson)[]>
export type FetchClassTimeResponse = JsonResponseFormat<ClassTimeJson, (ClassTimeSubjectJson | TeacherClassTimeJson|RoomClassTimeJson)[]>

export type FetchClassTimesPayload = WithRequestParams
export interface FetchClassTimePayload extends WithRequestParams {
  id: string | number,
}
export interface CreateClassTimePayload {
  data: Partial<ClassTimeFormAttributes>
}

export interface UpdateClassTimePayload extends CreateClassTimePayload {
  id: string
}

export interface DeleteClassTimePayload {
  id: string
}

export const FETCH_CLASS_TIMES = createPromiseAction('class_times: FETCH_CLASS_TIMES')<FetchClassTimesPayload, FetchClassTimesResponse, any>()
export const FETCH_CLASS_TIME = createPromiseAction('class_times: FETCH_CLASS_TIME')<FetchClassTimePayload, FetchClassTimeResponse, any>()
export const CREATE_CLASS_TIME = createPromiseAction('class_times: CREATE_CLASS_TIME')<CreateClassTimePayload, FetchClassTimeResponse, any>()
export const UPDATE_CLASS_TIME = createPromiseAction('class_times: UPDATE_CLASS_TIME')<UpdateClassTimePayload, FetchClassTimeResponse, any>()
export const DELETE_CLASS_TIME = createPromiseAction('class_times: DELETE_CLASS_TIME')<DeleteClassTimePayload, AxiosResponse, any>()
