import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const subjectPeriodRegistrationsEndpoint = '/subject_period_registrations'

export const fetchSubjectPeriodRegistrations = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subjectPeriodRegistrationsEndpoint}?${queryParams}`)
}

export const fetchSubjectPeriodRegistration = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subjectPeriodRegistrationsEndpoint}/${id}?${queryParams}`)
}

export const createSubjectPeriodRegistration = (data: object) => {
  return http.post(`${subjectPeriodRegistrationsEndpoint}`, data)
}

export const updateSubjectPeriodRegistration = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${subjectPeriodRegistrationsEndpoint}/${id}`, data)
}

export const deleteSubjectPeriodRegistration = ({ id }: { id: string | number } ) => {
  return http.delete(`${subjectPeriodRegistrationsEndpoint}/${id}`)
}
