/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, ProductsFormAttributes, ProductTypes, WithRequestParams } from '../../utils/constants'
import { ClassroomJson } from '../classrooms'
import { CourseJson } from '../courses'
import { KtwelveJson } from '../ktwelves'
import { PaymentCollectionJson, PaymentOptionJson } from '../payment_options'
import { DocumentRequirementJson } from '../registrations'
import { SubsidyJson } from '../subsidies'
import { CompanyJson } from '../companies'
import { KtwelveCurriculumJson } from '../ktwelve_curriculums'

export interface ProductPeriodAttrs {
  product_id: number
  starts_at: string
  ends_at: string
}
export interface ProductAttrs {
  available: number
  classroom_id: number
  company_id: number
  description: string
  full_price: number
  invoices_through_product: boolean
  kind: ProductTypes
  name: string
  price: number
  product_family_id: number
  quantity: number
  registration_fee: number
  sale_ends_at: string
  sale_starts_at: string
  year: number
  ktwelve_degree_id: number
}


export type ProductPeriodJson = JsonFormat<'product_periods', ProductPeriodAttrs>
export type ProductJson = JsonFormat<'products', ProductAttrs>
export type FetchProductsResponse = JsonResponseFormat<ProductJson[], (ProductPeriodJson|ClassroomJson|KtwelveJson|CourseJson|CompanyJson|KtwelveCurriculumJson)[]>
export type FetchProductResponse = JsonResponseFormat<ProductJson, (ProductPeriodJson|SubsidyJson|DocumentRequirementJson|PaymentOptionJson|ClassroomJson|PaymentCollectionJson)[]>

export type FetchProductsPayload = WithRequestParams
  export interface FetchProductPayload extends WithRequestParams {
  id: string | number,
}

export interface CreateProductPayload {
  data: Omit<ProductsFormAttributes, 'id'>
}

export interface UpdateProductPayload {
  id: string
  data: Omit<ProductsFormAttributes, 'id'>
}


export const FETCH_PRODUCTS = createPromiseAction('products: FETCH_PRODUCTS')<FetchProductsPayload, FetchProductsResponse, any>()
export const FETCH_PRODUCT = createPromiseAction('products: FETCH_PRODUCT')<FetchProductPayload, FetchProductResponse, any>()
export const CREATE_PRODUCT = createPromiseAction('products: CREATE_PRODUCT')<CreateProductPayload, FetchProductResponse, any>()
export const UPDATE_PRODUCT = createPromiseAction('products: UPDATE_PRODUCT')<UpdateProductPayload, FetchProductResponse, any>()
