/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { colors, CompositionAttributes, CompositionPeriodAttributes, ExamPlaceableType, FormulableType } from '../../utils/constants';
import { format, parseISO } from 'date-fns';
import { map, orderBy } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { Article, Functions } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import ExamPlacementsTable from './ExamPlacementsTable';
import { css } from '@emotion/react';
import FormulasTable from './FormulasTable';
import { useDispatch } from 'react-redux';
import { FETCH_COMPOSITION } from '../../store/compositions';
import { CompositionPeriodJson } from '../../store/composition_periods';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const CompositionPeriodsTable = ({ composition }: { composition: CompositionAttributes }) => {
  const [expandedExams, setExpandedExams] = React.useState<number[]>([]);
  const [expandedFormulas, setExpandedFormulas] = React.useState<number[]>([]);
  const [composition_periods, setCompositionPeriods] = React.useState<CompositionPeriodAttributes[]>([]);
  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const fetchCompositionPeriods = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_COMPOSITION.request({
          id: composition.id,
          params: {
            filters: {
              include: 'composition_periods',
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      const composition_periods_attributes = map(
        included.filter((incl) => incl.type === 'composition_periods') as CompositionPeriodJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      setCompositionPeriods(composition_periods_attributes);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar periodos',
        }),
      );
      setLoading(false);
    }
  }, [composition]);

  const init = async () => {
    await fetchCompositionPeriods();

  }
  React.useEffect(() => {
    init()
  }, []);

  if (loading){
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='composition-periods'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Ordem</TableCell>
          <TableCell align='center'>Nome</TableCell>
          <TableCell align='center'>Início</TableCell>
          <TableCell align='center'>Fim</TableCell>
          <TableCell align='center'>Lançamento de notas: início</TableCell>
          <TableCell align='center'>Lançamento de notas: fim</TableCell>
          <TableCell>{''}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderBy(composition_periods, 'order').map((composition_period, _, self) => {
          return (
            <React.Fragment key={composition_period.id}>
              <TableRow>
                <TableCell align='center'>{composition_period.order}</TableCell>
                <TableCell align='center'>{composition_period.name}</TableCell>
                <TableCell align='center'>{format(parseISO(composition_period.starts_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>{format(parseISO(composition_period.ends_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>
                  {format(parseISO(composition_period.insert_starts_at), 'dd-MM-yyyy')}
                </TableCell>
                <TableCell align='center'>{format(parseISO(composition_period.ends_at), 'dd-MM-yyyy')}</TableCell>
                <TableCell align='center'>
                  <TooltipButton
                    tooltipProps={{
                      title: expandedExams.includes(~~composition_period.id) ? 'Ocultar avaliações' : 'Ver avaliações',
                    }}
                    Icon={Article}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedExams((current) => {
                          if (current.includes(~~composition_period.id)) {
                            return current.filter((item) => item !== ~~composition_period.id);
                          } else {
                            return current.concat(~~composition_period.id);
                          }
                        }),
                    }}
                  />{' '}
                  <TooltipButton
                    tooltipProps={{
                      title: expandedFormulas.includes(~~composition_period.id) ? 'Ocultar fórmulas' : 'Ver fórmulas',
                    }}
                    Icon={Functions}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedFormulas((current) => {
                          if (current.includes(~~composition_period.id)) {
                            return current.filter((item) => item !== ~~composition_period.id);
                          } else {
                            return current.concat(~~composition_period.id);
                          }
                        }),
                    }}
                  />{' '}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={expandedExams.includes(~~composition_period.id)} timeout='auto' unmountOnExit>
                    <ExamPlacementsTable exam_placeable_id={~~composition_period.id} exam_placeable_type={ExamPlaceableType.COMPOSITION_PERIOD} />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={expandedFormulas.includes(~~composition_period.id)} timeout='auto' unmountOnExit>
                    <FormulasTable
                      composition_periods={self}
                      composition_period_id={composition_period.id}
                      composition_period_kind={composition_period.kind}
                      formulable_id={composition_period.id}
                      formulable_type={FormulableType.COMPOSITION_PERIOD}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default CompositionPeriodsTable;
